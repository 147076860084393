import React, { Component } from 'react';
import { auth, db } from '../utils/firebase';

import { Link } from 'react-router-dom';
import { viewable } from '../utils/userPermissions';

import Background from '../components/background';
import HeaderDropdown from '../components/headerDropdown';
import PromiseDetails from '../components/promiseDetails';
import { headerList } from '../utils/consts';
import { PulseSpinner } from 'react-spinners-kit';

export default class PromisePage extends Component {
	componentDidMount() {
		let promiseRef = db.ref('promises/' + this.state.promiseId);
		let userId = auth.currentUser.uid;
		let myFriends = [];
		db.ref('users/' + auth.currentUser.uid)
			.child('myFriends/')
			.once('value')
			.then(snapshot => {
				for (let i in snapshot.val()) {
					myFriends.push(snapshot.val()[i].friendId);
				}
			});

		promiseRef.once('value').then(snapshot => {
			let promiseData = snapshot.val();
			console.log(promiseData);
			let receiverId = promiseData.receiver;
			let senderId = promiseData.sender;
			if (receiverId === 'public') {
				db.ref('users/' + senderId)
					.child('publicFields')
					.on('value', snapshot => {
						let senderInfo = {
							senderId: senderId,
							publicFields: snapshot.val(),
						};
						let receiverInfo = {
							receiverId: receiverId,
							displayName: 'Public',
						};
						this.setState({
							userPermission: viewable(promiseData, userId, senderId, myFriends),
							promiseData: promiseData,
							receiverInfo: receiverInfo,
							senderInfo: senderInfo,
							history: promiseData.history,
						});
					});
			} else {
				db.ref('users/' + receiverId)
					.child('publicFields')
					.on('value', snapshot => {
						let receiverInfo = {
							receiverId: receiverId,
							publicFields: snapshot.val(),
						};
						db.ref('users/' + senderId)
							.child('publicFields')
							.on('value', snapshot => {
								let senderInfo = {
									senderId: senderId,
									publicFields: snapshot.val(),
								};
								this.setState({
									userPermission: viewable(promiseData, userId, senderId, myFriends),
									promiseData: promiseData,
									receiverInfo: receiverInfo,
									senderInfo: senderInfo,
									history: promiseData.history,
								});
							});
					});
			}
		});
	}

	constructor(props) {
		super(props);
		this.state = {
			promiseData: {},
			userPermission: 'loading',
			receiverInfo: {},
			senderInfo: {},
			history: [],
			promiseId: this.props.match.params.id,
		};
	}

	renderHistory() {
		if (this.state.history) {
			return this.state.history.map(obj => {
				return <HistoryCard key={obj.createdOn} data={obj} />;
			});
		}
	}

	render() {
		console.log(this.state);
		console.log(this.props);
		if (!this.state.userPermission) {
			return (
				<div className="promise-detail-root">
					<div className="all-background">
						<Background />
					</div>
					<div className="home-page-title">
						{
							<Link to="/" className="home-link">
								Promise
							</Link>
						}
						<div className="drop-down-menu">
							<HeaderDropdown login={auth.currentUser.uid} dropdownList={headerList} />
						</div>
					</div>
					<div className="promise-no-access">You don't have the permission to access this promise.</div>
				</div>
			);
		} else if (this.state.userPermission === 'loading') {
			return (
				<div className="promise-detail-root">
					<div className="all-background">
						<Background />
					</div>
					<div className="home-page-title">
						{
							<Link to="/" className="home-link">
								Promise
							</Link>
						}
						<div className="drop-down-menu">
							<HeaderDropdown login={auth.currentUser.uid} dropdownList={headerList} />
						</div>
					</div>
					<div className="loading-spinner-wrapper">
						<PulseSpinner size={60} color="#fff" loading={this.state.loading} />
					</div>
				</div>
			);
		} else {
			return (
				<div className="promise-detail-root">
					<div className="all-background">
						<Background />
					</div>
					<div className="home-page-title">
						{
							<Link to="/" className="home-link">
								Promise
							</Link>
						}
						<div className="drop-down-menu">
							<HeaderDropdown login={auth.currentUser.uid} dropdownList={headerList} />
						</div>
					</div>

					<PromiseDetails
						userPermission={this.state.userPermission}
						receiver={this.state.receiverInfo}
						sender={this.state.senderInfo}
						promiseData={this.state.promiseData}
						promiseId={this.state.promiseId}
					/>
				</div>
			);
		}
	}
}
