import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Friends from '../pages/friends';
import SearchFriend from '../pages/searchFriend';
import LoggedInPageNotFound from '../pages/loggedInPageNotFound';

export default class FriendsRouter extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Switch>
					<Route exact path="/friends" component={Friends} />
					<Route exact path="/friends/search" component={SearchFriend} />
					<Route component={LoggedInPageNotFound} />
				</Switch>
			</div>
		);
	}
}
