import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Background from '../components/background';
import '../styles/signupAlready.css';

export default class SignupAlready extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="signup-check-email-root">
                <Background />
                <div className="home-page-title">
                    {
                        <Link to="/" className="home-link">
                            Promise
                        </Link>
                    }
                </div>

                <div className="signup-already-container">
                    <div className="signup-already-title">
                        Email address already in use
                    </div>
					<div className="signup-already-returning-user">
						Are you a returning user? 
                    </div>
					<div className="signup-already-forgot-password-link">
                        <Link
                            to={{
                                pathname: '/login'
                            }}
                            className="signup-already-links"
                        >
                             Sign in
                        </Link>
                    </div>
                    <div className="signup-already-links">
                        <Link
                            to={{
                                pathname: '/resetPassword'
                            }}
                            className="signup-already-links"
                        >
                             Forgot your password
                        </Link>
                    </div>

					<div className="signup-already-new-user">
						New to Promise?
                    </div>
					<div className="signup-already-links">
                        <Link
                            to={{
                                pathname: '/signup'
                            }}
                            className="signup-already-links"
                        >
                             Create account
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
