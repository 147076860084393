import React, { Component } from 'react';
import { auth, db } from '../utils/firebase';

import HeaderDropdownItem from './headerDropdownItem';
import '../styles/headerDropdown.css';
import '../styles/headerDropdownItem.css';

export default class HeaderDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listOpen: false,
			headerTitle: 'MY ACCOUNT',
			list: this.props.dropdownList,
			hasRedDot: false,
		};

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		if (!auth.currentUser) {
			console.log('Not logged in');
		} else {
			db.ref('notifications/' + auth.currentUser.uid)
				.orderByChild('isRead')
				.equalTo(false)
				.once('value')
				.then(snapshot => {
					if (snapshot.exists()) {
						console.log('Have unread notification');
						this.setState({
							hasRedDot: true,
						});
					} else {
						console.log('No unread notification');
					}
				});
		}

		document.addEventListener('mousedown', this.handleClickOutside);
		document.addEventListener('touchstart', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
		document.removeEventListener('touchstart', this.handleClickOutside);
	}

	handleClickOutside(e) {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				listOpen: false,
			});
		}
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	render() {
		let list = [];
		if (this.props.login) {
			list = this.state.list.loggedInList;
		} else {
			list = this.state.list.loggedOutList;
		}

		let { listOpen, headerTitle } = this.state;
		let listItems = list.map(item => (
			<HeaderDropdownItem item={item} key={item.id} hasRedDot={this.state.hasRedDot} />
		));
		return (
			<div className="dd-wrapper" ref={this.setWrapperRef}>
				<div className="dd-header">
					<div className="dd-header-title">
						{headerTitle}
						{this.state.hasRedDot && <span className="notification-dot" id="dropdown-title-dot" />}
					</div>
				</div>
				<div className="dd-list-items">{listItems}</div>
			</div>
		);
	}
}
