import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ButtonDropdownItem from './buttonDropdownItem';
import '../styles/buttonDropdown.css';
import '../styles/button.css';

export default class ButtonDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listOpen: false,
			headerTitle: this.props.title,
			list: this.props.dropdownList,
		};

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(e) {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				listOpen: false,
			});
		}
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	render() {

		let { listOpen, headerTitle } = this.state;
		let listItems = this.state.list.map(item => (
			<ButtonDropdownItem
				uid={this.props.uid}
				id={this.props.id}
				item={item}
				key={item.id}
				onClickHandler={this.props.callback}
				next={this.props.next}
			/>
		));
		return (
			<div id={'button_dd_' + this.props.id} className="dd-wrapper-button" ref={this.setWrapperRef}>
				<div className="dd-header-button">
					<button className="dd-header-title-button">
						{headerTitle}
						<span className="dd-header-icon-button">&#10003;</span>
					</button>
				</div>
				<div className="dd-list-items-button">{listItems}</div>
			</div>
			
		);
	}
}
