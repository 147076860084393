import React, { Component } from 'react';
import { db, auth } from '../utils/firebase';

import '../styles/friendSearchBar.css';

export default class FriendSearchBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: undefined,
		};

		this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
	}

	handleSearchChange(event) {
		let text = event.target.value;
		this.state.search = text;

		// Search in my friends list
		db.ref('users/')
			.orderByChild('publicFields/displayName')
			.equalTo(text)
			.on('child_added', snapshot => {
				let friendName = snapshot.val().publicFields.displayName;
				console.log(friendName);
			});
	}

	handleSearchSubmit() {
		// NEED TO REWRITE THIS LOGIC
		try {
			db.ref('users/')
				.orderByChild('publicFields/promiseId')
				.equalTo(this.state.search)
				.once('value')
				.then(snapshot => {
					if (!this.state.search) {
						alert('Empty search value. Please enter a valid Promise ID.');
					} else {
						if (snapshot.exists()) {
							let searchResults = [];
							for (let key in snapshot.val()) {
								// skip searching current user
								if (key === auth.currentUser.uid) {
									alert('This is yourself.');
									continue;
								}
								let userInfo = snapshot.val()[key];
								db.ref('users/' + auth.currentUser.uid)
									.child('myFriends')
									.orderByChild('friendId')
									.equalTo(key)
									.once('value')
									.then(snapshot => {
										if (snapshot.exists()) {
											searchResults.push({
												id: key,
												name: userInfo.publicFields.displayName,
												promiseId: userInfo.publicFields.promiseId,
												avatarURL: userInfo.publicFields.avatarURL,
												relationWithUser: 'friended',
											});
											this.props.searchSubmitHandler(searchResults);
											console.log(searchResults);
										} else {
											db.ref('requests/' + key)
												.orderByChild('senderId')
												.equalTo(auth.currentUser.uid)
												.once('value')
												.then(snapshot => {
													console.log(snapshot.val());
													if (snapshot.exists()) {
														// already sent a friend request to this user
														// no reply yet
														db.ref('users/' + auth.currentUser.uid)
															.child('myFollowing')
															.orderByChild('followingId')
															.equalTo(key)
															.once('value')
															.then(snapshot => {
																if (snapshot.exists()) {
																	searchResults.push({
																		id: key,
																		name: userInfo.publicFields.displayName,
																		promiseId: userInfo.publicFields.promiseId,
																		avatarURL: userInfo.publicFields.avatarURL,
																		relationWithUser: 'requested-followed',
																	});
																	this.props.searchSubmitHandler(searchResults);
																	console.log(searchResults);
																} else {
																	searchResults.push({
																		id: key,
																		name: userInfo.publicFields.displayName,
																		promiseId: userInfo.publicFields.promiseId,
																		avatarURL: userInfo.publicFields.avatarURL,
																		relationWithUser: 'requested-follow',
																	});
																	this.props.searchSubmitHandler(searchResults);
																	console.log(searchResults);
																}
															});
													} else {
														db.ref('users/' + auth.currentUser.uid)
															.child('myFollowing')
															.orderByChild('followingId')
															.equalTo(key)
															.once('value')
															.then(snapshot => {
																if (snapshot.exists()) {
																	searchResults.push({
																		id: key,
																		name: userInfo.publicFields.displayName,
																		promiseId: userInfo.publicFields.promiseId,
																		avatarURL: userInfo.publicFields.avatarURL,
																		relationWithUser: 'add-followed',
																	});
																	this.props.searchSubmitHandler(searchResults);
																	console.log(searchResults);
																} else {
																	searchResults.push({
																		id: key,
																		name: userInfo.publicFields.displayName,
																		promiseId: userInfo.publicFields.promiseId,
																		avatarURL: userInfo.publicFields.avatarURL,
																		relationWithUser: 'add-follow',
																	});
																	this.props.searchSubmitHandler(searchResults);
																	console.log(searchResults);
																}
															});
													}
												});
										}
									});
							}
						} else {
							alert('No such user. Please enter another Promise ID.');
						}
					}
				});
		} catch (e) {
			alert('Empty search value. Please enter a valid Promise ID.');
		}
	}

	render() {
		return (
			<div className="friend-search-container">
				<input
					type="text"
					className="form-control-lg"
					placeholder="Promise ID"
					onChange={this.handleSearchChange}
				/>
				<button className="friends-search-button" onClick={this.handleSearchSubmit}>
					Search
				</button>
			</div>
		);
	}
}
