import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Background from '../components/background';
import '../styles/resetPasswordCheckEmail.css';

export default class ResetPasswordCheckEmail extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<div className="signup-check-email-root">
				<Background />
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
				</div>
				<div className="forget-password-check-email-container">
					<div className="forget-password-check-email-title">Verify it's you</div>
					<div className="forget-password-check-email-intro">
						For your security, we need to verify your identity.
					</div>
					<div className="forget-password-check-email-intro">
						We've sent a link to the email address: &ensp;
						<div className="forget-password-check-email-email">
							{localStorage.getItem('resetPasswordEmail')}.
						</div>
					</div>
					<div className="forget-password-check-email-intro">
						Please follow the link in the email to reset your password.
					</div>
				</div>
			</div>
		);
	}
}
