import React, { Component } from 'react';
import SVGIcon from './svgIcon';

export default class Tab extends Component {
    onClick = () => {
        const { label, onClick } = this.props;
        onClick(label);
    };

    render() {
		let isActive = false;
		if (this.props.activeTab === this.props.label) {
			isActive = true;
		}
		
        const {
            onClick,
            props: { activeTab, label }
        } = this;

        let className = this.props.className;

        if (this.props.svg) {
            return (
                <li className={className} onClick={onClick}>
                    <SVGIcon
                        isActive={isActive}
                        name={this.props.svgType}
                        width={120}
                        height={40}
                        svgText={label}
                        svgClassName={className}
                    />
                </li>
            );
        } else {
			if (activeTab === label) {
				className += '-list-active';
			}
            return (
                <li className={className} onClick={onClick}>
                    {label}
                </li>
            );
        }
    }
}
