import React, { Component } from 'react';
import { auth, db } from '../utils/firebase';
import UserCard from './userCard';

import '../styles/friendsTabContent.css';

export default class FriendsTabContent extends Component {
	componentDidMount() {
		let allMyFriends = [];
		let allMyFollowers = [];
		let allMyFollowing = [];
		let userRef = db.ref('users/' + auth.currentUser.uid);

		userRef.child('myFriends').once('value', snapshot => {
			let myFriends = [];
			if (snapshot.exists()) {
				allMyFriends = snapshot.val();
				for (let i in allMyFriends) {
					db.ref('users/' + allMyFriends[i].friendId)
						.child('publicFields')
						.once('value')
						.then(snapshot => {
							let friendInfo = snapshot.val();
							myFriends.push({
								id: allMyFriends[i].friendId,
								name: friendInfo.displayName,
								promiseId: friendInfo.promiseId,
								avatarURL: friendInfo.avatarURL,
								relationWithUser: 'friended',
							});

							this.setState({
								friends: myFriends,
							});
						});
				}
			} else {
				this.setState({
					emtpyFriend: true,
				});
			}
		});

		userRef.child('myFollowers').once('value', snapshot => {
			let myFollowers = [];
			if (snapshot.exists()) {
				allMyFollowers = snapshot.val();
				for (let i in allMyFollowers) {
					let followerId = allMyFollowers[i].followerId;
					db.ref('users/' + followerId)
						.child('publicFields')
						.once('value')
						.then(snapshot => {
							let followerInfo = snapshot.val();
							db.ref('requests/' + allMyFollowers[i].followerId)
								.orderByChild('senderId')
								.equalTo(auth.currentUser.uid)
								.once('value')
								.then(snapshot => {
									if (snapshot.exists()) {
										// already sent a friend request to this user
										// no reply yet
										myFollowers.push({
											id: allMyFollowers[i].followerId,
											name: followerInfo.displayName,
											promiseId: followerInfo.promiseId,
											avatarURL: followerInfo.avatarURL,
											relationWithUser: 'requested-followed',
										});
										this.setState({
											followers: myFollowers,
										});
									} else {
										userRef
											.child('myFollowing')
											.orderByChild('followingId')
											.equalTo(followerId)
											.once('value')
											.then(snapshot => {
												if (snapshot.exists()) {
													myFollowers.push({
														id: followerId,
														name: followerInfo.displayName,
														promiseId: followerInfo.promiseId,
														avatarURL: followerInfo.avatarURL,
														relationWithUser: 'add-followed',
													});
													this.setState({
														followers: myFollowers,
													});
												} else {
													myFollowers.push({
														id: followerId,
														name: followerInfo.displayName,
														promiseId: followerInfo.promiseId,
														avatarURL: followerInfo.avatarURL,
														relationWithUser: 'add-follow',
													});

													this.setState({
														followers: myFollowers,
													});
												}
											});
									}
								});
						});
				}
			} else {
				this.setState({
					emptyFollower: true,
				});
			}
		});

		userRef.child('myFollowing').once('value', snapshot => {
			let myFollowing = [];
			if (snapshot.exists()) {
				allMyFollowing = snapshot.val();
				for (let i in allMyFollowing) {
					db.ref('users/' + allMyFollowing[i].followingId)
						.child('publicFields')
						.once('value')
						.then(snapshot => {
							let followingInfo = snapshot.val();
							db.ref('requests/' + allMyFollowing[i].followingId)
								.orderByChild('senderId')
								.equalTo(auth.currentUser.uid)
								.once('value')
								.then(snapshot => {
									if (snapshot.exists()) {
										// already sent a friend request to this user
										// no reply yet
										myFollowing.push({
											id: allMyFollowing[i].followingId,
											name: followingInfo.displayName,
											promiseId: followingInfo.promiseId,
											avatarURL: followingInfo.avatarURL,
											relationWithUser: 'requested-followed',
										});
										this.setState({
											followings: myFollowing,
										});
									} else {
										myFollowing.push({
											id: allMyFollowing[i].followingId,
											name: followingInfo.displayName,
											promiseId: followingInfo.promiseId,
											avatarURL: followingInfo.avatarURL,
											relationWithUser: 'add-followed',
										});

										this.setState({
											followings: myFollowing,
										});
									}
								});
						});
				}
			} else {
				this.setState({
					emptyFollowing: true,
				});
			}
		});
	}

	constructor(props) {
		super(props);
		this.state = {
			friends: [],
			followings: [],
			followers: [],
			currentlyDisplayed: [],
		};
	}

	renderContent(list, isEmpty, type) {
		console.log(list);
		if (isEmpty) {
			return (
				<div className="empty-friends-text">
					You don't have {type} yet.
				</div>
			);
		} else {
			return list.map((obj, index) => {
				let user = {
					systemId: obj.id,
					promiseId: obj.promiseId,
					userName: obj.name,
					imageURL: obj.avatarURL,
				};
				return <UserCard cardId={index + 1} type={obj.relationWithUser} key={obj.id} user={user} />;
			});
		}
	}

	render() {
		let type = this.props.type;
		console.log(this.state);
		let emtpyFriend = this.state.emtpyFriend;
		let emptyFollowing = this.state.emptyFollowing;
		let emptyFollower = this.state.emptyFollower;
		switch (type) {
			case 'friend': {
				return (
					<div className="tab-content-root">{this.renderContent(this.state.friends, emtpyFriend, type)}</div>
				);
			}
			case 'following': {
				return (
					<div className="tab-content-root">
						{this.renderContent(this.state.followings, emptyFollowing, type)}
					</div>
				);
			}
			case 'follower': {
				return (
					<div className="tab-content-root">
						{this.renderContent(this.state.followers, emptyFollower, type)}
					</div>
				);
			}
		}
	}
}
