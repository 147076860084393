import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth, db, storage } from '../utils/firebase';
import HeaderDropdown from '../components/headerDropdown';
import { headerList } from '../utils/consts';

import Tabs from '../components/tabs';
import '../styles/settings.css';

import ImageUploader from '../components/imageUploader';
import Background from '../components/background';
import firebase from 'firebase';

export default class Settings extends Component {
	componentDidMount() {
		let userRef = db.ref('users/' + auth.currentUser.uid);
		userRef.child('publicFields').on('value', snapshot => {
			console.log(snapshot.val());
			let publicInfo = snapshot.val();
			this.setState({
				displayName: publicInfo.displayName,
				promiseId: publicInfo.promiseId,
			});
		});
	}

	constructor(props) {
		super(props);
		// TODO: Add local storage here
		this.state = {
			displayName: '',
			profile: {},
			currentEmail: auth.currentUser.email,
			newEmail: '',
			oldPassword: '',
			newPassword: '',
			confirmPassword: '',
			firstName: '',
			lastName: '',
			gender: '',
			birth: '',
			country: '',
			province: '',
			zip: '',
			address: '',
		};

		this.handleInputChange = this.handleInputChange.bind(this);
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	updateProfile() {
		// separate upload image and update profile
		let displayName = this.state.displayName;
		let promiseId = this.state.promiseId;

		let profilePhotoRef = storage.ref('images/profile_photo/' + auth.currentUser.uid);

		let fileInput = document.getElementById('file');
		let newProfile;
		let userRef = db.ref('users/' + auth.currentUser.uid);

		if (fileInput.files.length == 0) {
			newProfile = {
				displayName: displayName,
				promiseId: promiseId,
			};

			userRef
				.child('publicFields')
				.update(newProfile)
				.then(() => {
					alert('Successfully update user profile.');
				});
			auth.currentUser.updateProfile({
				displayName: newProfile.displayName,
			});
		} else {
			let file = document.getElementById('file').files[0];
			let name = +new Date() + '-' + file.name;
			let metaData = { contentType: file.type };
			let task = profilePhotoRef.child(name).put(file, metaData);

			task.then(snapshot => snapshot.ref.getDownloadURL())
				.then(url => {
					document.getElementById('avatar').src = url;
					let avatar = document.getElementById('avatar').src;
					newProfile = {
						displayName: displayName,
						promiseId: promiseId,
						avatarURL: avatar,
					};

					userRef
						.child('publicFields')
						.update(newProfile)
						.then(() => {
							alert('Successfully update user profile.');
						});
					auth.currentUser.updateProfile({
						displayName: newProfile.displayName,
						photoURL: newProfile.avatarURL,
					});
				})
				.catch(err => {
					alert(err);
				});
		}
	}

	cancelUpdateProfile() {
		window.location.reload(true);
	}

	updateEmail() {
		auth.currentUser.updateEmail(this.state.newEmail).catch(err => {
			alert(err);
		});
	}

	updatePassword() {
		auth.currentUser
			.reauthenticateWithCredential(
				firebase.auth.EmailAuthProvider.credential(auth.currentUser.email, this.state.oldPassword)
			)
			.then(
				auth.currentUser.updatePassword(this.state.newPassword).then(
					result => alert('Successfully update password!'),
					error => {
						alert('update' + error);
					}
				)
			)
			.catch(error => {
				alert('credential' + error);
			});
	}

	updatePersonalInfo() {
		let firstName = this.state.firstName;
		let lastName = this.state.lastName;
		let gender = this.state.gender;
		let birthDate = this.state.birth;
		let country = this.state.country;
		let province = this.state.province;
		let address = this.state.address;
		let zipcode = this.state.zip;

		let personal = {
			firstName: firstName,
			lastName: lastName,
			gender: gender,
			birthDate: birthDate,
			country: country,
			province: province,
			address: address,
			zipcode: zipcode,
		};

		let userRef = db.ref('users/' + auth.currentUser.uid);

		userRef
			.child('privateFields')
			.update(personal)
			.then(() => {
				alert('Successfully update personal information.');
			})
			.catch(err => alert(err));
	}

	render() {
		console.log(auth.currentUser.displayName);

		return (
			<div className="settings-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown
							login={auth.currentUser}
							userInfo={this.state.profile}
							dropdownList={headerList}
						/>
					</div>
				</div>
				<div className="settings-container">
					<Tabs tabs="settings-tabs" tabList="settings-tab-list" tabContent="settings-tab-content">
						<div label="Profile" useSVG={true} svgType="envelope" className="settings-svg-tab">
							<div className="profile-image-and-name-container">
								<div className="settings-profile-image">
									<ImageUploader />
								</div>
								<div className="settings-user-name-and-id">
									<div className="settings-user-name">{this.state.displayName}</div>
									<div className="settings-user-id">{this.state.promiseId}</div>
								</div>
							</div>
							<div className="settings-display-name">
								<label className="settings-title">User name: </label>
								<input
									className="settings-input"
									type="text"
									name="displayName"
									value={this.state.displayName}
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="settings-promiseId">
								<label className="settings-title">Unique ID: </label>
								<input
									className="settings-input"
									type="text"
									name="promiseId"
									value={this.state.promiseId}
									onChange={this.handleInputChange}
								/>
							</div>

							<div className="settings-basic-info-buttons">
								{/* <button
									className="settings-cancel-button"
									onClick={this.cancelUpdateProfile.bind(this)}
								>
									Cancel
								</button> */}
								<button className="settings-save-button" onClick={this.updateProfile.bind(this)}>
									Save
								</button>
							</div>
						</div>
						<div label="Account" useSVG={true} svgType="envelope" className="settings-svg-tab">
							<div className="settings-email">
								<label className="settings-title">Email </label>
								<input
									className="settings-input"
									type="text"
									name="newEmail"
									value={this.state.currentEmail}
									onChange={this.handleInputChange}
								/>
								<button className="settings-update-email-button" onClick={this.updateEmail.bind(this)}>
									Update Email
								</button>
							</div>

							<div className="settings-old-password">
								<label className="settings-title">Old password</label>
								<input
									className="settings-input"
									type="password"
									name="oldPassword"
									value={this.state.oldPassword}
									onChange={this.handleInputChange}
								/>
							</div>

							<div className="settings-new-password">
								<label className="settings-title">New password</label>
								<input
									className="settings-input"
									type="password"
									name="newPassword"
									value={this.state.newPassword}
									onChange={this.handleInputChange}
								/>
							</div>

							<div className="settings-confirm-password">
								<label className="settings-title">Confirm new password</label>
								<input
									className="settings-input"
									type="password"
									name="confirmPassword"
									value={this.state.confirmPassword}
									onChange={this.handleInputChange}
								/>
							</div>
							<button
								className="settings-update-password-button"
								onClick={this.updatePassword.bind(this)}
							>
								Update Password
							</button>

							<div className="login-forgot-password">
								<Link
									to={{
										pathname: '/resetPassword',
										state: {
											email: this.state.currentEmail,
										},
									}}
									className="login-forgot-password"
								>
									Reset password
								</Link>
							</div>
						</div>
						<div label="Personal" useSVG={true} svgType="envelope" className="settings-svg-tab">
							<form>
								<div className="settings-name">
									<label className="settings-first-name">
										<label className="settings-title">First name</label>
										<input
											className="settings-input"
											name="firstName"
											type="title"
											checked={this.state.firstName}
											onChange={this.handleInputChange}
										/>
									</label>

									<label className="settings-last-name">
										<label className="settings-title">Last name</label>
										<input
											className="settings-input"
											name="lastName"
											type="title"
											value={this.state.lastName}
											onChange={this.handleInputChange}
										/>
									</label>
								</div>

								<div className="settings-gender-birth">
									<label className="settings-gender">
										<label className="settings-title">Gender</label>
										<input
											className="settings-input"
											name="gender"
											type="title"
											value={this.state.gender}
											onChange={this.handleInputChange}
										/>
									</label>
									<label className="settings-birth-date">
										<label className="settings-title">Birth</label>
										<input
											className="settings-input"
											placeholder="MM/DD/YYYY"
											name="birth"
											type="title"
											value={this.state.birth}
											onChange={this.handleInputChange}
										/>
									</label>
								</div>
								<div className="settings-country-state">
									<label className="settings-country">
										<label className="settings-title">Country</label>
										<input
											className="settings-input"
											name="country"
											type="title"
											value={this.state.country}
											onChange={this.handleInputChange}
										/>
									</label>
									<label className="settings-state">
										<label className="settings-title">State/Province</label>
										<input
											className="settings-input"
											name="province"
											type="title"
											value={this.state.province}
											onChange={this.handleInputChange}
										/>
									</label>
								</div>
								<div className="settings-address-zip-code">
									<label className="settings-address">
										<label className="settings-title">Address</label>
										<input
											className="settings-input"
											name="address"
											type="title"
											value={this.state.address}
											onChange={this.handleInputChange}
										/>
									</label>
									<label className="settings-zip-code">
										<label className="settings-title">Zip code</label>
										<input
											className="settings-input"
											name="zip"
											type="title"
											value={this.state.zip}
											onChange={this.handleInputChange}
										/>
									</label>
								</div>
								<button
									className="settings-update-personal-info-button"
									onClick={this.updateProfile.bind(this)}
								>
									Update
								</button>
							</form>
						</div>
					</Tabs>
				</div>
			</div>
		);
	}
}
