import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { standardTime, convertUTCToLocal } from '../utils/time';
import { generateUserNameLink, generateUserImageLink } from '../utils/links';
import ButtonAction from './buttonAction';
import '../styles/notificationCard.css';

export default class NotificationCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: this.props.notification.id,
			createdOn: this.props.notification.createdOn,
			promiseId: this.props.notification.promiseId,
			oldContent: this.props.notification.promiseOldContent,
			newContent: this.props.notification.promiseNewContent,
			buttonType: this.props.notification.buttonType,
			contentType: this.props.notification.contentType,
			senderId: this.props.notification.senderId,
			senderName: this.props.notification.senderName,
			senderPromiseId: this.props.notification.senderPromiseId,
			senderURL: this.props.notification.senderImage,
		};
	}

	render() {
		let buttonType = this.state.buttonType;
		let contentType = this.state.contentType;

		switch (buttonType) {
			case 'track': {
				switch (contentType) {
					case 'changed': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-promise-left">
									<div className="notification-card-promise-left-top">
										<div className="notification-card-promise-top-left">
											{generateUserImageLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												this.state.senderURL,
												'notification-sender-image'
											)}
										</div>
										<div className="notification-card-promise-top-right">
											<div className="notification-card-friend-name">
												{generateUserNameLink(
													this.state.senderId,
													this.state.senderPromiseId,
													this.state.senderName,
													'user-page-link'
												)}
											</div>
											changed promise:
										</div>
									</div>
									<div className="notification-card-promise-content">
										{
											<Link
												to={`/promises/${this.state.promiseId}`}
												className="notification-card-promise-link"
											>
												{this.state.oldContent}
											</Link>
										}
									</div>
									<div className="notification-card-promise-create-on">
										{standardTime(convertUTCToLocal(this.state.createdOn))}
									</div>
								</div>

								<div className="notification-card-promise-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											promiseId={this.state.promiseId}
											type="track"
										/>
									</div>
								</div>
							</div>
						);
					}
					case 'created': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-promise-left">
									<div className="notification-card-promise-left-top">
										<div className="notification-card-promise-top-left">
											{generateUserImageLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												this.state.senderURL,
												'notification-sender-image'
											)}
										</div>
										<div className="notification-card-promise-top-right">
											<div className="notification-card-friend-name">
												{generateUserNameLink(
													this.state.senderId,
													this.state.senderPromiseId,
													this.state.senderName,
													'user-page-link'
												)}
											</div>
											sent you a new promise:
										</div>
									</div>
									<div className="notification-card-promise-content">
										{
											<Link
												to={`/promises/${this.state.promiseId}`}
												className="notification-card-promise-link"
											>
												{this.state.newContent}
											</Link>
										}
									</div>
									<div className="notification-card-promise-create-on">
										{standardTime(convertUTCToLocal(this.state.createdOn))}
									</div>
								</div>
								<div className="notification-card-promise-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											promiseId={this.state.promiseId}
											type="track"
										/>
									</div>
								</div>
							</div>
						);
					}
					case 'broken': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-promise-left">
									<div className="notification-card-promise-left-top">
										<div className="notification-card-promise-top-left">
											{generateUserImageLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												this.state.senderURL,
												'notification-sender-image'
											)}
										</div>
										<div className="notification-card-promise-top-right">
											<div className="notification-card-friend-name">
												{generateUserNameLink(
													this.state.senderId,
													this.state.senderPromiseId,
													this.state.senderName,
													'user-page-link'
												)}
											</div>
											broke promise:
										</div>
									</div>
									<div className="notification-card-promise-content">
										{
											<Link
												to={`/promises/${this.state.promiseId}`}
												className="notification-card-promise-link"
											>
												{this.state.oldContent}
											</Link>
										}
									</div>
									<div className="notification-card-promise-create-on">
										{standardTime(convertUTCToLocal(this.state.createdOn))}
									</div>
								</div>

								<div className="notification-card-promise-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											promiseId={this.state.promiseId}
											type="track"
										/>
									</div>
								</div>
							</div>
						);
					}
				}
			}

			case 'expired': {
				return (
					<div className="notification-card-container">
						<div className="notification-card-friend-left">
							{generateUserImageLink(
								this.state.senderId,
								this.state.senderPromiseId,
								this.state.senderName,
								this.state.senderURL,
								'notification-sender-image'
							)}
						</div>
						<div className="notification-card-middle">
							Your promise:
							<div className="notification-card-clickable-content">
								{
									<Link to={`/promises/${this.state.promiseId}`} className="promise-history-link">
										{this.state.oldContent}
									</Link>
								}
							</div>
							is expired.
							<div className="notification-card-bottom">
								{standardTime(convertUTCToLocal(this.state.createdOn))}
							</div>
						</div>
						<div className="notification-card-right">
							<div className="notification-card-bottons-container">
								<ButtonAction
									id={this.props.cardId}
									uid={this.state.userId}
									userName={this.state.userName}
									type="expired"
								/>
							</div>
						</div>
					</div>
				);
			}

			case 'confirm-reject': {
				return (
					<div className="notification-card-container">
						<div className="notification-card-friend-top">
							<div className="notification-card-friend-left">
								{generateUserImageLink(
									this.state.senderId,
									this.state.senderPromiseId,
									this.state.senderName,
									this.state.senderURL,
									'notification-sender-image'
								)}
							</div>
							<div className="notification-card-friend-middle">
								<div className="notification-card-friend-name">
									{generateUserNameLink(
										this.state.senderId,
										this.state.senderPromiseId,
										this.state.senderName,
										'user-page-link'
									)}
								</div>
								sent you a friend request
								<div className="notification-card-create-on">
									{standardTime(convertUTCToLocal(this.state.createdOn))}
								</div>
							</div>
						</div>
						<div className="notification-card-friend-right">
							<div className="notification-card-bottons-container">
								<ButtonAction
									id={this.props.cardId}
									uid={this.state.senderId}
									userName={this.state.senderName}
									type="confirm-reject"
								/>
							</div>
						</div>
					</div>
				);
			}

			case 'requested-followed': {
				switch (contentType) {
					case 'friend-request': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-friend-top">
									<div className="notification-card-friend-left">
										{generateUserImageLink(
											this.state.senderId,
											this.state.senderPromiseId,
											this.state.senderName,
											this.state.senderURL,
											'notification-sender-image'
										)}
									</div>
									<div className="notification-card-friend-middle">
										<div className="notification-card-friend-name">
											{generateUserNameLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												'user-page-link'
											)}
										</div>
										sent you a friend request
										<div className="notification-card-create-on">
											{standardTime(convertUTCToLocal(this.state.createdOn))}
										</div>
									</div>
								</div>
								<div className="notification-card-friend-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											type="requested-followed"
										/>
									</div>
								</div>
							</div>
						);
					}
					case 'follow': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-friend-top">
									<div className="notification-card-friend-left">
										{generateUserImageLink(
											this.state.senderId,
											this.state.senderPromiseId,
											this.state.senderName,
											this.state.senderURL,
											'notification-sender-image'
										)}
									</div>
									<div className="notification-card-friend-middle">
										<div className="notification-card-friend-name">
											{generateUserNameLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												'user-page-link'
											)}
										</div>
										followed you
										<div className="notification-card-create-on">
											{standardTime(convertUTCToLocal(this.state.createdOn))}
										</div>
									</div>
								</div>
								<div className="notification-card-friend-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											type="requested-followed"
										/>
									</div>
								</div>
							</div>
						);
					}
				}
			}

			case 'requested-follow': {
				switch (contentType) {
					case 'friend-request': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-friend-top">
									<div className="notification-card-friend-left">
										{generateUserImageLink(
											this.state.senderId,
											this.state.senderPromiseId,
											this.state.senderName,
											this.state.senderURL,
											'notification-sender-image'
										)}
									</div>
									<div className="notification-card-friend-middle">
										<div className="notification-card-friend-name">
											{generateUserNameLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												'user-page-link'
											)}
										</div>
										sent you a friend request
										<div className="notification-card-create-on">
											{standardTime(convertUTCToLocal(this.state.createdOn))}
										</div>
									</div>
								</div>
								<div className="notification-card-friend-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											type="requested-follow"
										/>
									</div>
								</div>
							</div>
						);
					}
					case 'follow': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-friend-top">
									<div className="notification-card-friend-left">
										{generateUserImageLink(
											this.state.senderId,
											this.state.senderPromiseId,
											this.state.senderName,
											this.state.senderURL,
											'notification-sender-image'
										)}
									</div>
									<div className="notification-card-friend-middle">
										<div className="notification-card-friend-name">
											{generateUserNameLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												'user-page-link'
											)}
										</div>
										followed you
										<div className="notification-card-create-on">
											{standardTime(convertUTCToLocal(this.state.createdOn))}
										</div>
									</div>
								</div>
								<div className="notification-card-friend-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											type="requested-follow"
										/>
									</div>
								</div>
							</div>
						);
					}
				}
			}

			case 'friended': {
				switch (contentType) {
					case 'friend-request': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-friend-top">
									<div className="notification-card-friend-left">
										{generateUserImageLink(
											this.state.senderId,
											this.state.senderPromiseId,
											this.state.senderName,
											this.state.senderURL,
											'notification-sender-image'
										)}
									</div>
									<div className="notification-card-friend-middle">
										<div className="notification-card-friend-name">
											{generateUserNameLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												'user-page-link'
											)}
										</div>
										sent you a friend request
										<div className="notification-card-create-on">
											{standardTime(convertUTCToLocal(this.state.createdOn))}
										</div>
									</div>
								</div>
								<div className="notification-card-friend-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											type="friended"
										/>
									</div>
								</div>
							</div>
						);
					}
					case 'follow': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-friend-top">
									<div className="notification-card-friend-left">
										{generateUserImageLink(
											this.state.senderId,
											this.state.senderPromiseId,
											this.state.senderName,
											this.state.senderURL,
											'notification-sender-image'
										)}
									</div>
									<div className="notification-card-friend-middle">
										<div className="notification-card-friend-name">
											{generateUserNameLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												'user-page-link'
											)}
										</div>
										followed you
										<div className="notification-card-create-on">
											{standardTime(convertUTCToLocal(this.state.createdOn))}
										</div>
									</div>
								</div>
								<div className="notification-card-friend-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											type="friended"
										/>
									</div>
								</div>
							</div>
						);
					}
				}
			}

			case 'rejected': {
				return (
					<div className="notification-card-container">
						<div className="notification-card-friend-top">
							<div className="notification-card-friend-left">
								{generateUserImageLink(
									this.state.senderId,
									this.state.senderPromiseId,
									this.state.senderName,
									this.state.senderURL,
									'notification-sender-image'
								)}
							</div>
							<div className="notification-card-friend-middle">
								<div className="notification-card-friend-name">
									{generateUserNameLink(
										this.state.senderId,
										this.state.senderPromiseId,
										this.state.senderName,
										'user-page-link'
									)}
								</div>
								sent you a friend request
								<div className="notification-card-create-on">
									{standardTime(convertUTCToLocal(this.state.createdOn))}
								</div>
							</div>
						</div>
						<div className="notification-card-friend-right">
							<div className="notification-card-bottons-container">
								<ButtonAction
									id={this.props.cardId}
									uid={this.state.senderId}
									userName={this.state.senderName}
									type="rejected"
								/>
							</div>
						</div>
					</div>
				);
			}

			case 'add-follow': {
				switch (contentType) {
					case 'friend-request': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-friend-top">
									<div className="notification-card-friend-left">
										{generateUserImageLink(
											this.state.senderId,
											this.state.senderPromiseId,
											this.state.senderName,
											this.state.senderURL,
											'notification-sender-image'
										)}
									</div>
									<div className="notification-card-friend-middle">
										<div className="notification-card-friend-name">
											{generateUserNameLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												'user-page-link'
											)}
										</div>
										sent you a friend request
										<div className="notification-card-create-on">
											{standardTime(convertUTCToLocal(this.state.createdOn))}
										</div>
									</div>
								</div>
								<div className="notification-card-friend-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											type="add-follow"
										/>
									</div>
								</div>
							</div>
						);
					}
					case 'follow': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-friend-top">
									<div className="notification-card-friend-left">
										{generateUserImageLink(
											this.state.senderId,
											this.state.senderPromiseId,
											this.state.senderName,
											this.state.senderURL,
											'notification-sender-image'
										)}
									</div>
									<div className="notification-card-friend-middle">
										<div className="notification-card-friend-name">
											{generateUserNameLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												'user-page-link'
											)}
										</div>
										followed you
										<div className="notification-card-create-on">
											{standardTime(convertUTCToLocal(this.state.createdOn))}
										</div>
									</div>
								</div>
								<div className="notification-card-friend-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											type="add-follow"
										/>
									</div>
								</div>
							</div>
						);
					}
				}
			}

			case 'add-followed': {
				switch (contentType) {
					case 'friend-request': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-friend-top">
									<div className="notification-card-friend-left">
										{generateUserImageLink(
											this.state.senderId,
											this.state.senderPromiseId,
											this.state.senderName,
											this.state.senderURL,
											'notification-sender-image'
										)}
									</div>
									<div className="notification-card-friend-middle">
										<div className="notification-card-friend-name">
											{generateUserNameLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												'user-page-link'
											)}
										</div>
										sent you a friend request
										<div className="notification-card-create-on">
											{standardTime(convertUTCToLocal(this.state.createdOn))}
										</div>
									</div>
								</div>
								<div className="notification-card-friend-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											type="add-followed"
										/>
									</div>
								</div>
							</div>
						);
					}
					case 'follow': {
						return (
							<div className="notification-card-container">
								<div className="notification-card-friend-top">
									<div className="notification-card-friend-left">
										{generateUserImageLink(
											this.state.senderId,
											this.state.senderPromiseId,
											this.state.senderName,
											this.state.senderURL,
											'notification-sender-image'
										)}
									</div>
									<div className="notification-card-friend-middle">
										<div className="notification-card-friend-name">
											{generateUserNameLink(
												this.state.senderId,
												this.state.senderPromiseId,
												this.state.senderName,
												'user-page-link'
											)}
										</div>
										followed you
										<div className="notification-card-create-on">
											{standardTime(convertUTCToLocal(this.state.createdOn))}
										</div>
									</div>
								</div>
								<div className="notification-card-friend-right">
									<div className="notification-card-bottons-container">
										<ButtonAction
											id={this.props.cardId}
											uid={this.state.senderId}
											userName={this.state.senderName}
											type="add-followed"
										/>
									</div>
								</div>
							</div>
						);
					}
				}
			}
		}
	}
}
