import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { auth } from '../utils/firebase';
import Tabs from '../components/tabs';
import FriendsTabContent from '../components/friendsTabContent';
import HeaderDropdown from '../components/headerDropdown';
import Background from '../components/background';
import { headerList } from '../utils/consts';

import '../styles/friends.css';

export default class Friends extends Component {
	constructor(props) {
		super(props);

	}

	render() {
		return (
			<div className="friends-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown
							login={auth.currentUser.uid}
							dropdownList={headerList}
						/>
					</div>
				</div>
				<div className="find-new-friend">
					{
						<button className="friend-search-button">
						<Link to="/friends/search" className="friend-search-link">
							+ Find New Friends
						</Link>
						</button>
					}
				</div>
				<div className="friends-container">
					<Tabs tabs="friends-tabs" tabList="friends-tab-list" tabContent="friends-tab-content">
						<div label="Friend" useSVG={true} svgType="envelope" className="friends-svg-tab">
							<FriendsTabContent type="friend" />
						</div>
						<div label="Following" useSVG={true} svgType="envelope" className="friends-svg-tab">
							<FriendsTabContent type="following" />
						</div>
						<div label="Follower" useSVG={true} svgType="envelope" className="friends-svg-tab">
							<FriendsTabContent type="follower" />
						</div>
					</Tabs>
				</div>
			</div>
		);
	}
}
