import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../utils/firebase';
import '../styles/resetPassword.css';

import '../styles/home.css';
import Background from '../components/background';
import { validateEmail } from '../utils/formValidate';

export default class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
		};
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	resetPassword() {
		let emailErrorElement = document.getElementById('reset-password-error');
		let emailInputBorder = document.getElementsByClassName('reset-password-email-input')[0];

		if (!validateEmail(this.state.email)) {
			this.setState({
				emailErrorCode: 'invalid',
			});
			emailErrorElement.className = 'reset-password-error-message show';
			emailInputBorder.className = 'reset-password-email-input border-red';
			return;
		} else {
			auth.sendPasswordResetEmail(this.state.email)
				.then(result => {
					emailErrorElement.className = 'reset-password-error-message';
					emailInputBorder.className = 'reset-password-email-input';
					localStorage.setItem('resetPasswordEmail', this.state.email);
					this.props.history.push('/resetPasswordCheckEmail');
				})
				.catch(error => {
					console.log(error);
					this.setState({
						emailErrorCode: 'not-exist',
					});
					emailErrorElement.className = 'reset-password-error-message show';
					emailInputBorder.className = 'reset-password-email-input border-red';
				});
		}
	}

	render() {
		console.log(this.props);
		let emailErrorMessage;
		switch (this.state.emailErrorCode) {
			case 'invalid': {
				emailErrorMessage = 'It is not a valid email address';
				break;
			}
			case 'not-exist': {
				emailErrorMessage = 'There is no user record corresponding to this email address.';
				break;
			}
			default:
				break;
		}
		return (
			<div className="home-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
				</div>

				<div className="reset-password-container">
					<div className="reset-password-title">Forgot password</div>
					<div className="reset-password-intro">
						Enter the email address associated with your Promise account.
					</div>
					<div className="reset-password-email-text">Email</div>
					<input
						className="reset-password-email-input"
						name="email"
						type="text"
						placeholder=""
						value={this.state.email}
						required
						onChange={this.handleInputChange.bind(this)}
					/>
					<div className="reset-password-error-message" id="reset-password-error">
						{emailErrorMessage}
					</div>
					<button className="reset-password-continue" onClick={this.resetPassword.bind(this)}>
						Continue
					</button>
				</div>
			</div>
		);
	}
}
