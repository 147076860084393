import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../utils/firebase';

import HeaderDropdown from '../components/headerDropdown';
import { headerList } from '../utils/consts';

import Background from '../components/background';

import '../styles/home.css';

export default class Start extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="home-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown login={auth.currentUser} dropdownList={headerList} />
					</div>
				</div>
				<div className="home-container">
					<div className="home-body">
						<div className="home-title">Now and Forever</div>
						<button className="home-make-promise-button">
							{
								<Link to="/login" className="make-promise-link">
									Make a Promise
								</Link>
							}
						</button>
					</div>
				</div>
			</div>
		);
	}
}
