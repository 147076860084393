export const headerList = {
	loggedInList: [
		{
			id: 0,
			key: 'header',
			selected: false,
			title: '+ New promise',
			url: '/create',
		},
		{
			id: 1,
			key: 'header',
			selected: false,
			title: 'My Promises',
			url: '/myPromises',
		},
		{
			id: 2,
			key: 'header',
			selected: false,
			title: 'Friends',
			url: '/friends',
		},
		{
			id: 3,
			key: 'header',
			selected: false,
			title: 'Billboard',
			url: '/billboard',
		},
		{
			id: 4,
			key: 'header',
			selected: false,
			title: 'Notifications',
			url: '/notifications',
		},
		{
			id: 5,
			key: 'header',
			selected: false,
			title: 'Settings',
			url: '/settings',
		},
		{
			id: 6,
			key: 'header',
			selected: false,
			title: 'Logout',
			url: '/',
		},
	],
	loggedOutList: [
		{
			id: 0,
			key: 'header',
			selected: false,
			title: '+ New promise',
			url: '/login',
		},
		{
			id: 1,
			key: 'header',
			selected: false,
			title: 'My Promises',
			url: '/login',
		},
		{
			id: 2,
			key: 'header',
			selected: false,
			title: 'Friends',
			url: '/login',
		},
		{
			id: 3,
			key: 'header',
			selected: false,
			title: 'Billboard',
			url: '/login',
		},
		{
			id: 4,
			key: 'header',
			selected: false,
			title: 'Notifications',
			url: '/login',
		},
		{
			id: 5,
			key: 'header',
			selected: false,
			title: 'Settings',
			url: '/login',
		},
		{
			id: 6,
			key: 'header',
			selected: false,
			title: 'Login',
			url: '/login',
		},
	],
};

export const userActionList = {
	friendAction: [
		{
			id: 0,
			selected: false,
			title: 'Unfriend',
		},
	],
	followAction: [
		{
			id: 0,
			selected: false,
			title: 'Unfollow',
		},
	],
};
