import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth, db } from '../utils/firebase';
import { headerList } from '../utils/consts';
import '../styles/createPromise.css';

import Background from '../components/background';
import DateSelection from '../components/dateSelection';
import FriendSelector from '../components/friendSelector';

import { checkExpiration } from '../utils/time';
import HeaderDropdown from '../components/headerDropdown';

export default class CreatePromise extends Component {
	componentDidMount() {
		let allMyFriends = [];

		db.ref('users/' + auth.currentUser.uid)
			.child('publicFields')
			.once('value')
			.then(snapshot => {
				let publicInfo = snapshot.val();
				this.setState({
					senderName: publicInfo.displayName,
				});
			});

		db.ref('users/' + auth.currentUser.uid)
			.child('myFriends')
			.on('value', snapshot => {
				let myFriends = [];
				if (snapshot.exists()) {
					allMyFriends = snapshot.val();
					for (let i in allMyFriends) {
						db.ref('users/' + allMyFriends[i].friendId)
							.child('publicFields')
							.once('value')
							.then(snapshot => {
								let friendInfo = snapshot.val();
								myFriends.push({
									id: allMyFriends[i].friendId,
									name: friendInfo.displayName,
									userPromiseId: friendInfo.promiseId,
								});

								this.setState({
									friends: myFriends,
								});
							});
					}
				}
			});
	}

	constructor(props) {
		super(props);
		this.state = {
			promiseContent: '',
			privacy: 'Public',
			promiseId: '',
			friends: [],
			receiverId: 'public',
			receiverName: 'public',
			receiverPromiseId: '',
			senderName: '',
			isNeverExpired: false,
			selectedDay: new Date().getDate() + 1,
			selectedMonth: new Date().getMonth(),
			selectedYear: new Date().getFullYear(),
		};

		this.handlePromiseContentChange = this.handlePromiseContentChange.bind(this);
		this.handleDay = this.handleDay.bind(this);
		this.handleMonth = this.handleMonth.bind(this);
		this.handleYear = this.handleYear.bind(this);
	}

	enableExpirationSelection() {
		let elm = document.querySelector('.promise-life-expiration-select');
		elm.disabled = false;
	}

	disableExpirationSelection() {
		let elm = document.querySelector('.promise-life-expiration-select');
		elm.disabled = true;
	}

	setReceiver(data) {
		this.setState({
			receiverId: data.id,
			receiverName: data.name,
			receiverPromiseId: data.userPromiseId,
		});
	}

	handlePromiseContentChange(event) {
		this.setState({
			promiseContent: event.target.value,
		});
	}

	handleDay(day) {
		this.setState({
			selectedDay: day,
		});
	}

	handleMonth(month) {
		this.setState({
			selectedMonth: month,
		});
	}

	handleYear(year) {
		this.setState({
			selectedYear: year,
		});
	}

	handlePrivacySelected(e) {
		console.log(e.target);
		this.setState({
			privacy: e.target.value,
		});
	}

	validatePromise(promise) {
		console.log(promise);
		let errorMsg = {
			content: '',
			privacy: '',
			receiver: '',
			life: '',
		};
		let error = {
			existError: false,
			msg: errorMsg,
		};

		if (!promise.content) errorMsg.content = 'Please enter your promise.';
		if (!promise.privacy) errorMsg.privacy = 'Please select a privacy scope.';
		if (!promise.life) errorMsg.privacy = 'Please choose an expiration time.';
		if (!promise.receiver) errorMsg.receiver = 'Please choose a group or an individual receiver.';

		for (let i in errorMsg) {
			if (errorMsg[i] !== null && errorMsg[i] !== '') {
				error.existError = true;
				break;
			}
		}

		return error;
	}

	validateDate(day, month, year) {
		if (this.state.isNeverExpired) return true;
		else {
			let date = month + 1 + '/' + day + '/' + year;
			let life = new Date(date).getTime();
			let currentTime = new Date().getTime();
			return life > currentTime ? true : false;
		}
	}

	makePromise() {
		let privacy = this.state.privacy;
		let content = this.state.promiseContent;
		let receiver = this.state.receiverId;
		let receiverName = this.state.receiverName;
		let date = this.state.selectedMonth + 1 + '/' + this.state.selectedDay + '/' + this.state.selectedYear;

		let life;
		if (this.state.isNeverExpired) {
			let largeDate = new Date('01/01/2500');
			life = new Date(largeDate).getTime();
		} else {
			life = new Date(date).getTime();
		}

		let currentTime = new Date().getTime();
		console.log(life);

		// Compare UTC
		if (life < currentTime) {
			alert("Please choose an expiration date after today's date.");
			return;
		}

		let notification = {};
		let historyLine = [];
		let newHistory = {};

		newHistory = {
			createdOn: currentTime,
			action: 'make',
			content: content,
			reason: 'Promise made',
			life: life,
			privacy: privacy,
		};

		historyLine.push(newHistory);

		let newPromise = {
			content: content,
			createdOn: currentTime,
			life: life,
			privacy: privacy,
			sender: auth.currentUser.uid,
			senderName: this.state.senderName,
			receiver: receiver,
			receiverName: receiverName,
			status: checkExpiration(life, currentTime) ? 'expired' : 'keeping',
			notification: notification,
			history: historyLine,
			lastChange: currentTime,
		};
		console.log(newPromise);

		let error = this.validatePromise(newPromise);

		if (error.existError) {
			alert('Error: ' + error.msg.content + error.msg.privacy + error.msg.receiver + error.msg.life);
		} else {
			let promiseId = db.ref('promises/').push(newPromise).key;
			notification = {
				promiseId: promiseId,
				sender: auth.currentUser.uid,
				createdOn: currentTime,
				oldContent: '',
				newContent: content,
				oldLife: '',
				newLife: life,
				oldPrivacy: '',
				newPrivacy: privacy,
				contentType: 'created',
				isRead: false,
			};

			db.ref('users/' + auth.currentUser.uid + '/myPromises')
				.child('sent')
				.push(promiseId)
				.then(() => {
					if (newPromise.receiver === 'public') return;
					db.ref('users/' + newPromise.receiver + '/myPromises')
						.child('received')
						.push(promiseId)
						.then(() => {
							alert('You have successfully sent a promise to ' + this.state.receiverName + '!');
						});
					db.ref('notifications/' + newPromise.receiver)
						.push(notification)
						.catch(err => {
							alert(err);
						});
				});
		}
	}

	goBack() {
		this.props.history.goBack();
	}

	handleCheckboxChange(e) {
		this.setState({
			isNeverExpired: e.target.checked,
		});
	}

	render() {
		console.log(this.state);
		let date = this.state.selectedMonth + '/' + this.state.selectedDay + '/' + this.state.selectedYear;
		let life = new Date(date).getTime();
		let promise = {
			content: this.state.promiseContent,
			life: life,
			privacy: this.state.privacy,
			receiver: this.state.receiverId,
		};
		let privacySelection = {};
		if (this.state.receiverId === 'public') {
			privacySelection = (
				<select className="privacy-selector" onChange={this.handlePrivacySelected.bind(this)} id="privacy">
					<option value="Public">Public</option>
					<option value="Friends">Friends only</option>
				</select>
			);
		} else {
			privacySelection = (
				<select className="privacy-selector" onChange={this.handlePrivacySelected.bind(this)} id="privacy">
					<option value="Public">Public</option>
					<option value="Participants">Participants only</option>
					<option value="Friends">Friends only</option>
				</select>
			);
		}
		return (
			<div className="edit-promise-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown login={auth.currentUser.uid} dropdownList={headerList} />
					</div>
				</div>
				<div className="edit-promise-content">
					<div className="edit-promise-first-line">
						<div className="edit-promise-receiver">
							<div className="edit-promise-receiver-title">To:</div>
							<div className="edit-receiver-selector-container">
								<FriendSelector
									className="receiver-selector"
									id="receiver"
									data={this.state.friends}
									setReceiver={this.setReceiver.bind(this)}
								/>
							</div>
						</div>
						<div className="edit-promise-privacy">
							<div className="edit-promise-privacy-title">Privacy:</div>
							<div className="privacy-selector-container">{privacySelection}</div>
						</div>
						<div className="edit-promise-expiration-mobile">
							<div className="edit-promise-expiration-title">Expiration:</div>
							<div className="edit-promise-expiration-container-mobile">
								<DateSelection
									onSelectedDay={this.handleDay}
									onSelectedMonth={this.handleMonth}
									onSelectedYear={this.handleYear}
									type="after"
								/>
							</div>
						</div>
					</div>
					<div className="create-promise">
						<textarea
							className="promise-content"
							placeholder="Make a promise..."
							id="content"
							value={this.state.promiseContent}
							onChange={this.handlePromiseContentChange}
						/>
					</div>

					<div className="edit-promise-submit-line">
						<div className="edit-promise-expiration">
							<div className="edit-promise-expiration-title">Expiration:</div>
							<div className="expiration-selector-container">
								<DateSelection
									isDisabled={this.state.isNeverExpired}
									selectedYear={this.state.selectedYear}
									selectedMonth={this.state.selectedMonth}
									selectedDay={this.state.selectedDay}
									onSelectedDay={this.handleDay}
									onSelectedMonth={this.handleMonth}
									onSelectedYear={this.handleYear}
									type="after"
								/>
							</div>
							<input
								type="checkbox"
								className="expiration-forever-checkbox"
								id="expiration-forever"
								onChange={this.handleCheckboxChange.bind(this)}
							/>
							<span>Forever</span>
						</div>
						<div className="edit-promise-button">
							<button className="create-promise-cancel-promise-button" onClick={this.goBack.bind(this)}>
								<div className="create-promise-cancel-link">Cancel</div>
							</button>
							<button className="create-promise-make-promise-button">
								{this.validateDate(
									this.state.selectedDay,
									this.state.selectedMonth,
									this.state.selectedYear
								) && !this.validatePromise(promise).existError ? (
									<Link
										className="create-promise-promise-make-link"
										to={{
											pathname: '/myPromises/made',
											state: {
												receiverName: this.state.receiverName,
												receiverPromiseId: this.state.receiverPromiseId,
												action: 'make',
											},
										}}
										onClick={this.makePromise.bind(this)}
									>
										Make
									</Link>
								) : (
									<div onClick={this.makePromise.bind(this)}>Make</div>
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
