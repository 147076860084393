import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth, db } from '../utils/firebase';
import '../styles/home.css';
import Background from '../components/background';
import HeaderDropdown from '../components/headerDropdown'
import { headerList } from '../utils/consts';

export default class LoggedInPageNotFound extends Component {
    componentDidMount() {
		let userRef = db.ref('users/' + auth.currentUser.uid);
		userRef
			.child('publicFields/')
			.once('value')
			.then(snapshot => {
				let publicInfo = snapshot.val();
				this.setState({
					profile: publicInfo,
				});
			});
	}

	constructor(props) {
        super(props);
        this.onBackClicked = this.onBackClicked.bind(this);

        this.state = {
			profile: {},
		};
    }
    
    onBackClicked() {
        this.props.history.goBack();
    }

	render() {
		return (
			<div className="error-root">
				<div className="all-background">
					<Background />
				</div>
                <div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown
							login={auth.currentUser}
							userInfo={this.state.profile}
							dropdownList={headerList}
						/>
					</div>
				</div>

				<div className="error-container">
					<div className="error-body">
						<div className="error-title"> Page Not Found </div>
                        <div className='error-content'>Isn't it broken in the first place?</div>
                        <button className="error-back-button" onClick={this.onBackClicked}>Back</button>
					</div>
				</div>
			</div>
		);
	}
}
