import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { auth, db } from '../utils/firebase';
import DateSelection from '../components/dateSelection';
import Background from '../components/background';
import HeaderDropdown from '../components/headerDropdown';
import { headerList } from '../utils/consts';
import '../styles/extendPromise.css';
import '../styles/formTransition.css';

export default class ExtendPromise extends Component {
	componentDidMount() {
		let allMyFriends = [];

		db.ref('users/' + auth.currentUser.uid)
			.child('myFriends')
			.on('value', snapshot => {
				let myFriends = [];
				if (snapshot.exists()) {
					allMyFriends = snapshot.val();
					for (let i in allMyFriends) {
						db.ref('users/' + allMyFriends[i].friendId)
							.child('publicFields')
							.once('value')
							.then(snapshot => {
								let friendInfo = snapshot.val();
								myFriends.push({
									id: allMyFriends[i].friendId,
									name: friendInfo.displayName,
								});

								this.setState({
									friends: myFriends,
								});
							});
					}
				}
			});

		db.ref('promises/' + this.state.promiseId)
			.child('history')
			.once('value')
			.then(snapshot => {
				let historyArray = snapshot.val();
				let historySize = historyArray.length;
				let oldContent = historyArray[historySize - 1].content;
				let oldExpiration = historyArray[historySize - 1].life;

				let oldPrivacy = historyArray[historySize - 1].privacy;
				// Month: [0, 11]
				let selectedMonth = new Date(oldExpiration).getMonth();
				// Year
				let selectedYear = new Date(oldExpiration).getFullYear();
				// Date: [1, 31]
				let selectedDay = new Date(oldExpiration).getDate();

				// Set old promise fields as default value for new promise
				this.setState({
					oldContent: oldContent,
					newContent: oldContent,
					oldPrivacy: oldPrivacy,
					selectedYear: selectedYear,
					selectedMonth: selectedMonth,
					selectedDay: selectedDay,
				});
			});
	}

	constructor(props) {
		super(props);
		let storedUserName = {};
		let storedUserPromiseId = {};
		if (props.location.state === undefined) {
			storedUserName = localStorage.getItem('username');
			storedUserPromiseId = localStorage.getItem('userPromiseId');
		} else {
			storedUserName = props.location.state.userName;
			localStorage.setItem('username', storedUserName);
			storedUserPromiseId = props.location.state.userPromiseId;
			localStorage.setItem('userPromiseId', storedUserPromiseId);
		}
		this.state = {
			isNeverExpired: false,
			selectedDay: '',
			selectedMonth: '',
			selectedYear: '',
			friends: [],
			receiverName: storedUserName,
			receiverPromiseId: storedUserPromiseId,
			promiseId: this.props.match.params.id,
			oldContent: '',
			oldPrivacy: '',
			reason: '',
		};

		this.handleReason = this.handleReason.bind(this);
		this.handleDay = this.handleDay.bind(this);
		this.handleMonth = this.handleMonth.bind(this);
		this.handleYear = this.handleYear.bind(this);
	}

	handleDay(day) {
		this.setState({
			selectedDay: day,
		});
	}

	handleMonth(month) {
		this.setState({
			selectedMonth: month,
		});
	}

	handleYear(year) {
		this.setState({
			selectedYear: year,
		});
	}

	handleReason(event) {
		this.setState({ reason: event.target.value });
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	validateDate(day, month, year) {
		if (this.state.isNeverExpired) return true;
		else {
			let date = month + 1 + '/' + day + '/' + year;
			let life = new Date(date).getTime();
			let currentTime = new Date().getTime();
			return life > currentTime ? true : false;
		}
	}

	makePromise() {
		let valid = true;
		let originalId = this.state.promiseId;

		let date = this.state.selectedMonth + 1 + '/' + this.state.selectedDay + '/' + this.state.selectedYear;
		let life;
		let currentTime = new Date().getTime();
		if (this.state.isNeverExpired) {
			let largeDate = new Date('01/01/2500');
			life = new Date(largeDate).getTime();
		} else {
			life = new Date(date).getTime();
			if (life < currentTime) {
				let dateError = document.getElementById('date-error show');
				dateError.className = 'expiration-date-alert';
				valid = false;
			} else {
				let dateError = document.getElementById('date-error');
				dateError.className = 'expiration-date-alert';
			}
		}

		let reason = this.state.reason;

		if (!reason) {
			let reasonError = document.getElementById('reason-error');
			reasonError.className = 'no-reason-alert show';
			valid = false;
		} else {
			let reasonError = document.getElementById('reason-error');
			reasonError.className = 'no-reason-alert';
		}

		let newHistory = {};
		let notification = {};

		db.ref('promises/' + originalId)
			.child('history')
			.once('value')
			.then(snapshot => {
				let allHistory = snapshot.val();
				let latestHistory = allHistory[allHistory.length - 1];
				notification = {
					promiseId: originalId,
					sender: auth.currentUser.uid,
					createdOn: currentTime,
					content: latestHistory.content,
					oldLife: latestHistory.life,
					newLife: life,
					oldPrivacy: latestHistory.privacy,
					newPrivacy: latestHistory.privacy,
					contentType: 'extended',
					isRead: false,
				};
				newHistory = {
					createdOn: currentTime,
					action: 'extend',
					content: latestHistory.content,
					reason: reason,
					privacy: latestHistory.privacy,
					life: life,
				};

				allHistory.push(newHistory);
				let newPromiseData = {
					life: life,
					history: allHistory,
					notification: notification,
					lastChange: currentTime,
				};
				if (!valid) {
					return;
				} else {
					db.ref('promises/' + originalId)
						.update(newPromiseData)
						.then(() => {
							alert('You have extended your promise!');
						});

					db.ref('promises/' + originalId)
						.child('receiver')
						.once('value')
						.then(snapshot => {
							let receiverId = snapshot.val();
							if (receiverId === 'public') return;
							db.ref('notifications/' + receiverId)
								.push(notification)
								.catch(err => {
									alert(err);
								});
						});
				}
			});
	}

	goBack() {
		this.props.history.goBack();
	}

	handleCheckboxChange(e) {
		this.setState({
			isNeverExpired: e.target.checked,
		});
	}

	render() {
		console.log(this.state);

		return (
			<div className="change-promise-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown login={auth.currentUser.uid} dropdownList={headerList} />
					</div>
				</div>
				<div className="extend-promise-content">
					<div className="extend-promise-page-title">Extend Promise</div>
					<div className="extend-promise-extension">
						<div className="edit-promise-titles" id="extend-promise-extension-title">
							Extend the promise to:
						</div>
						<div className="extension-selector-container">
							<DateSelection
								isDisabled={this.state.isNeverExpired}
								selectedYear={this.state.selectedYear}
								selectedMonth={this.state.selectedMonth}
								selectedDay={this.state.selectedDay}
								onSelectedDay={this.handleDay}
								onSelectedMonth={this.handleMonth}
								onSelectedYear={this.handleYear}
								type="after"
							/>
							<input
								type="checkbox"
								className="expiration-forever-checkbox"
								id="expiration-forever"
								onChange={this.handleCheckboxChange.bind(this)}
							/>
							<span>Forever</span>
							<div className="expiration-date-alert" id="date-error">
								Please enter a date after today's date.
							</div>
						</div>
					</div>
					<div className="extend-promise-reason">
						<div className="edit-promise-titles" id="extend-promise-reason-title">
							Extend reason:
						</div>
						<textarea
							className="extend-promise-reason-content"
							placeholder="Extend reason..."
							id="reason"
							value={this.state.reason}
							onChange={this.handleReason}
						/>
						<div className="no-reason-alert" id="reason-error">
							Please enter a reason.
						</div>
					</div>

					<button className="create-promise-cancel-promise-button" onClick={this.goBack.bind(this)}>
						<div className="create-promise-cancel-link">Cancel</div>
					</button>

					<button className="extend-promise-button">
						{this.validateDate(this.state.selectedDay, this.state.selectedMonth, this.state.selectedYear) &&
						this.state.reason ? (
							<Link
								className="promise-make-link"
								to={{
									pathname: '/myPromises/sent/' + this.state.promiseId,
									state: {
										receiverName: this.state.receiverName,
										receiverPromiseId: this.state.receiverPromiseId,
										action: 'extended',
									},
								}}
								onClick={this.makePromise.bind(this)}
							>
								Extend
							</Link>
						) : (
							<Link
								className="promise-make-link"
								to={{
									pathname: '/myPromises/extend/' + this.state.promiseId,
								}}
								onClick={this.makePromise.bind(this)}
							>
								Extend
							</Link>
						)}
					</button>
				</div>
			</div>
		);
	}
}
