import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../pages/home';
import CreatePromise from '../pages/createPromise';
import Settings from '../pages/settings';
import Notifications from '../pages/notifications';

import PromiseRouter from './promiseRouter';
import FriendsRouter from './friendsRouter';
import UserPage from '../pages/userPage';
import PromisePage from '../pages/promisePage';
import ResetPassword from '../pages/resetPassword';
import Billboard from '../pages/billboard';
import HandleEmailLink from '../pages/emailLinkHandler';
import ResetPasswordCheckEmail from '../pages/resetPasswordCheckEmail';

export default class LoggedInRouter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPromiseContent: null,
		};
	}

	myCallback(dataFromChild) {
		this.setState({ currentPromiseContent: dataFromChild });
	}

	render() {
		return (
			<div>
				<Switch>
					<Route path="/myPromises" component={PromiseRouter} />
					<Route
						exact
						path="/create"
						render={props => (
							<CreatePromise
								{...props}
								promiseContent={this.state.currentPromiseContent}
								isFirstEdit={true}
							/>
						)}
					/>
					<Route exact path="/billboard" component={Billboard} />
					<Route exct path="/friends" component={FriendsRouter} />
					<Route exact path="/settings" component={Settings} />
					<Route exact path="/notifications" component={Notifications} />
					<Route path="/__/auth/handleEmailLink" component={HandleEmailLink} />
					<Route exact path="/resetPassword" component={ResetPassword} />
					<Route path="/resetPasswordCheckEmail" component={ResetPasswordCheckEmail} />
					<Route path="/user/:id" render={props => <UserPage {...props} />} />
					<Route path="/promises/:id" component={PromisePage} />

					<Route
						exact
						path="/"
						render={props => <Home {...props} callbackFromMain={this.myCallback.bind(this)} />}
					/>
				</Switch>
			</div>
		);
	}
}
