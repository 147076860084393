import React, { Component } from 'react';

import { auth, db } from '../utils/firebase';
import Background from '../components/background';
import { Link } from 'react-router-dom';
import HeaderDropdown from '../components/headerDropdown';
import { headerList } from '../utils/consts';
import NotificationCard from '../components/notificationCard';

import '../styles/notifications.css';

const notificationsRef = db.ref('notifications/');

export default class Notifications extends Component {
	componentDidMount() {
		let allNotifications = [];

		db.ref('notifications/' + auth.currentUser.uid)
			.once('value')
			.then(snapshot => {
				console.log(snapshot.val());
				let update = {
					isRead: true,
				};
				for (let notificationId in snapshot.val()) {
					db.ref('notifications/' + auth.currentUser.uid + '/' + notificationId).update(update);
				}
			});

		notificationsRef
			.child(auth.currentUser.uid)
			.once('value')
			.then(snapshot => {
				if (snapshot.exists()) {
					for (let i in snapshot.val()) {
						let notificationInfo = snapshot.val()[i];
						let senderId = notificationInfo.sender;
						db.ref('users/' + senderId)
							.child('publicFields')
							.once('value')
							.then(snapshot => {
								let senderImg = snapshot.val().avatarURL;
								let senderName = snapshot.val().displayName;
								let senderPromiseId = snapshot.val().promiseId;
								let notificationView = {};
								if (notificationInfo.contentType === 'expired') {
									notificationView = {
										id: i,
										senderId: senderId,
										senderName: senderName,
										senderImage: senderImg,
										senderPromiseId: senderPromiseId,
										promiseId: notificationInfo.promiseId,
										promiseOldContent: notificationInfo.oldContent,
										promiseNewContent: notificationInfo.newContent,
										createdOn: notificationInfo.createdOn,
										contentType: notificationInfo.contentType,
										buttonType: 'expired',
									};
								} else if (notificationInfo.contentType === 'follow') {
									db.ref('users/' + auth.currentUser.uid)
										.child('myFriends')
										.orderByChild('friendId')
										.equalTo(senderId)
										.once('value')
										.then(snapshot => {
											if (!snapshot.exists()) {
												db.ref('requests/' + senderId)
													.orderByChild('senderId')
													.equalTo(auth.currentUser.uid)
													.once('value')
													.then(snapshot => {
														if (snapshot.exists()) {
															db.ref('users/' + auth.currentUser.uid)
																.child('myFollowing')
																.orderByChild('followingId')
																.equalTo(senderId)
																.once('value')
																.then(snapshot => {
																	if (snapshot.exists()) {
																		notificationView = {
																			id: i,
																			senderId: senderId,
																			senderName: senderName,
																			senderImage: senderImg,
																			senderPromiseId: senderPromiseId,
																			promiseId: notificationInfo.promiseId,
																			promiseOldContent:
																				notificationInfo.oldContent,
																			promiseNewContent:
																				notificationInfo.newContent,
																			createdOn: notificationInfo.createdOn,
																			contentType: notificationInfo.contentType,
																			buttonType: 'requested-followed',
																		};
																		allNotifications.push(notificationView);
																		this.setState({
																			notifications: allNotifications,
																		});
																	} else {
																		notificationView = {
																			id: i,
																			senderId: senderId,
																			senderName: senderName,
																			senderImage: senderImg,
																			senderPromiseId: senderPromiseId,
																			promiseId: notificationInfo.promiseId,
																			promiseOldContent:
																				notificationInfo.oldContent,
																			promiseNewContent:
																				notificationInfo.newContent,
																			createdOn: notificationInfo.createdOn,
																			contentType: notificationInfo.contentType,
																			buttonType: 'requested-follow',
																		};
																		allNotifications.push(notificationView);
																		this.setState({
																			notifications: allNotifications,
																		});
																	}
																});
														} else {
															db.ref('users/' + auth.currentUser.uid)
																.child('myFollowing')
																.orderByChild('followingId')
																.equalTo(senderId)
																.once('value')
																.then(snapshot => {
																	if (snapshot.exists()) {
																		notificationView = {
																			id: i,
																			senderId: senderId,
																			senderName: senderName,
																			senderImage: senderImg,
																			senderPromiseId: senderPromiseId,
																			promiseId: notificationInfo.promiseId,
																			promiseOldContent:
																				notificationInfo.oldContent,
																			promiseNewContent:
																				notificationInfo.newContent,
																			createdOn: notificationInfo.createdOn,
																			contentType: notificationInfo.contentType,
																			buttonType: 'add-followed',
																		};
																		allNotifications.push(notificationView);
																		this.setState({
																			notifications: allNotifications,
																		});
																	} else {
																		notificationView = {
																			id: i,
																			senderId: senderId,
																			senderName: senderName,
																			senderImage: senderImg,
																			senderPromiseId: senderPromiseId,
																			promiseId: notificationInfo.promiseId,
																			promiseOldContent:
																				notificationInfo.oldContent,
																			promiseNewContent:
																				notificationInfo.newContent,
																			createdOn: notificationInfo.createdOn,
																			contentType: notificationInfo.contentType,
																			buttonType: 'add-follow',
																		};
																		allNotifications.push(notificationView);
																		this.setState({
																			notifications: allNotifications,
																		});
																	}
																});
														}
													});
											} else {
												notificationView = {
													id: i,
													senderId: senderId,
													senderName: senderName,
													senderImage: senderImg,
													senderPromiseId: senderPromiseId,
													promiseId: notificationInfo.promiseId,
													promiseOldContent: notificationInfo.oldContent,
													promiseNewContent: notificationInfo.newContent,
													createdOn: notificationInfo.createdOn,
													contentType: notificationInfo.contentType,
													buttonType: 'friended',
												};
												allNotifications.push(notificationView);
												this.setState({
													notifications: allNotifications,
												});
											}
										});
								} else if (notificationInfo.contentType === 'friend-request') {
									db.ref('users/' + auth.currentUser.uid)
										.child('myFriends')
										.orderByChild('friendId')
										.equalTo(senderId)
										.once('value')
										.then(snapshot => {
											if (!snapshot.exists()) {
												db.ref('requests/' + auth.currentUser.uid)
													.orderByChild('senderId')
													.equalTo(senderId)
													.once('value')
													.then(snapshot => {
														if (snapshot.exists()) {
															notificationView = {
																id: i,
																senderId: senderId,
																senderName: senderName,
																senderImage: senderImg,
																senderPromiseId: senderPromiseId,
																promiseId: notificationInfo.promiseId,
																promiseOldContent: notificationInfo.oldContent,
																promiseNewContent: notificationInfo.newContent,
																createdOn: notificationInfo.createdOn,
																contentType: notificationInfo.contentType,
																buttonType: 'confirm-reject',
															};
															allNotifications.push(notificationView);
															this.setState({
																notifications: allNotifications,
															});
														} else {
															db.ref('requests/' + senderId)
																.orderByChild('senderId')
																.equalTo(auth.currentUser.uid)
																.once('value')
																.then(snapshot => {
																	if (snapshot.exists()) {
																		db.ref('users/' + auth.currentUser.uid)
																			.child('myFollowing')
																			.orderByChild('followingId')
																			.equalTo(senderId)
																			.once('value')
																			.then(snapshot => {
																				if (snapshot.exists()) {
																					notificationView = {
																						id: i,
																						senderId: senderId,
																						senderName: senderName,
																						senderImage: senderImg,
																						senderPromiseId: senderPromiseId,
																						promiseId:
																							notificationInfo.promiseId,
																						promiseOldContent:
																							notificationInfo.oldContent,
																						promiseNewContent:
																							notificationInfo.newContent,
																						createdOn:
																							notificationInfo.createdOn,
																						contentType:
																							notificationInfo.contentType,
																						buttonType:
																							'requested-followed',
																					};
																					allNotifications.push(
																						notificationView
																					);
																					this.setState({
																						notifications: allNotifications,
																					});
																				} else {
																					notificationView = {
																						id: i,
																						senderId: senderId,
																						senderName: senderName,
																						senderImage: senderImg,
																						senderPromiseId: senderPromiseId,
																						promiseId:
																							notificationInfo.promiseId,
																						promiseOldContent:
																							notificationInfo.oldContent,
																						promiseNewContent:
																							notificationInfo.newContent,
																						createdOn:
																							notificationInfo.createdOn,
																						contentType:
																							notificationInfo.contentType,
																						buttonType: 'requested-follow',
																					};
																					allNotifications.push(
																						notificationView
																					);
																					this.setState({
																						notifications: allNotifications,
																					});
																				}
																			});
																	} else {
																		db.ref('users/' + auth.currentUser.uid)
																			.child('myFollowing')
																			.orderByChild('followingId')
																			.equalTo(senderId)
																			.once('value')
																			.then(snapshot => {
																				if (snapshot.exists()) {
																					notificationView = {
																						id: i,
																						senderId: senderId,
																						senderName: senderName,
																						senderImage: senderImg,
																						senderPromiseId: senderPromiseId,
																						promiseId:
																							notificationInfo.promiseId,
																						createdOn:
																							notificationInfo.createdOn,
																						promiseOldContent:
																							notificationInfo.oldContent,
																						promiseNewContent:
																							notificationInfo.newContent,
																						contentType:
																							notificationInfo.contentType,
																						buttonType: 'add-followed',
																					};
																					allNotifications.push(
																						notificationView
																					);
																					this.setState({
																						notifications: allNotifications,
																					});
																				} else {
																					notificationView = {
																						id: i,
																						senderId: senderId,
																						senderName: senderName,
																						senderImage: senderImg,
																						senderPromiseId: senderPromiseId,
																						promiseId:
																							notificationInfo.promiseId,
																						createdOn:
																							notificationInfo.createdOn,
																						promiseOldContent:
																							notificationInfo.oldContent,
																						promiseNewContent:
																							notificationInfo.newContent,
																						contentType:
																							notificationInfo.contentType,
																						buttonType: 'add-follow',
																					};
																					allNotifications.push(
																						notificationView
																					);
																					this.setState({
																						notifications: allNotifications,
																					});
																				}
																			});
																	}
																});
														}
													});
											} else {
												notificationView = {
													id: i,
													senderId: senderId,
													senderName: senderName,
													senderImage: senderImg,
													senderPromiseId: senderPromiseId,
													promiseId: notificationInfo.promiseId,
													promiseOldContent: notificationInfo.oldContent,
													promiseNewContent: notificationInfo.newContent,
													createdOn: notificationInfo.createdOn,
													contentType: notificationInfo.contentType,
													buttonType: 'friended',
												};
												allNotifications.push(notificationView);
												this.setState({
													notifications: allNotifications,
												});
											}
										});
								} else {
									notificationView = {
										id: i,
										senderId: senderId,
										senderName: senderName,
										senderImage: senderImg,
										senderPromiseId: senderPromiseId,
										promiseId: notificationInfo.promiseId,
										promiseOldContent: notificationInfo.oldContent,
										promiseNewContent: notificationInfo.newContent,
										createdOn: notificationInfo.createdOn,
										contentType: notificationInfo.contentType,
										buttonType: 'track',
									};
									allNotifications.push(notificationView);
									this.setState({
										notifications: allNotifications,
									});
								}
							});
					}
				} else {
					this.setState({
						emptyNotification: true,
					});
				}
			});
	}

	constructor(props) {
		super(props);
		this.state = {
			notifications: [],
		};
	}

	renderContent(list) {
		if (this.state.emptyNotification) {
			return (
				<div className="emtpy-notification">
					<div className="empty-notification-text">You don't have notification at the moment.</div>
				</div>
			);
		} else {
			let currentList = this.removeDuplicates(list, 'id');
			let displayList = currentList.sort((a, b) => {
				return b.createdOn - a.createdOn;
			});
			return displayList.map((obj, index) => {
				return <NotificationCard cardId={index + 1} key={obj.id} notification={obj} />;
			});
		}
	}

	removeDuplicates(myArr, prop) {
		return myArr.filter((obj, pos, arr) => {
			return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
		});
	}

	render() {
		console.log(this.state);
		return (
			<div className="notifications-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown login={auth.currentUser.uid} dropdownList={headerList} />
					</div>
				</div>

				<div className="notifications-container">{this.renderContent(this.state.notifications)}</div>
			</div>
		);
	}
}
