import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../styles/userCard.css';
import ButtonAction from './buttonAction';
import { generateUserNameLink } from '../utils/links';

export default class UserCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userId: this.props.user.systemId,
			promiseId: this.props.user.promiseId,
			email: this.props.user.email,
			userName: this.props.user.userName,
			image: this.props.user.imageURL,
		};
	}

	render() {
		let type = this.props.type;
		console.log(this.state);
		switch (type) {
			case 'friended': {
				return (
					<div className="user-card-root">
						<div className="user-card">
							<div className="user-card-left-and-middle">
								<div className="user-card-left">
									<Link to={`/user/${this.state.promiseId}`}>
										<img
											className="profile-image"
											alt="image"
											src={this.state.image}
											width="130"
											height="130"
										/>
									</Link>
								</div>

								<div className="user-card-middle">
									<div className="user-card-user-name">
										{generateUserNameLink(
											this.state.userId,
											this.state.promiseId,
											this.state.userName,
											'user-card-name-link'
										)}
									</div>
									<div className="user-card-promise-id">{this.props.user.promiseId}</div>
								</div>
							</div>
							<div className="user-card-right">
								<div className="user-card-bottons-container">
									<ButtonAction
										id={this.props.cardId}
										uid={this.state.userId}
										userName={this.state.userName}
										type="friended"
									/>
								</div>
							</div>
						</div>
					</div>
				);
			}

			case 'add-follow': {
				return (
					<div className="user-card-root">
						<div className="user-card">
							<div className="user-card-left-and-middle">
								<div className="user-card-left">
									<Link to={`/user/${this.state.promiseId}`}>
										<img
											className="profile-image"
											alt="image"
											src={this.state.image}
											width="150"
											height="150"
										/>
									</Link>
								</div>

								<div className="user-card-middle">
									<div className="user-card-user-name">
										{generateUserNameLink(
											this.state.userId,
											this.state.promiseId,
											this.state.userName,
											'user-card-name-link'
										)}
									</div>
									<div className="user-card-promise-id">{this.props.user.promiseId}</div>
								</div>
							</div>
							<div className="user-card-right">
								<ButtonAction
									id={this.props.cardId}
									uid={this.state.userId}
									userName={this.state.userName}
									type="add-follow"
								/>
							</div>
						</div>
					</div>
				);
			}

			case 'add-followed': {
				return (
					<div className="user-card-root">
						<div className="user-card">
							<div className="user-card-left-and-middle">
								<div className="user-card-left">
									<Link to={`/user/${this.state.promiseId}`}>
										<img
											className="profile-image"
											alt="image"
											src={this.state.image}
											width="130"
											height="130"
										/>
									</Link>
								</div>

								<div className="user-card-middle">
									<div className="user-card-user-name">
										{generateUserNameLink(
											this.state.userId,
											this.state.promiseId,
											this.state.userName,
											'user-card-name-link'
										)}
									</div>
									<div className="user-card-promise-id">{this.props.user.promiseId}</div>
								</div>
							</div>
							<div className="user-card-right">
								<ButtonAction
									id={this.props.cardId}
									uid={this.state.userId}
									userName={this.state.userName}
									type="add-followed"
								/>
							</div>
						</div>
					</div>
				);
			}

			case 'requested-followed': {
				return (
					<div className="user-card-root">
						<div className="user-card">
							<div className="user-card-left-and-middle">
								<div className="user-card-left">
									<Link to={`/user/${this.state.promiseId}`}>
										<img
											className="profile-image"
											alt="image"
											src={this.state.image}
											width="130"
											height="130"
										/>
									</Link>
								</div>

								<div className="user-card-middle">
									<div className="user-card-user-promise-id">
										{generateUserNameLink(
											this.state.userId,
											this.state.promiseId,
											this.state.userName,
											'user-card-name-link'
										)}
									</div>
									<div className="user-card-promise-id">{this.props.user.promiseId}</div>
								</div>
							</div>
							<div className="user-card-right">
								<ButtonAction
									id={this.props.cardId}
									uid={this.state.userId}
									userName={this.state.userName}
									type="requested-followed"
								/>
							</div>
						</div>
					</div>
				);
			}

			case 'requested-follow': {
				return (
					<div className="user-card-root">
						<div className="user-card">
							<div className="user-card-left-and-middle">
								<div className="user-card-left">
									<Link to={`/user/${this.state.promiseId}`}>
										<img
											className="profile-image"
											alt="image"
											src={this.state.image}
											width="130"
											height="130"
										/>
									</Link>
								</div>

								<div className="user-card-middle">
									<div className="user-card-user-promise-id">
										{generateUserNameLink(
											this.state.userId,
											this.state.promiseId,
											this.state.userName,
											'user-page-link'
										)}
									</div>
									<div className="user-card-promise-id">{this.props.user.promiseId}</div>
								</div>
							</div>
							<div className="user-card-right">
								<ButtonAction
									id={this.props.cardId}
									uid={this.state.userId}
									userName={this.state.userName}
									type="requested-follow"
								/>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
