import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { signOut, auth } from '../utils/firebase';

import '../styles/headerDropdown.css';
import '../styles/headerDropdownItem.css';

export default class HeaderDropdownItem extends Component {
	constructor(props) {
		super(props);
	}

	handleItemClicked() {
		if (this.props.item.title === 'Logout') {
			localStorage.clear();
			signOut();
		} else if (this.props.item.title === 'Notifications') {
			let dotElementInTitle = document.getElementById('dropdown-title-dot');
			console.log(dotElementInTitle);
			if (dotElementInTitle !== null) dotElementInTitle.className = 'notification-dot hide';
			console.log(dotElementInTitle);
			let dotElementInItem = document.getElementById('dropdown-item-dot');
			if (dotElementInItem) dotElementInItem.className = 'notification-dot hide';
		}
	}

	render() {
		let dropdownItem = {};
		if (this.props.item.title !== 'Notifications') {
			dropdownItem = (
				<div className="dd-item-wrapper">
					<Link
						to={{
							pathname: this.props.item.url,
							state: this.props.userInfo,
						}}
						className="dropdown-link"
					>
						{this.props.item.title}
					</Link>
				</div>
			);
		} else {
			dropdownItem = (
				<div className="dd-item-wrapper">
					<div>
						<Link
							to={{
								pathname: this.props.item.url,
								state: this.props.userInfo,
							}}
							className="dropdown-link"
						>
							{this.props.item.title}
						</Link>
					</div>

					{this.props.hasRedDot && <span className="notification-dot" id="dropdown-item-dot" />}
				</div>
			);
		}

		return (
			<div className="dd-list-item" onClick={this.handleItemClicked.bind(this)}>
				{dropdownItem}
			</div>
		);
	}
}
