import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { timeSince } from '../utils/time';
import { generateUserNameLink, generateUserImageLink } from '../utils/links';
import '../styles/billboardCard.css';

export default class BillboardCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			billboardId: this.props.billboard.billboardId,
			promiseId: this.props.billboard.promiseId,
			createdOn: this.props.billboard.createdOn,
			content: this.props.billboard.content,
			senderName: this.props.billboard.senderName,
			senderImage: this.props.billboard.senderImage,
			senderId: this.props.billboard.sender,
			senderPromiseId: this.props.billboard.senderPromiseId,
			status: this.props.billboard.status,
			lastChange: this.props.billboard.lastChange,
			life: this.props.billboard.life,
			action: this.props.billboard.action,
		};
	}

	render() {
		let actionType = this.state.action;

		switch (actionType) {
			case 'make': {
				return (
					<div className="billboard-card-container">
						<div className="billboard-card-top">
							{generateUserImageLink(
								this.props.billboard.senderId,
								this.props.billboard.senderPromiseId,
								this.props.billboard.senderName,
								this.props.billboard.senderImage,
								'billboard-card-profile-image'
							)}
							<div className="billboard-card-top-right">
								<div className="billboard-card-user-name">
									{generateUserNameLink(
										this.props.billboard.senderId,
										this.props.billboard.senderPromiseId,
										this.props.billboard.senderName,
										'billboard-card-user-link'
									)}
								</div>
								<div className="billboard-card-action"> made a promise</div>
							</div>
						</div>
						<div className="billboard-card-bottom">
							<div className="billboard-card-promise-content">
								{
									<Link
										to={`/promises/${this.state.promiseId}`}
										className="billboard-card-promise-link"
									>
										{this.state.content}
									</Link>
								}
							</div>
							<div className="billboard-card-last-change">{timeSince(this.state.lastChange)}</div>
						</div>
					</div>
				);
			}

			case 'change': {
				return (
					<div className="billboard-card-container">
						<div className="billboard-card-top">
							<Link to={`/user/${this.props.billboard.senderPromiseId}`}>
								<img
									className="billboard-card-profile-image"
									alt="image"
									src={this.state.senderImage}
									width="100"
									height="100"
								/>
							</Link>
							<div className="billboard-card-top-right">
								<div className="billboard-card-user-name">
									{
										<Link
											to={{
												pathname: `/user/${this.state.senderPromiseId}`,
												state: {
													userPromiseId: this.state.senderPromiseId,
													userName: this.state.sender,
												},
											}}
											className="billboard-card-user-link"
										>
											{this.state.senderName}
										</Link>
									}
								</div>
								<div className="billboard-card-action"> changed a promise</div>
							</div>
						</div>
						<div className="billboard-card-bottom">
							<div className="billboard-card-promise-content">
								{
									<Link
										to={`/promises/${this.state.promiseId}`}
										className="billboard-card-promise-link"
									>
										{this.state.content}
									</Link>
								}
							</div>
							<div className="billboard-card-last-change">{timeSince(this.state.lastChange)}</div>
						</div>
					</div>
				);
			}

			case 'break': {
				return (
					<div className="billboard-card-container">
						<div className="billboard-card-top">
							<Link to={`/user/${this.props.billboard.senderPromiseId}`}>
								<img
									className="billboard-card-profile-image"
									alt="image"
									src={this.state.senderImage}
									width="100"
									height="100"
								/>
							</Link>
							<div className="billboard-card-top-right">
								<div className="billboard-card-user-name">
									{
										<Link
											to={{
												pathname: `/user/${this.state.senderPromiseId}`,
												state: {
													userPromiseId: this.state.senderPromiseId,
													userName: this.state.sender,
												},
											}}
											className="billboard-card-user-link"
										>
											{this.state.senderName}
										</Link>
									}
								</div>
								<div className="billboard-card-action"> broke a promise</div>
							</div>
						</div>
						<div className="billboard-card-bottom">
							<div className="billboard-card-promise-content">
								{
									<Link
										to={`/promises/${this.state.promiseId}`}
										className="billboard-card-promise-link"
									>
										{this.state.content}
									</Link>
								}
							</div>
							<div className="billboard-card-last-change">{timeSince(this.state.lastChange)}</div>
						</div>
					</div>
				);
			}

			case 'extend': {
				return (
					<div className="billboard-card-container">
						<div className="billboard-card-top">
							<Link to={`/user/${this.props.billboard.senderPromiseId}`}>
								<img
									className="billboard-card-profile-image"
									alt="image"
									src={this.state.senderImage}
									width="100"
									height="100"
								/>
							</Link>
							<div className="billboard-card-top-right">
								<div className="billboard-card-user-name">
									{
										<Link
											to={{
												pathname: `/user/${this.state.senderPromiseId}`,
												state: {
													userPromiseId: this.state.senderPromiseId,
													userName: this.state.sender,
												},
											}}
											className="billboard-card-user-link"
										>
											{this.state.senderName}
										</Link>
									}
								</div>
								<div className="billboard-card-action"> extended a promise</div>
							</div>
						</div>
						<div className="billboard-card-bottom">
							<div className="billboard-card-promise-content">
								{
									<Link
										to={`/promises/${this.state.promiseId}`}
										className="billboard-card-promise-link"
									>
										{this.state.content}
									</Link>
								}
							</div>
							<div className="billboard-card-last-change">{timeSince(this.state.lastChange)}</div>
						</div>
					</div>
				);
			}

			case 'finish': {
				return (
					<div className="billboard-card-container">
						<div className="billboard-card-top">
							<Link to={`/user/${this.props.billboard.senderPromiseId}`}>
								<img
									className="billboard-card-profile-image"
									alt="image"
									src={this.state.senderImage}
									width="100"
									height="100"
								/>
							</Link>
							<div className="billboard-card-top-right">
								<div className="billboard-card-user-name">
									{
										<Link
											to={{
												pathname: `/user/${this.state.senderPromiseId}`,
												state: {
													userPromiseId: this.state.senderPromiseId,
													userName: this.state.sender,
												},
											}}
											className="billboard-card-user-link"
										>
											{this.state.senderName}
										</Link>
									}
								</div>
								<div className="billboard-card-action"> finished a promise</div>
							</div>
						</div>
						<div className="billboard-card-bottom">
							<div className="billboard-card-promise-content">
								{
									<Link
										to={`/promises/${this.state.promiseId}`}
										className="billboard-card-promise-link"
									>
										{this.state.content}
									</Link>
								}
							</div>
							<div className="billboard-card-last-change">{timeSince(this.state.lastChange)}</div>
						</div>
					</div>
				);
			}
		}
	}
}
