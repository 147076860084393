import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../utils/firebase';
import '../styles/login.css';
import '../styles/formTransition.css';
import Background from '../components/background';

import { validateEmail } from '../utils/formValidate';

export default class Login extends Component {
	constructor() {
		super();
		this.state = {
			email: '',
			password: '',
		};
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleSubmitSignin() {
		if (auth.currentUser) {
			// Sign out
			auth.signOut();
		} else {
			let email = this.state.email;
			let password = this.state.password;
			let emailErrorElement = document.getElementById('error-email');
			let emailInputBorder = document.getElementsByClassName('login-email-input')[0];
			let passwordErrorElement = document.getElementById('error-password');
			let passwordInputBorder = document.getElementsByClassName('login-password-input')[0];

			if (!validateEmail(email)) {
				this.setState({
					emailErrorCode: 'invalid-email',
				});
				emailErrorElement.className = 'login-email-error-message show';
				emailInputBorder.className = 'login-email-input border-red';
				return;
			} else {
				emailErrorElement.className = 'login-email-error-message';
				emailInputBorder.className = 'login-email-input';
			}

			// Authenticate with email
			auth.signInWithEmailAndPassword(email, password)
				.then(result => {
					passwordErrorElement.className = 'login-password-error-message';
					passwordInputBorder.className = 'login-password-input';
					auth.onAuthStateChanged(() => {
						this.props.history.push('/');
					});
				})
				.catch(error => {
					let errorCode = error.code;
					if (errorCode === 'auth/wrong-password') {
						this.setState({
							passwordErrorCode: 'wrong-password',
						});
						passwordErrorElement.className = 'login-password-error-message show';
						passwordInputBorder.className = 'login-password-input border-red';
					} else {
						this.setState({
							emailErrorCode: 'wrong-email',
						});
						emailErrorElement.className = 'login-email-error-message show';
						emailInputBorder.className = 'login-email-input border-red';
					}
				});
		}
	}

	render() {
		console.log(this.state);
		let emailErrorMessage;
		let passwordErrorMessage = 'Wrong password.';
		switch (this.state.emailErrorCode) {
			case 'invalid-email': {
				emailErrorMessage = 'Invalid email.';
				break;
			}
			case 'wrong-email': {
				emailErrorMessage =
					'There is no user record corresponding to this email address. Please try another one.';
				break;
			}
			default:
				break;
		}
		return (
			<div className="signin-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
				</div>
				<div className="login-container">
					<div className="login-body">
						<div className="login-text"> Sign in </div>
						<div className="login-email">
							<div className="login-email-text">Email</div>
							<input
								className="login-email-input"
								name="email"
								type="text"
								placeholder=""
								value={this.state.email}
								required
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
						<div className="login-email-error-message" id="error-email">
							{emailErrorMessage}
						</div>
						<div className="login-password">
							<div className="login-password-text">Password</div>
							<input
								className="login-password-input"
								name="password"
								type="password"
								placeholder=""
								required
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
						<div className="login-password-error-message" id="error-password">
							{passwordErrorMessage}
						</div>
						<div className="login-forgot-password">
							<Link
								to={{
									pathname: '/resetPassword',
									state: {
										email: this.state.email,
									},
								}}
								className="login-forgot-password"
							>
								Forgot password?
							</Link>
						</div>
						<div className="signinquestion-container">
							<Link to="/signup" className="create-account-link">
								Create account
							</Link>
							<button className="signin-button" onClick={this.handleSubmitSignin.bind(this)}>
								Sign in
							</button>
							<Link to="/signup" className="create-account-link-mobile">
								Create account
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
