import React, { Component } from 'react';
import firebase from 'firebase';
import { auth, db } from '../utils/firebase';

import '../styles/button.css';
import ButtonDropdown from './buttonDropdown';
import { userActionList } from '../utils/consts';
import { sendRequest, acceptFriendRequest, deleteFriendRequest } from '../utils/requests';
const firebaseRequestRef = db.ref('requests/');
const firebaseNotificationRef = db.ref('notifications/');

export default class ButtonAction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userId: this.props.uid,
			userName: this.props.userName,
			type: this.props.type,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			userId: nextProps.uid,
			userName: nextProps.userName,
			type: nextProps.type,
		});
	}

	onAddFriendClicked(e) {
		let senderId = auth.currentUser.uid;
		let receiverId = e.target.getAttribute('uid');
		let receiverName = e.target.getAttribute('name');
		let next = e.target.getAttribute('next');
		if (next) {
			this.setState({
				type: next,
			});
		}

		if (senderId === receiverId) {
			alert('You cannot send a friend request to your self.');
		} else {
			let request = {
				receiverId: receiverId,
				receiverName: receiverName,
				senderId: senderId,
				senderName: auth.currentUser.displayName,
				timeStamp: firebase.database.ServerValue.TIMESTAMP,
			};
			let notification = {
				createdOn: firebase.database.ServerValue.TIMESTAMP,
				sender: senderId,
				isRead: false,
				contentType: 'friend-request',
			};

			firebaseRequestRef
				.child(request.receiverId)
				.orderByChild('senderId')
				.equalTo(request.senderId)
				.once('value', snapshot => {
					if (snapshot.val()) {
						console.log('Same friend request sent.');
					} else {
						sendRequest(request)
							.then(response => {
								if (response.success) {
									firebaseNotificationRef.child(receiverId).push(notification);
									alert('Your friend request was successfully sent.');
								}
							})
							.catch(error => {
								console.log(error);
							});
					}
				});
		}
	}

	onUnfriendClicked(e) {
		let next = e.target.getAttribute('next');
		if (next) {
			this.setState({
				type: next,
			});
		}
		let yourFriendId = e.target.getAttribute('uid');

		db.ref('users/' + yourFriendId)
			.child('myFriends')
			.orderByChild('friendId')
			.equalTo(auth.currentUser.uid)
			.once('value')
			.then(snapshot => {
				snapshot.forEach(childSnapshot => {
					db.ref('users/' + yourFriendId)
						.child('myFriends/' + childSnapshot.key)
						.remove()
						.then(() => {
							console.log('Delete record on your friend.');
						});
				});
			});

		db.ref('users/' + auth.currentUser.uid)
			.child('myFriends')
			.orderByChild('friendId')
			.equalTo(yourFriendId)
			.once('value')
			.then(snapshot => {
				snapshot.forEach(childSnapshot => {
					db.ref('users/' + auth.currentUser.uid)
						.child('myFriends/' + childSnapshot.key)
						.remove()
						.then(() => {
							console.log('Delete record on your friend.');
						});
				});
			});
	}

	onFollowClicked(e) {
		console.log(e.target);
		let followerId = auth.currentUser.uid;
		let followedId = e.target.getAttribute('uid');
		let followedName = e.target.getAttribute('name');
		let followed = {
			followingId: followedId,
			followingName: followedName,
		};
		let follower = {
			followerId: followerId,
			followerName: auth.currentUser.displayName,
		};

		let next = e.target.getAttribute('next');
		if (next) {
			this.setState({
				type: next,
			});
		}

		let notification = {
			createdOn: firebase.database.ServerValue.TIMESTAMP,
			sender: followerId,
			isRead: false,
			contentType: 'follow',
		};
		// db.ref('notifications/' + followedId)
		// 	.orderByChild('sender')
		// 	.equalTo(notification.sender)
		// 	.once('value')
		// 	.then(snapshot => {
		// 		if (snapshot.exists()) {
		// 			console.log(notification.sender + ' has followed ' + followedId + '.');
		// 		} else {
		// 			firebaseNotificationRef.child(followedId).push(notification);
		// 		}
		// 	});

		db.ref('users/' + followerId)
			.child('myFollowing')
			.orderByChild('followingId')
			.equalTo(followedId)
			.once('value')
			.then(snapshot => {
				if (snapshot.exists()) {
					alert('You have already followed: ' + followedName);
				} else {
					db.ref('users/' + followerId)
						.child('myFollowing')
						.push(followed)
						.then(() => {
							db.ref('users/' + followedId)
								.child('myFollowers')
								.push(follower);
						});
					firebaseNotificationRef.child(followedId).push(notification);
				}
			});
	}

	onUnfollowClicked(e) {
		let next = e.target.getAttribute('next');
		if (next) {
			this.setState({
				type: next,
			});
		}

		let followerId = auth.currentUser.uid;
		let followedId = e.target.getAttribute('uid');

		db.ref('users/' + followerId)
			.child('myFollowing')
			.orderByChild('followingId')
			.equalTo(followedId)
			.once('value')
			.then(snapshot => {
				snapshot.forEach(childSnapshot => {
					db.ref('users/' + followerId)
						.child('myFollowing/' + childSnapshot.key)
						.remove()
						.then(() => {
							console.log('Delete record on your following.');
						});
				});
			});

		db.ref('users/' + followedId)
			.child('myFollowers')
			.orderByChild('followerId')
			.equalTo(followerId)
			.once('value')
			.then(snapshot => {
				snapshot.forEach(childSnapshot => {
					db.ref('users/' + followedId)
						.child('myFollowers/' + childSnapshot.key)
						.remove()
						.then(() => {
							console.log('Delete record on your followers.');
						});
				});
			});
	}

	onConfirmClicked(e) {
		let next = e.target.getAttribute('next');
		if (next) {
			this.setState({
				type: next,
			});
		}
		let friendId = e.target.getAttribute('uid');
		let friendName = e.target.name;
		let friend = {
			friendId: friendId,
			friendName: friendName,
		};

		acceptFriendRequest(friend)
			.then(response => {
				if (response.success) {
					alert('You have accepted friend request from ' + friend.friendName + '.');
				}
			})
			.catch(error => {
				alert(error);
			});
	}

	onRejectClicked(e) {
		let next = e.target.getAttribute('next');
		if (next) {
			this.setState({
				type: next,
			});
		}

		let friendId = e.target.getAttribute('uid');
		let friendName = e.target.name;
		let friend = {
			friendId: friendId,
			friendName: friendName,
		};

		deleteFriendRequest(friend)
			.then(response => {
				if (response.success) {
					alert('You have ignored friend request from ' + friend.friendName + '.');
				}
			})
			.catch(error => {
				alert(error);
			});
	}

	render() {
		switch (this.state.type) {
			case 'default': {
				return <h1>Loading...</h1>;
			}
			case 'track': {
				return (
					<div className="button-action-group">
						<button
							id={'track_' + this.props.id}
							className="track-promise-button"
							uid={this.state.userId}
							name={this.state.userName}
						>
							Track
						</button>
					</div>
				);
			}
			case 'friended': {
				return (
					<div className="button-action-group">
						<ButtonDropdown
							id={this.props.cardId}
							uid={this.state.userId}
							title="Friend"
							dropdownList={userActionList.friendAction}
							callback={this.onUnfriendClicked.bind(this)}
							next="add-follow"
						/>
					</div>
				);
			}
			case 'add-follow': {
				return (
					<div className="button-action-group">
						<button
							id={'add_' + this.props.id}
							className="add-friend-button"
							onClick={this.onAddFriendClicked.bind(this)}
							uid={this.state.userId}
							name={this.state.userName}
							next="requested-followed"
						>
							Add
						</button>
						<button
							id={'follow_' + this.props.id}
							className="follow-button"
							onClick={this.onFollowClicked.bind(this)}
							uid={this.state.userId}
							name={this.state.userName}
							next="add-followed"
						>
							Follow
						</button>
					</div>
				);
			}
			case 'add-followed': {
				return (
					<div className="button-action-group">
						<button
							id={'add_' + this.props.id}
							className="add-friend-button"
							onClick={this.onAddFriendClicked.bind(this)}
							uid={this.state.userId}
							name={this.state.userName}
							next="requested-followed"
						>
							Add
						</button>
						<ButtonDropdown
							id={this.props.cardId}
							uid={this.state.userId}
							title="Followed"
							dropdownList={userActionList.followAction}
							callback={this.onUnfollowClicked.bind(this)}
							next="add-follow"
						/>
					</div>
				);
			}
			case 'requested-followed': {
				return (
					<div className="button-action-group">
						<button
							id={'request_sent_' + this.props.id}
							className="already-friend-button"
							uid={this.state.userId}
							name={this.state.userName}
						>
							Request sent
						</button>
						<ButtonDropdown
							id={this.props.cardId}
							uid={this.state.userId}
							title="Followed"
							dropdownList={userActionList.followAction}
							callback={this.onUnfollowClicked.bind(this)}
							next="requested-follow"
						/>
					</div>
				);
			}
			case 'requested-follow': {
				return (
					<div className="button-action-group">
						<button
							id={'request_sent_' + this.props.id}
							className="already-friend-button"
							uid={this.state.userId}
							name={this.state.userName}
						>
							Request sent
						</button>
						<button
							id={'follow_' + this.props.id}
							className="follow-button"
							onClick={this.onFollowClicked.bind(this)}
							uid={this.state.userId}
							name={this.state.userName}
							next="requested-followed"
						>
							Follow
						</button>
					</div>
				);
			}
			case 'confirm-reject': {
				return (
					<div className="button-action-group">
						<button
							id="confirm"
							className="add-friend-button"
							onClick={this.onConfirmClicked.bind(this)}
							uid={this.state.userId}
							name={this.state.userName}
							next="friended"
						>
							Confirm
						</button>
						<button
							id="reject"
							className="add-friend-button"
							onClick={this.onRejectClicked.bind(this)}
							uid={this.state.userId}
							name={this.state.userName}
							next="rejected"
						>
							Reject
						</button>
					</div>
				);
			}
			case 'rejected': {
				return (
					<div className="button-action-group">
						<button
							id="rejected"
							className="ignore-friend-button"
							uid={this.state.userId}
							name={this.state.userName}
						>
							Friend request rejected
						</button>
					</div>
				);
			}
		}
	}
}
