import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import HistoryCard from './historyCard';
import { standardTime, convertUTCToLocal } from '../utils/time';
import '../styles/promiseDetails.css';
import { generateUserNameLink, generateUserImageLink } from '../utils/links';
import { auth } from '../utils/firebase';

export default class PromiseDetails extends Component {
	renderHistory() {
		if (this.props.promiseData.history) {
			let history = this.props.promiseData.history;
			history.reverse();
			return history.map(obj => {
				return <HistoryCard key={obj.createdOn} data={obj} />;
			});
		}
	}

	render() {
		console.log(this.props);
		let promiseStatus = this.props.promiseData.status;
		let receiverInfo = {};
		const LARGE_DATE = new Date('01/01/2500').getTime();
		const neverExpire = this.props.promiseData.life == LARGE_DATE;

		if (this.props.receiver.receiverId === 'public') {
			receiverInfo = (
				<div className="promise-details-receiver-info">
					<div className="promise-details-receiver-text">
						<div className="promise-details-titles" id="promise-details-receiver-titles">
							To
						</div>
						<div className="promise-details-receiver">public</div>
					</div>
				</div>
			);
		} else {
			receiverInfo = (
				<div className="promise-details-receiver-info">
					<div className="promise-details-receiver-text">
						<div className="promise-details-titles" id="promise-details-receiver-titles">
							To
						</div>
						<div className="promise-details-receiver-containter">
							{generateUserNameLink(
								this.props.receiver.receiverId,
								this.props.receiver.publicFields.promiseId,
								this.props.receiver.publicFields.displayName,
								'promise-details-receiver'
							)}
						</div>
					</div>
					{generateUserImageLink(
						this.props.receiver.receiverId,
						this.props.receiver.publicFields.promiseId,
						this.props.receiver.publicFields.displayName,
						this.props.receiver.publicFields.avatarURL,
						'promise-details-user-image'
					)}
				</div>
			);
		}
		let senderInfo = (
			<div className="promise-details-sender-info">
				{generateUserImageLink(
					this.props.sender.senderId,
					this.props.sender.publicFields.promiseId,
					this.props.sender.publicFields.displayName,
					this.props.sender.publicFields.avatarURL,
					'promise-details-user-image'
				)}
				<div className="promise-details-sender-text">
					<div className="promise-details-titles" id="promise-details-sender-titles">
						From
					</div>
					<div className="promise-details-sender-containter">
						{generateUserNameLink(
							this.props.sender.senderId,
							this.props.sender.publicFields.promiseId,
							this.props.sender.publicFields.displayName,
							'promise-details-sender'
						)}
					</div>
				</div>
			</div>
		);

		let keepingButton = <div />;
		let expiredButton = <div />;
		let finishedButton = (
			<div className="promise-details-buttons">
				<button disabled className="promise-details-button">
					Change
				</button>
				<button disabled className="promise-details-button">
					Break
				</button>
			</div>
		);
		let brokenButton = (
			<div className="promise-details-buttons">
				<button disabled className="promise-details-button">
					Change
				</button>
				<button disabled className="promise-details-button">
					Break
				</button>
			</div>
		);

		if (auth.currentUser.uid === this.props.sender.senderId) {
			if (this.props.receiver.receiverId === 'public') {
				keepingButton = (
					<div className="promise-details-buttons">
						<button className="promise-details-button">
							{
								<Link
									to={{
										pathname: '/myPromises/change/' + this.props.promiseId,
										state: {
											promiseId: this.props.promiseId,
											userName: this.props.receiver.receiverId,
										},
									}}
									className="promise-details-button-link"
								>
									Change
								</Link>
							}
						</button>
						<button className="promise-details-button">
							{
								<Link
									to={{
										pathname: '/myPromises/break/' + this.props.promiseId,
										state: {
											promiseId: this.props.promiseId,
											userName: this.props.receiver.receiverId,
										},
									}}
									className="promise-details-button-link"
								>
									Break
								</Link>
							}
						</button>
						<button className="promise-details-button">
							{
								<Link
									to={{
										pathname: '/myPromises/finish/' + this.props.promiseId,
										state: {
											promiseId: this.props.promiseId,
											userName: this.props.receiver.receiverId,
										},
									}}
									className="promise-details-button-link"
								>
									Finish
								</Link>
							}
						</button>
					</div>
				);
				expiredButton = (
					<div className="promise-details-buttons">
						<button className="promise-details-button">
							{
								<Link
									to={{
										pathname: '/myPromises/extend/' + this.props.promiseId,
										state: {
											promiseId: this.props.promiseId,
											userName: this.props.receiver.receiverId,
										},
									}}
									className="promise-details-button-link"
								>
									Extend
								</Link>
							}
						</button>
						<button className="promise-details-button">
							{
								<Link
									to={{
										pathname: '/myPromises/break/' + this.props.promiseId,
										state: {
											promiseId: this.props.promiseId,
											userName: this.props.receiver.receiverId,
										},
									}}
									className="promise-details-button-link"
								>
									Break
								</Link>
							}
						</button>
						<button className="promise-details-button">
							{
								<Link
									to={{
										pathname: '/myPromises/finish/' + this.props.promiseId,
										state: {
											promiseId: this.props.promiseId,
											userName: this.props.receiver.receiverId,
										},
									}}
									className="promise-details-button-link"
								>
									Finish
								</Link>
							}
						</button>
					</div>
				);
			} else {
				keepingButton = (
					<div className="promise-details-buttons">
						<button className="promise-details-button">
							{
								<Link
									to={{
										pathname: '/myPromises/change/' + this.props.promiseId,
										state: {
											promiseId: this.props.promiseId,
											userName: this.props.receiver.publicFields.displayName,
										},
									}}
									className="promise-details-button-link"
								>
									Change
								</Link>
							}
						</button>
						<button className="promise-details-button">
							{
								<Link
									to={{
										pathname: '/myPromises/break/' + this.props.promiseId,
										state: {
											promiseId: this.props.promiseId,
											userName: this.props.receiver.publicFields.displayName,
										},
									}}
									className="promise-details-button-link"
								>
									Break
								</Link>
							}
						</button>
						<button className="promise-details-button">
							{
								<Link
									to={{
										pathname: '/myPromises/finish/' + this.props.promiseId,
										state: {
											promiseId: this.props.promiseId,
											userName: this.props.receiver.publicFields.receiverId,
										},
									}}
									className="promise-details-button-link"
								>
									Finish
								</Link>
							}
						</button>
					</div>
				);
				expiredButton = (
					<div className="promise-details-buttons">
						<button className="promise-details-button">
							{
								<Link
									to={{
										pathname: '/myPromises/extend/' + this.props.promiseId,
										state: {
											promiseId: this.props.promiseId,
											userName: this.props.receiver.publicFields.receiverId,
										},
									}}
									className="promise-details-button-link"
								>
									Extend
								</Link>
							}
						</button>
						<button className="promise-details-button">
							{
								<Link
									to={{
										pathname: '/myPromises/break/' + this.props.promiseId,
										state: {
											promiseId: this.props.promiseId,
											userName: this.props.receiver.publicFields.receiverId,
										},
									}}
									className="promise-details-button-link"
								>
									Break
								</Link>
							}
						</button>
						<button className="promise-details-button">
							{
								<Link
									to={{
										pathname: '/myPromises/finish/' + this.props.promiseId,
										state: {
											promiseId: this.props.promiseId,
											userName: this.props.receiver.publicFields.receiverId,
										},
									}}
									className="promise-details-button-link"
								>
									Finish
								</Link>
							}
						</button>
					</div>
				);
			}
		}
		switch (promiseStatus) {
			case 'keeping': {
				return (
					<div className="promise-details">
						<div className="promise-details-top">
							{senderInfo}
							{receiverInfo}
						</div>
						<div className="promise-details-middle">
							<div className="promise-details-promise-info">
								<div className="promise-details-titles">Promise</div>
								<div className="promise-details-promise">{this.props.promiseData.content}</div>
							</div>
							<div className="promise-details-middle-right">
								<div className="promise-details-status-info">
									<div className="promise-details-titles">Status</div>
									<div className="promise-details-contents">{this.props.promiseData.status}</div>
								</div>
								<div className="promise-details-createdon-info">
									<div className="promise-details-titles">Created on</div>
									<div className="promise-details-contents">
										{standardTime(convertUTCToLocal(this.props.promiseData.createdOn))}
									</div>
								</div>

								<div className="promise-details-expiration-info">
									<div className="promise-details-titles">Expiration</div>
									<div className="promise-details-contents">
										{neverExpire
											? 'Never'
											: standardTime(convertUTCToLocal(this.props.promiseData.life))}
									</div>
								</div>
								<div className="promise-details-privacy-info">
									<div className="promise-details-titles">Privacy</div>
									<div className="promise-details-contents">{this.props.promiseData.privacy}</div>
								</div>
							</div>
						</div>

						<div className="promise-history">
							<div className="promise-details-titles" id="promise-history-title">
								History (Scroll Right)
							</div>
							<div className="promise-history-timeline">{this.renderHistory()}</div>
						</div>
						{keepingButton}
					</div>
				);
			}
			case 'broken': {
				return (
					<div className="promise-details">
						<div className="promise-details-top">
							{senderInfo}
							{receiverInfo}
						</div>
						<div className="promise-details-middle">
							<div className="promise-details-promise-info">
								<div className="promise-details-titles">Promise</div>
								<div className="promise-details-promise">{this.props.promiseData.content}</div>
							</div>
							<div className="promise-details-middle-right">
								<div className="promise-details-status-info">
									<div className="promise-details-titles">Status</div>
									<div className="promise-details-contents">{this.props.promiseData.status}</div>
								</div>
								<div className="promise-details-createdon-info">
									<div className="promise-details-titles">Created on</div>
									<div className="promise-details-contents">
										{standardTime(convertUTCToLocal(this.props.promiseData.createdOn))}
									</div>
								</div>

								<div className="promise-details-expiration-info">
									<div className="promise-details-titles">Expiration</div>
									<div className="promise-details-contents">
										{neverExpire
											? 'Never'
											: standardTime(convertUTCToLocal(this.props.promiseData.life))}
									</div>
								</div>
								<div className="promise-details-privacy-info">
									<div className="promise-details-titles">Privacy</div>
									<div className="promise-details-contents">{this.props.promiseData.privacy}</div>
								</div>
							</div>
						</div>

						<div className="promise-history">
							<div className="promise-details-titles" id="promise-history-title">
								History (Scroll Right)
							</div>
							<div className="promise-history-timeline">{this.renderHistory()}</div>
						</div>
						{brokenButton}
					</div>
				);
			}
			case 'expired': {
				return (
					<div className="promise-details">
						<div className="promise-details-top">
							{senderInfo}
							{receiverInfo}
						</div>
						<div className="promise-details-middle">
							<div className="promise-details-promise-info">
								<div className="promise-details-titles">Promise</div>
								<div className="promise-details-promise">{this.props.promiseData.content}</div>
							</div>
							<div className="promise-details-middle-right">
								<div className="promise-details-status-info">
									<div className="promise-details-titles">Status</div>
									<div className="promise-details-contents">{this.props.promiseData.status}</div>
								</div>
								<div className="promise-details-createdon-info">
									<div className="promise-details-titles">Created on</div>
									<div className="promise-details-contents">
										{standardTime(convertUTCToLocal(this.props.promiseData.createdOn))}
									</div>
								</div>

								<div className="promise-details-expiration-info">
									<div className="promise-details-titles">Expiration</div>
									<div className="promise-details-contents">
										{neverExpire
											? 'Never'
											: standardTime(convertUTCToLocal(this.props.promiseData.life))}
									</div>
								</div>
								<div className="promise-details-privacy-info">
									<div className="promise-details-titles">Privacy</div>
									<div className="promise-details-contents">{this.props.promiseData.privacy}</div>
								</div>
							</div>
						</div>

						<div className="promise-history">
							<div className="promise-details-titles" id="promise-history-title">
								History (Scroll Right)
							</div>
							<div className="promise-history-timeline">{this.renderHistory()}</div>
						</div>
						{expiredButton}
					</div>
				);
			}
			case 'finished': {
				return (
					<div className="promise-details">
						<div className="promise-details-top">
							{senderInfo}
							{receiverInfo}
						</div>
						<div className="promise-details-middle">
							<div className="promise-details-promise-info">
								<div className="promise-details-titles">Promise</div>
								<div className="promise-details-promise">{this.props.promiseData.content}</div>
							</div>
							<div className="promise-details-middle-right">
								<div className="promise-details-status-info">
									<div className="promise-details-titles">Status</div>
									<div className="promise-details-contents">{this.props.promiseData.status}</div>
								</div>
								<div className="promise-details-createdon-info">
									<div className="promise-details-titles">Created on</div>
									<div className="promise-details-contents">
										{standardTime(convertUTCToLocal(this.props.promiseData.createdOn))}
									</div>
								</div>

								<div className="promise-details-expiration-info">
									<div className="promise-details-titles">Expiration</div>
									<div className="promise-details-contents">
										{neverExpire
											? 'Never'
											: standardTime(convertUTCToLocal(this.props.promiseData.life))}
									</div>
								</div>
								<div className="promise-details-privacy-info">
									<div className="promise-details-titles">Privacy</div>
									<div className="promise-details-contents">{this.props.promiseData.privacy}</div>
								</div>
							</div>
						</div>

						<div className="promise-history">
							<div className="promise-details-titles" id="promise-history-title">
								History (Scroll Right)
							</div>
							<div className="promise-history-timeline">{this.renderHistory()}</div>
						</div>
						{finishedButton}
					</div>
				);
			}
		}
	}
}
