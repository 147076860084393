import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { auth, db } from '../utils/firebase';
import Background from '../components/background';
import Tabs from '../components/tabs';

import PromiseCard from '../components/promiseCard';
import HeaderDropdown from '../components/headerDropdown';
import { headerList } from '../utils/consts';
import { PulseSpinner } from 'react-spinners-kit';

import '../styles/myPromise.css';
import '../styles/home.css';

export default class MyPromises extends Component {
	componentDidMount() {
		let allMyReceivedPromises = [];
		let allMySentPromises = [];
		let allMyNewPromises = [];
		let allMyExpiredPromises = [];
		let allMyKeepingPromises = [];
		let allMyBrokenPromises = [];
		let allMyFinishedPromises = [];

		let userRef = db.ref('users/' + auth.currentUser.uid);

		userRef
			.child('publicFields/')
			.once('value')
			.then(snapshot => {
				let publicInfo = snapshot.val();
				this.setState({
					profile: publicInfo,
				});
			});

		userRef.child('myPromises/sent').on('value', snapshot => {
			if (snapshot.exists()) {
				let numOfPromises = snapshot.val().length;
				for (let i in snapshot.val()) {
					db.ref('promises/')
						.child(snapshot.val()[i])
						.on('value', snapshot => {
							let promise = snapshot.val();
							console.log('ID: ' + i + ' Promise: ' + promise);
							if (promise.status === 'expired') allMyExpiredPromises.push(promise);
							else if (promise.status === 'keeping') allMyKeepingPromises.push(promise);
							else if (promise.status === 'broken') allMyBrokenPromises.push(promise);
							else if (promise.status === 'finished') allMyFinishedPromises.push(promise);
						});
				}

				this.setState({
					totalPromiseMade: numOfPromises,
					expiredPromises: allMyExpiredPromises,
					keepingPromises: allMyKeepingPromises,
					brokenPromises: allMyBrokenPromises,
					finishedPromises: allMyFinishedPromises,
				});
			}
		});

		userRef.child('myPromises/newPromises').on('value', snapshot => {
			let newPromises = [];
			if (snapshot.exists()) {
				allMyNewPromises = snapshot.val();
				for (let i in allMyNewPromises) {
					newPromises.push(i);
				}
			}
			this.setState({
				newPromises: newPromises,
			});
		});

		userRef.child('myPromises/received').once('value', snapshot => {
			let receivedPromises = [];
			if (snapshot.exists()) {
				allMyReceivedPromises = snapshot.val();
				for (let i in allMyReceivedPromises) {
					db.ref('promises/' + allMyReceivedPromises[i])
						.once('value')
						.then(snapshot => {
							let instance = snapshot.val();
							let historySize = instance.history.length;
							let latestHistory = instance.history[historySize - 1];
							db.ref('users/' + instance.sender)
								.child('publicFields')
								.once('value', snapshot => {
									let senderName = snapshot.val().displayName;
									let senderPromiseId = snapshot.val().promiseId;
									let promiseView = {
										id: allMyReceivedPromises[i],
										createdOn: instance.createdOn,
										content: instance.content,
										userName: senderName,
										userId: instance.sender,
										userPromiseId: senderPromiseId,
										status: instance.status,
										privacy: instance.privacy,
										lastChange: latestHistory.createdOn,
										life: instance.life,
									};
									receivedPromises.push(promiseView);
									receivedPromises.sort((a, b) => {
										return b.lastChange - a.lastChange;
									});
									this.setState({
										receivedPromises: receivedPromises,
										receivedLoading: false,
									});
								});
						});
				}
			} else {
				this.setState({
					receivedPromises: receivedPromises,
					receivedLoading: false,
					emptyReceivedPromise: true,
				});
			}
		});

		userRef.child('myPromises/sent').once('value', snapshot => {
			let sentPromises = [];
			if (snapshot.exists()) {
				allMySentPromises = snapshot.val();
				for (let i in allMySentPromises) {
					db.ref('promises/' + allMySentPromises[i])
						.once('value')
						.then(snapshot => {
							let instance = snapshot.val();
							let historySize = instance.history.length;
							let latestHistory = instance.history[historySize - 1];
							let promiseView = {};
							if (instance.receiver === 'public') {
								promiseView = {
									id: allMySentPromises[i],
									createdOn: instance.createdOn,
									content: instance.content,
									userName: instance.receiver,
									userId: instance.receiver,
									userPromiseId: instance.receiver,
									status: instance.status,
									privacy: instance.privacy,
									lastChange: latestHistory.createdOn,
									life: instance.life,
								};
								sentPromises.push(promiseView);
								sentPromises.sort((a, b) => {
									return b.lastChange - a.lastChange;
								});
								this.setState({
									madePromises: sentPromises,
									madeLoading: false,
								});
							} else {
								db.ref('users/' + instance.receiver)
									.child('publicFields')
									.once('value', snapshot => {
										let receiverName = snapshot.val().displayName;
										let receiverPromiseId = snapshot.val().promiseId;
										promiseView = {
											id: allMySentPromises[i],
											createdOn: instance.createdOn,
											content: instance.content,
											userName: receiverName,
											userId: instance.receiver,
											userPromiseId: receiverPromiseId,
											status: instance.status,
											privacy: instance.privacy,
											lastChange: latestHistory.createdOn,
											life: instance.life,
										};
										sentPromises.push(promiseView);
										sentPromises.sort((a, b) => {
											return b.lastChange - a.lastChange;
										});
										this.setState({
											madePromises: sentPromises,
											madeLoading: false,
										});
									});
							}
						});
				}
			} else {
				this.setState({
					madePromises: sentPromises,
					madeLoading: false,
					emptyMadePromise: true,
				});
			}
		});
	}

	constructor(props) {
		super(props);
		this.state = {
			profile: {},
			newPromises: [],
			expiredPromises: [],
			keepingPromises: [],
			brokenPromises: [],
			finishedPromises: [],
			madePromises: [],
			receivedPromises: [],
			madeLoading: true,
			receivedLoading: true,
		};
	}

	renderMadePromiseCards() {
		if (!this.state.emptyMadePromise) {
			return this.state.madePromises.map(obj => {
				return <PromiseCard type="made" status={obj.status} key={obj.id} promise={obj} />;
			});
		} else {
			return (
				<div className="empty-made-promise">
					<div className="empty-made-promise-text">You have not made a promise yet.</div>
					&nbsp;
					<Link to="/create" className="empty-made-promise-link">
						Make a promise
					</Link>
				</div>
			);
		}
	}

	renderReceivedPromiseCards() {
		if (!this.state.emptyReceivedPromise) {
			return this.state.receivedPromises.map(obj => {
				return <PromiseCard type="received" status={obj.status} key={obj.id} promise={obj} />;
			});
		} else {
			return (
				<div className="empty-received-promise">
					<div className="empty-received-promise-text">You have not received a promise yet.</div>
				</div>
			);
		}
	}

	renderNotificationMessage() {
		let numOfNewPromises = this.state.newPromises.length;
		let numOfExpiredPromises = this.state.expiredPromises.length;

		return (
			<div>
				You have
				<Link
					to={{
						pathname: '/notifications',
						state: {
							newPromises: this.state.newPromises,
						},
					}}
					className="promises-profile-notifications-link"
				>
					{numOfNewPromises} new promises
				</Link>
				and
				<Link
					to={{
						pathname: '/notifications',
						state: {
							expiredPromises: this.state.expiredPromises,
						},
					}}
					className="promises-profile-notifications-link"
				>
					{numOfExpiredPromises} expired promises
				</Link>
			</div>
		);
	}

	render() {
		let totalPromiseMade =
			this.state.keepingPromises.length +
			this.state.brokenPromises.length +
			this.state.finishedPromises.length +
			this.state.expiredPromises.length;

		let keepingPromiselength = this.state.keepingPromises.length
			? ((this.state.keepingPromises.length / totalPromiseMade) * 100).toFixed(2) + '%'
			: 0 + '%';
		let brokenPromiselength = this.state.brokenPromises.length
			? ((this.state.brokenPromises.length / totalPromiseMade) * 100).toFixed(2) + '%'
			: 0 + '%';
		let finishedPromiselength = this.state.finishedPromises.length
			? ((this.state.finishedPromises.length / totalPromiseMade) * 100).toFixed(2) + '%'
			: 0 + '%';
		let expiredPromiselength = this.state.expiredPromises.length
			? ((this.state.expiredPromises.length / totalPromiseMade) * 100).toFixed(2) + '%'
			: 0 + '%';

		return (
			<div className="promises-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown login={auth.currentUser} dropdownList={headerList} />
					</div>
				</div>
				<div className="promises-personal-profile">
					<div className="promises-personal-profile-left">
						<img
							className="promises-profile-image"
							id="avatar"
							src={this.state.profile.avatarURL}
							width="200"
							heigth="200"
						/>
					</div>
					<div className="promises-personal-profile-right">
						<div className="promises-personal-profile-right-top">
							<div className="promises-personal-profile-right-top-right">
								<div className="promises-profile-username">{this.state.profile.displayName}</div>
								<div className="promises-profile-promiseId">
									<p className="promises-profile-promiseId-title">Promise ID:</p>
									{this.state.profile.promiseId}
								</div>
							</div>
							<Link to="/settings" className="promises-profile-settings-link">
								Edit profile
							</Link>
						</div>
						<Link to="/settings" className="promises-profile-settings-link-mobile">
							Edit profile
						</Link>

						{/* <div className="promises-statistics">
                            Total: {totalPromiseMade}
                        </div>
                        <div className="promises-statistics">
                            Keeping: {this.state.keepingPromises.length}
                        </div>
                        <div className="promises-statistics">
                            Broken: {this.state.brokenPromises.length}
                        </div>
                        <div className="promises-statistics">
                            Finished: {this.state.finishedPromises.length}
                        </div>
                        <div className="promises-statistics">
                            Expired: {this.state.expiredPromises.length}
						</div> */}

						<div className="my-promise-profile-svg-container">
							<svg width={keepingPromiselength} height="50">
								<text x="10" y="15" className="my-promise-profile-svg-number">
									{this.state.keepingPromises.length}
								</text>
								<text x="36" y="15" className="my-promise-profile-svg-text">
									Keeping Promise
								</text>
								<rect
									x="0"
									y="23"
									width="100%"
									height="5"
									rx="2"
									ry="2"
									className="my-promise-profile-svg-keeping"
								/>
							</svg>
							<svg width={finishedPromiselength} height="50">
								<text x="10" y="15" className="my-promise-profile-svg-number">
									{this.state.finishedPromises.length}
								</text>
								<text x="36" y="15" className="my-promise-profile-svg-text">
									Finished Promise
								</text>
								<rect
									x="0"
									y="23"
									width="100%"
									height="5"
									rx="2"
									ry="2"
									className="my-promise-profile-svg-finished"
								/>
							</svg>
							<svg width={brokenPromiselength} height="50">
								<text x="10" y="15" className="my-promise-profile-svg-number">
									{this.state.brokenPromises.length}
								</text>
								<text x="36" y="15" className="my-promise-profile-svg-text">
									Broken Promise
								</text>
								<rect
									x="0"
									y="23"
									width="100%"
									height="5"
									rx="2"
									ry="2"
									className="my-promise-profile-svg-broken"
								/>
							</svg>

							<svg width={expiredPromiselength} height="50">
								<text x="10" y="15" className="my-promise-profile-svg-number">
									{this.state.expiredPromises.length}
								</text>
								<text x="36" y="15" className="my-promise-profile-svg-text">
									Expired Promise
								</text>
								<rect
									x="0"
									y="23"
									width="100%"
									height="5"
									rx="2"
									ry="2"
									className="my-promise-profile-svg-expired"
								/>
							</svg>
						</div>
					</div>
				</div>
				<div className="promises-profile-notification">{this.renderNotificationMessage()}</div>
				<div className="promises-container">
					<Tabs tabs="my-promises-tabs" tabList="my-promises-tab-list" tabContent="my-promises-tab-content">
						<div label="Made" useSVG={true} svgType="envelope" className="my-promise-svg-tab">
							<div className="loading-spinner-wrapper">
								<PulseSpinner size={60} color="#fff" loading={this.state.madeLoading} />
							</div>
							{!this.state.madeLoading ? this.renderMadePromiseCards() : null}
						</div>
						<div label="Received" useSVG={true} svgType="envelope" className="my-promise-svg-tab">
							<div className="loading-spinner-wrapper">
								<PulseSpinner size={60} color="#fff" loading={this.state.receivedLoading} />
							</div>
							{!this.state.receivedLoading ? this.renderReceivedPromiseCards() : null}
						</div>
					</Tabs>
				</div>
			</div>
		);
	}
}
