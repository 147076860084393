import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { auth, db } from '../utils/firebase';
import DateSelection from '../components/dateSelection';
import Background from '../components/background';
import HeaderDropdown from '../components/headerDropdown';
import { headerList } from '../utils/consts';
import '../styles/changePromise.css';
import '../styles/formTransition.css';

export default class ChangePromise extends Component {
	componentDidMount() {
		let allMyFriends = [];

		db.ref('users/' + auth.currentUser.uid)
			.child('myFriends')
			.on('value', snapshot => {
				let myFriends = [];
				if (snapshot.exists()) {
					allMyFriends = snapshot.val();
					for (let i in allMyFriends) {
						db.ref('users/' + allMyFriends[i].friendId)
							.child('publicFields')
							.once('value')
							.then(snapshot => {
								let friendInfo = snapshot.val();
								myFriends.push({
									id: allMyFriends[i].friendId,
									name: friendInfo.displayName,
								});

								this.setState({
									friends: myFriends,
								});
							});
					}
				}
			});

		db.ref('promises/' + this.state.promiseId)
			.child('history')
			.once('value')
			.then(snapshot => {
				let historyArray = snapshot.val();
				let historySize = historyArray.length;
				let oldContent = historyArray[historySize - 1].content;
				let oldExpiration = historyArray[historySize - 1].life;
				// Display with local time
				let oldPrivacy = historyArray[historySize - 1].privacy;
				// Month: [0, 11]
				let selectedMonth = new Date(oldExpiration).getMonth();
				// Year
				let selectedYear = new Date(oldExpiration).getFullYear();
				// Date: [1, 31]
				let selectedDay = new Date(oldExpiration).getDate();

				// Set old promise fields as default value for new promise
				this.setState({
					oldContent: oldContent,
					newContent: oldContent,
					oldPrivacy: oldPrivacy,
					selectedYear: selectedYear,
					selectedMonth: selectedMonth,
					selectedDay: selectedDay,
				});
			});
	}

	constructor(props) {
		super(props);
		let storedUserName = {};
		let storedUserPromiseId = {};
		if (props.location.state === undefined) {
			storedUserName = localStorage.getItem('username');
			storedUserPromiseId = localStorage.getItem('userPromiseId');
		} else {
			storedUserName = props.location.state.userName;
			localStorage.setItem('username', storedUserName);
			storedUserPromiseId = props.location.state.userPromiseId;
			localStorage.setItem('userPromiseId', storedUserPromiseId);
		}
		this.state = {
			isNeverExpired: false,
			selectedDay: '',
			selectedMonth: '',
			selectedYear: '',
			friends: [],
			receiverName: storedUserName,
			receiverPromiseId: storedUserPromiseId,
			promiseId: this.props.match.params.id,
			newContent: '',
			oldContent: '',
			oldPrivacy: '',
			reason: '',
		};

		this.handleReason = this.handleReason.bind(this);
		this.handleNewPromise = this.handleNewPromise.bind(this);
		this.handleDay = this.handleDay.bind(this);
		this.handleMonth = this.handleMonth.bind(this);
		this.handleYear = this.handleYear.bind(this);
	}

	handleDay(day) {
		this.setState({
			selectedDay: day,
		});
	}

	handleMonth(month) {
		this.setState({
			selectedMonth: month,
		});
	}

	handleYear(year) {
		this.setState({
			selectedYear: year,
		});
	}

	handleNewPromise(event) {
		this.setState({ newContent: event.target.value });
	}

	handleReason(event) {
		this.setState({ reason: event.target.value });
	}

	validateDate(day, month, year) {
		if (this.state.isNeverExpired) return true;
		else {
			let date = month + 1 + '/' + day + '/' + year;
			let life = new Date(date).getTime();
			let currentTime = new Date().getTime();
			return life > currentTime ? true : false;
		}
	}

	makePromise() {
		let originalId = this.state.promiseId;
		let valid = true;

		let reason = this.state.reason;
		if (!reason) {
			let reasonError = document.getElementById('reason-error');
			reasonError.className = 'no-reason-alert show';
			valid = false;
		} else {
			let reasonError = document.getElementById('reason-error');
			reasonError.className = 'no-reason-alert';
		}

		let currentTime = new Date().getTime();
		let date = this.state.selectedMonth + 1 + '/' + this.state.selectedDay + '/' + this.state.selectedYear;
		let life;
		if (this.state.isNeverExpired) {
			let largeDate = new Date('01/01/2500');
			life = new Date(largeDate).getTime();
		} else {
			life = new Date(date).getTime();
			if (life < currentTime) {
				let dateError = document.getElementById('date-error');
				dateError.className = 'expiration-date-alert show';
				valid = false;
			} else {
				let dateError = document.getElementById('date-error');
				dateError.className = 'expiration-date-alert';
			}
		}

		let content = this.state.newContent;
		if (!content) {
			let contentError = document.getElementById('content-error');
			contentError.className = 'no-content-alert show';
			valid = false;
		} else {
			let reasonError = document.getElementById('content-error');
			reasonError.className = 'no-content-alert';
		}

		let privacy = document.getElementById('privacy').value;

		let newHistory = {};
		let notification = {};

		newHistory = {
			createdOn: currentTime,
			action: 'change',
			content: content,
			reason: reason,
			life: life,
			privacy: privacy,
		};

		db.ref('promises/' + originalId)
			.child('history')
			.once('value')
			.then(snapshot => {
				let allHistory = snapshot.val();
				let latestHistory = allHistory[allHistory.length - 1];
				notification = {
					promiseId: originalId,
					sender: auth.currentUser.uid,
					createdOn: currentTime,
					oldContent: latestHistory.content,
					newContent: content,
					oldLife: latestHistory.life,
					newLife: life,
					oldPrivacy: latestHistory.privacy,
					newPrivacy: privacy,
					contentType: 'changed',
					isRead: false,
				};
				allHistory.push(newHistory);
				let newPromiseData = {
					content: content,
					life: life,
					privacy: privacy,
					history: allHistory,
					notification: notification,
					lastChange: currentTime,
				};
				console.log(newPromiseData);
				if (!valid) {
					return;
				} else {
					db.ref('promises/' + originalId)
						.update(newPromiseData)
						.then(() => {
							alert('You have changed your promise!');
						});

					db.ref('promises/' + originalId)
						.child('receiver')
						.once('value')
						.then(snapshot => {
							let receiverId = snapshot.val();
							if (receiverId === 'public') return;
							db.ref('notifications/' + receiverId)
								.push(notification)
								.catch(err => {
									alert(err);
								});
						});
				}
			});
	}

	goBack() {
		this.props.history.goBack();
	}

	handleCheckboxChange(e) {
		this.setState({
			isNeverExpired: e.target.checked,
		});
	}

	render() {
		console.log(this.state);
		let privacySelection = {};
		if (this.state.receiverName === 'public') {
			if (this.state.oldPrivacy === 'Public') {
				privacySelection = (
					<select className="privacy-selector" id="privacy">
						<option value="Public">Public</option>
						<option value="Friends">Friends only</option>
					</select>
				);
			} else {
				privacySelection = (
					<select className="privacy-selector" id="privacy">
						<option value="Friends">Friends Only</option>
						<option value="Public">Public</option>
					</select>
				);
			}
		} else {
			if (this.state.oldPrivacy === 'Public') {
				privacySelection = (
					<select className="privacy-selector" id="privacy">
						<option value="Public">Public</option>
						<option value="Participants">Participants only</option>
						<option value="Friends">Friends only</option>
					</select>
				);
			} else if (this.state.oldPrivacy === 'Friends') {
				privacySelection = (
					<select className="privacy-selector" id="privacy">
						<option value="Friends">Friends only</option>
						<option value="Public">Public</option>
						<option value="Participants">Participants only</option>
					</select>
				);
			} else {
				privacySelection = (
					<select className="privacy-selector" id="privacy">
						<option value="Participants">Participants only</option>
						<option value="Public">Public</option>
						<option value="Friends">Friends only</option>
					</select>
				);
			}
		}

		let validChangedPromise = this.state.reason && this.state.newContent;

		let changeButton = {};
		if (
			this.validateDate(this.state.selectedDay, this.state.selectedMonth, this.state.selectedYear) &&
			validChangedPromise
		) {
			changeButton = (
				<button className="change-promise-button">
					<Link
						className="promise-make-link"
						to={{
							pathname: '/myPromises/sent/' + this.state.promiseId,
							state: {
								receiverName: this.state.receiverName,
								receiverPromiseId: this.state.receiverPromiseId,
								action: 'changed',
							},
						}}
						onClick={this.makePromise.bind(this)}
					>
						Change
					</Link>
				</button>
			);
		} else {
			changeButton = (
				<button className="change-promise-button">
					<Link
						className="promise-make-link"
						to={{
							pathname: '/myPromises/change/' + this.state.promiseId,
						}}
						onClick={this.makePromise.bind(this)}
					>
						Change
					</Link>
				</button>
			);
		}

		return (
			<div className="change-promise-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown login={auth.currentUser.uid} dropdownList={headerList} />
					</div>
				</div>
				<div className="change-promise-content">
					<div className="change-promise-page-title">Change Promise</div>
					<div className="change-promise-first-line">
						<div className="change-promise-receiver">
							<div className="change-promise-titles" id="change-promise-receiver-title">
								To:
							</div>
							<div className="receiver-selector-container">{this.state.receiverName}</div>
						</div>
						<div className="change-promise-privacy">
							<div className="change-promise-titles" id="change-promise-privacy-title">
								Privacy
							</div>
							<div className="privacy-selector-container">{privacySelection}</div>
						</div>
						<div className="change-promise-expiration-mobile">
							<div className="change-promise-titles" id="change-promise-expiration-title">
								Expiration:
							</div>
							<div className="change-promise-expiration-container-mobile">
								<DateSelection
									selectedYear={this.state.selectedYear}
									selectedMonth={this.state.selectedMonth}
									selectedDay={this.state.selectedDay}
									onSelectedDay={this.handleDay}
									onSelectedMonth={this.handleMonth}
									onSelectedYear={this.handleYear}
									type="after"
								/>
							</div>
						</div>
					</div>
					<div className="new-promise">
						<div className="change-promise-titles" id="change-promise-reason-title">
							New Promise:
						</div>
						<textarea
							className="new-promise-content"
							placeholder={this.state.oldContent}
							id="content"
							value={this.state.newContent}
							onChange={this.handleNewPromise}
						/>
						<div className="no-content-alert" id="content-error">
							Please enter a new promise.
						</div>
					</div>
					<div className="change-promise-reason">
						<div className="edit-promise-titles" id="change-promise-reason-title">
							Change reason:
						</div>
						<textarea
							className="change-promise-reason-content"
							placeholder="Change reason..."
							id="reason"
							value={this.state.reason}
							onChange={this.handleReason}
						/>
						<div className="no-reason-alert" id="reason-error">
							Please enter a reason.
						</div>
					</div>
					<div className="promise-submit-line">
						<div className="promise-expiration">
							<div className="edit-promise-titles" id="expiration-title">
								Expiration
							</div>
							<div className="expiration-selector-container">
								<DateSelection
									isDisabled={this.state.isNeverExpired}
									selectedYear={this.state.selectedYear}
									selectedMonth={this.state.selectedMonth}
									selectedDay={this.state.selectedDay}
									onSelectedDay={this.handleDay}
									onSelectedMonth={this.handleMonth}
									onSelectedYear={this.handleYear}
									type="after"
								/>
								<input
									type="checkbox"
									className="expiration-forever-checkbox"
									id="expiration-forever"
									onChange={this.handleCheckboxChange.bind(this)}
								/>
								<span>Forever</span>
								<div className="expiration-date-alert" id="date-error">
									Please enter a date after today's date.
								</div>
							</div>
						</div>

						<div className="change-promise-buttons">
							<button className="create-promise-cancel-promise-button" onClick={this.goBack.bind(this)}>
								<div className="create-promise-cancel-link">Cancel</div>
							</button>
							{changeButton}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
