import React, { Component } from 'react';

import firebase from 'firebase';
import { db, auth } from '../utils/firebase';

import { Link } from 'react-router-dom';

import { headerList } from '../utils/consts';
import '../styles/breakPromise.css';
import '../styles/formTransition.css';
import Background from '../components/background';
import HeaderDropdown from '../components/headerDropdown';

export default class BreakPromise extends Component {
	constructor(props) {
		super(props);
		let storedUserName = {};
		let storedUserPromiseId = {};
		if (localStorage.getItem('username') && props.location.state === undefined) {
			storedUserName = localStorage.getItem('username');
		} else {
			storedUserName = props.location.state.userName;
			localStorage.setItem('username', storedUserName);
		}
		if (localStorage.getItem('userPromiseId') && props.location.state === undefined) {
			storedUserPromiseId = localStorage.getItem('userPromiseId');
		} else {
			storedUserPromiseId = props.location.state.userPromiseId;
			localStorage.setItem('userPromiseId', storedUserPromiseId);
		}

		this.state = {
			receiverName: storedUserName,
			receiverPromiseId: storedUserPromiseId,
			promiseId: this.props.match.params.id,
			reason: '',
		};

		this.contentInputChange = this.contentInputChange.bind(this);
		this.reasonInputChange = this.reasonInputChange.bind(this);

		this.handleBreakPromise = this.handleBreakPromise.bind(this);
	}

	contentInputChange(e) {
		this.setState({
			content: e.target.value,
		});
	}

	reasonInputChange(e) {
		this.setState({
			reason: e.target.value,
		});
	}

	handleBreakPromise() {
		let promiseRef = db.ref('promises/' + this.state.promiseId);
		let valid = true;
		let reason = this.state.reason;

		if (!reason) {
			let reasonError = document.getElementById('reason-error');
			reasonError.className = 'no-reason-alert show';
			valid = false;
		} else {
			let reasonError = document.getElementById('reason-error');
			reasonError.className = 'no-reason-alert';
		}

		let currentTime = new Date().getTime();
		promiseRef
			.child('history')
			.once('value')
			.then(snapshot => {
				let allHistory = snapshot.val();
				let latestHistory = allHistory[allHistory.length - 1];
				let notification = {
					promiseId: this.state.promiseId,
					sender: auth.currentUser.uid,
					createdOn: currentTime,
					oldContent: latestHistory.content,
					newContent: '',
					oldLife: latestHistory.life,
					newLife: latestHistory.life,
					oldPrivacy: latestHistory.privacy,
					contentType: 'broken',
					isRead: false,
				};
				let newHistory = {
					createdOn: currentTime,
					action: 'break',
					content: latestHistory.content,
					reason: this.state.reason,
					life: latestHistory.life,
					privacy: latestHistory.privacy,
				};
				allHistory.push(newHistory);
				let newPromiseData = {
					content: latestHistory.content,
					privacy: latestHistory.privacy,
					history: allHistory,
					notification: notification,
					status: 'broken',
				};

				if (!valid) {
					return;
				} else {
					db.ref('promises/' + this.state.promiseId)
						.update(newPromiseData)
						.then(() => {
							alert('You have broken your promise!');
						});
					db.ref('promises/' + this.state.promiseId)
						.child('receiver')
						.once('value')
						.then(snapshot => {
							let receiverId = snapshot.val();
							if (receiverId === 'public') return;
							db.ref('notifications/' + receiverId)
								.push(notification)
								.catch(err => {
									alert(err);
								});
						});
				}
			});
	}

	goBack() {
		this.props.history.goBack();
	}

	render() {
		console.log(this.state);
		return (
			<div className="change-promise-root">
				<div className="all-background">
					<Background />
				</div>

				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown login={auth.currentUser.uid} dropdownList={headerList} />
					</div>
				</div>

				<div className="break-promise-content">
					<div className="break-promise-page-title">Break promise</div>
					<div className="break-promise-reason">
						<div className="break-promise-reason-title">Break reason </div>
						<textarea
							className="break-promise-reason-content"
							placeholder="Break reason..."
							value={this.state.reason}
							onChange={this.reasonInputChange}
						/>
						<div className="no-reason-alert" id="reason-error">
							Please enter a reason.
						</div>
					</div>
					<div className="break-promise-bottons">
						<button className="break-promise-cancel-button" onClick={this.goBack.bind(this)}>
							<div className="break-promise-cancel-link">Cancel</div>
						</button>
						<button className="break-promise-break-button" onClick={this.handleBreakPromise}>
							{this.state.reason ? (
								<Link
									className="promises-broken-link"
									to={{
										pathname: '/myPromises/broken/' + this.state.promiseId,
										state: {
											content: this.state.content,
											reason: this.state.reason,
											promiseId: this.state.promiseId,
											receiverName: this.state.receiverName,
											receiverPromiseId: this.state.receiverPromiseId,
										},
									}}
								>
									Break
								</Link>
							) : (
								<Link
									className="promises-broken-link"
									to={{
										pathname: '/myPromises/break/' + this.state.promiseId,
									}}
								>
									Break
								</Link>
							)}
						</button>
					</div>
				</div>
			</div>
		);
	}
}
