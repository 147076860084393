import React, { Component } from 'react';
import { db, auth } from '../utils/firebase';
import { generateUID } from '../utils/uid';
import Background from '../components/background';
import firebase from 'firebase';

import '../styles/emailLinkHandler.css';

export default class HandleEmailLink extends Component {
	constructor() {
		super();
		this.state = {
			username: '',
			promiseId: '',
			newPassword: '',
			confirmNewPassword: '',
		};

		this.handleSignupComplete = this.handleSignupComplete.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.redirectHome = this.redirectHome.bind(this);
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	redirectHome() {
		console.log(this.state);
		if (auth.currentUser) {
			this.props.history.push('/');
		} else {
			alert('Signup not successful, please try again.');
		}
	}

	handleSignupComplete() {
		let username = this.state.username;
		let email = window.localStorage.getItem('emailForSignIn');
		console.log(email);
		let password = window.localStorage.getItem('passwordForSignIn');
		console.log(password);
		let usernameErrorElement = document.getElementById('username-error');
		let usernameInputBorder = document.getElementsByClassName('signup-username-input')[0];
		if (username === '') {
			usernameErrorElement.className = 'signup-username-error-message show';
			usernameInputBorder.className = 'signup-username-input border-red';
			return;
		} else {
			usernameErrorElement.className = 'signup-username-error-message';
			usernameInputBorder.className = 'signup-username-input';
		}

		// Confirm the link is a sign-in with email link.
		if (auth.isSignInWithEmailLink(window.location.href)) {
			// console.log('no email link');
			// Additional state parameters can also be passed via URL.
			// This can be used to continue the user's intended action before triggering
			// the sign-in operation.
			// Get the email if available. This should be available if the user completes
			// the flow on the same device where they started it.
			// if (!email) {
			// 	// User opened the link on a different device. To prevent session fixation
			// 	// attacks, ask the user to provide the associated email again. For example:
			// 	email = window.prompt(
			// 		'You are using another device to sign in. Please provide your email for confirmation.'
			// 	);
			// }

			auth.createUserWithEmailAndPassword(email, password)
				.then(userInfo => {
					window.localStorage.removeItem('emailForSignIn');
					window.localStorage.removeItem('passwordForSignIn');

					if (userInfo) {
						let user = auth.currentUser;

						user.updateProfile({
							photoURL: 'http://via.placeholder.com/100x100',
						})
							.then(() => {
								console.log(user.photoURL);
							})
							.catch(err => {
								console.log('line 90' + err);
							});

						user.updateEmail(email)
							.then(() => {
								console.log(user.email);
							})
							.catch(err => {
								console.log('line 98' + err);
							});

						user.updatePassword(password)
							.then(() => {
								console.log('Successfully update password.');
							})
							.catch(err => {
								console.log('line 106', err);
							});
					}
				})
				.then(result => {
					let newPromiseId = generateUID();
					this.setState({
						promiseId: newPromiseId
					});
					db.ref('users/' + auth.currentUser.uid)
						.child('privateFields')
						.update({
							email: auth.currentUser.email,
							createdOn: firebase.database.ServerValue.TIMESTAMP,
						})
						.then(result => {
							db.ref('users/' + auth.currentUser.uid)
								.child('publicFields')
								.update({
									displayName: username,
									avatarURL: auth.currentUser.photoURL,
									promiseId: newPromiseId,
								})
								.then(() => {
									alert("Successfully signed up!!!!");
									
									let successEle = document.getElementById('signup-success');
									let signupBody = document.getElementsByClassName('signup-final-body')[0];
									successEle.className = 'signup-success-info show';
									signupBody.className += '-hidden';
								})
								.catch(err => {
									console.log("line 135: ", err);
								});
						})
						.catch(error => console.log("line 138: ", error));
				})
				.catch(error => {
					console.log("line 141: ", error);
				});
		}
	}

	updatePassword() {
		let password = this.state.newPassword;
		let confirmPassword = this.state.confirmNewPassword;
		let passwordSuccessElement = document.getElementById('reset-password-success');
		let passwordErrorElement = document.getElementById('reset-password-first-error');
		let passwordInputBorder = document.getElementsByClassName('reset-password-first-input')[0];
		let confirmPasswordErrorElement = document.getElementById('reset-password-repeat-error');
		let confirmPasswordInputBorder = document.getElementsByClassName('reset-password-repeat-input')[0];

		if (password.length < 6) {
			passwordErrorElement.className = 'reset-password-error-message show';
			passwordInputBorder.className = 'reset-password-first-input border-red';
			return;
		} else {
			passwordErrorElement.className = 'reset-password-error-message';
			passwordInputBorder.className = 'reset-password-first-input';
		}

		if (confirmPassword === '') {
			this.setState({
				confirmPasswordErrorCode: 'empty-confirm-password',
			});

			confirmPasswordErrorElement.className = 'reset-confirm-password-message show';
			confirmPasswordInputBorder.className = 'reset-password-repeat-input border-red';
			return;
		} else if (confirmPassword !== password) {
			this.setState({
				confirmPasswordErrorCode: 'not-match-password',
			});

			confirmPasswordErrorElement.className = 'reset-confirm-password-message show';
			confirmPasswordInputBorder.className = 'reset-password-repeat-input border-red';
			return;
		} else {
			confirmPasswordErrorElement.className = 'reset-confirm-password-message';
			confirmPasswordInputBorder.className = 'reset-password-repeat-input';
		}

		let oobCode = window.location.href.split('&')[1].split('=')[1];

		auth.confirmPasswordReset(oobCode, this.state.newPassword)
			.then(() => {
				passwordSuccessElement.className = 'reset-password-success-message show';
				this.props.history.push('/login');
			})
			.catch(err => {
				alert('Fail to confirm password reset: ' + error);
			});
	}

	render() {
		let url = window.location.href;
		let urlSplit = url.split('mode=')[1]
		let actionMode = urlSplit.split('&')[0];

		let confirmPasswordErrorMessage;
		switch (this.state.confirmPasswordErrorCode) {
			case 'empty-confirm-password': {
				confirmPasswordErrorMessage = 'Please input your password again.';
				break;
			}
			case 'not-match-password': {
				confirmPasswordErrorMessage = 'Confirm password does not match. Please input again.';
				break;
			}
			default:
				break;
		}

		switch (actionMode) {
			case 'signIn': {
				return (
					<div className="signup-final-root">
						<div className="all-background">
							<Background />
						</div>
						<div className="home-page-title">
							{
							<div className="home-link">
								Promise
							</div>
							}
						</div>
						<div className="signup-final-container">
							<div className="signup-final-body">
								<div className="signup-final-text"> Complete profile </div>
								<div className="signup-username">
									<div className="signup-username-text">Username</div>
									<input
										className="signup-username-input"
										name="username"
										type="text"
										value={this.state.username}
										required
										onChange={this.handleInputChange.bind(this)}
									/>
								</div>
								<div className="signup-username-error-message" id="username-error">
									Please enter your user name.
								</div>

								<div className="signupquestion-container">
									<button
										className="complete-signup-button"
										onClick={this.handleSignupComplete.bind(this)}
									>
										Complete
									</button>
								</div>
							</div>
							<div className="signup-success-info" id="signup-success">
								<div className="signup-success-title">Success</div>
								<div className="signup-success-text">
									Congradulations! Your account has been created. <br />
									You could find your
									<span className="signup-success-ID">&nbsp; Promise ID</span>
									&nbsp; in settings.
								</div>
								<button className="signup-final-redirect-button" onClick={this.redirectHome.bind(this)}>
									Enter Site
								</button>
							</div>
						</div>
					</div>
				);
			}
			case 'resetPassword': {
				return (
					<div className="reset-password-final-root">
						<div className="all-background">
							<Background />
						</div>
						<div className="reset-password-final-container">
							<div className="reset-new-password">
								<div className="reset-password-first">New password</div>
								<input
									type="password"
									name="newPassword"
									className="reset-password-first-input"
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="reset-password-error-message" id="reset-password-first-error">
								Password must be at least 6 characters.
							</div>

							<div className="reset-confirm-password">
								<div className="reset-password-repeat">Confirm new password</div>
								<input
									type="password"
									name="confirmNewPassword"
									className="reset-password-repeat-input"
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="reset-confirm-password-message" id="reset-password-repeat-error">
								{confirmPasswordErrorMessage}
							</div>
						</div>
						<button className="save-new-password-button" onClick={this.updatePassword.bind(this)}>
							Submit
						</button>
						<div className="reset-password-success-message" id="reset-password-success">
							You have successfully update your password!
							<br />
							System automatically redirect to login page...
						</div>
					</div>
				);
			}

			// TODO: remove this or make it a real default page when email link is not valid
			default: {
				return <div>Seems that you don't come from a valid email link.</div>;
			}
		}
	}
}
