import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth, db } from '../utils/firebase';
import HeaderDropdown from '../components/headerDropdown';
import { headerList } from '../utils/consts';

import Background from '../components/background';
import BillboardCard from '../components/billboardCard';
import '../styles/billboard.css';

import { PulseSpinner } from 'react-spinners-kit';

export default class Billboard extends Component {
	componentDidMount() {
		let userRef = db.ref('users/' + auth.currentUser.uid);
		let myFriends = [];
		let myFollowings = [];
		let initialViewable = [];

		// In friends billboard filter, it displays promises sent by user's friends and user himself
		userRef
			.child('myFriends')
			.once('value')
			.then(snapshot => {
				for (let id in snapshot.val()) {
					myFriends.push(snapshot.val()[id].friendId);
				}
				let friendsViewable = [];
				db.ref('billboards/' + auth.currentUser.uid)
					.once('value')
					.then(snapshot => {
						for (let id in snapshot.val()) {
							let billboard = snapshot.val()[id];
							if (
								!myFriends.includes(billboard.senderId) &&
								billboard.senderId !== auth.currentUser.uid
							) {
								continue;
							}
							let promiseView = {
								billboardId: billboard.billboardId,
								promiseId: billboard.promiseId,
								createdOn: billboard.createdOn,
								content: billboard.content,
								senderName: billboard.senderName,
								senderImage: billboard.senderImage,
								senderId: billboard.senderId,
								senderPromiseId: billboard.senderPromiseId,
								status: billboard.status,
								privacy: billboard.privacy,
								lastChange: billboard.lastChange,
								life: billboard.life,
								action: billboard.action,
							};
							friendsViewable.push(promiseView);
							initialViewable.push(promiseView);
						}
						this.setState({
							friendsViewableBillboards: friendsViewable,
							currentlyDisplayed: initialViewable,
							loading: false,
						});
					});

				db.ref('publicPromises/')
					.once('value')
					.then(snapshot => {
						for (let id in snapshot.val()) {
							let publicPromise = snapshot.val()[id];
							if (
								!myFriends.includes(publicPromise.senderId) &&
								publicPromise.senderId !== auth.currentUser.uid
							)
								continue;
							let publicPromiseView = {
								billboardId: id,
								promiseId: publicPromise.promiseId,
								createdOn: publicPromise.createdOn,
								content: publicPromise.content,
								senderName: publicPromise.senderName,
								senderImage: publicPromise.senderImage,
								senderId: publicPromise.senderId,
								senderPromiseId: publicPromise.senderPromiseId,
								status: publicPromise.status,
								privacy: publicPromise.privacy,
								lastChange: publicPromise.lastChange,
								life: publicPromise.life,
								action: publicPromise.action,
							};
							friendsViewable.push(publicPromiseView);
							initialViewable.push(publicPromiseView);
						}
						this.setState({
							friendsViewableBillboards: friendsViewable,
							currentlyDisplayed: initialViewable,
							loading: false,
						});
					});
			});

		userRef
			.child('myFollowing')
			.once('value')
			.then(snapshot => {
				for (let id in snapshot.val()) {
					myFollowings.push(snapshot.val()[id].followingId);
				}
				let followingsViewable = [];
				db.ref('billboards/' + auth.currentUser.uid)
					.once('value')
					.then(snapshot => {
						for (let id in snapshot.val()) {
							let billboard = snapshot.val()[id];
							if (!myFollowings.includes(billboard.senderId)) {
								continue;
							}
							let promiseView = {
								billboardId: billboard.billboardId,
								promiseId: billboard.promiseId,
								createdOn: billboard.createdOn,
								content: billboard.content,
								senderName: billboard.senderName,
								senderImage: billboard.senderImage,
								senderId: billboard.senderId,
								senderPromiseId: billboard.senderPromiseId,
								status: billboard.status,
								privacy: billboard.privacy,
								lastChange: billboard.lastChange,
								life: billboard.life,
								action: billboard.action,
							};
							followingsViewable.push(promiseView);
							initialViewable.push(promiseView);
						}

						this.setState({
							followingsViewableBillboards: followingsViewable,
							currentlyDisplayed: initialViewable,
							loading: false,
						});
					});

				db.ref('publicPromises/')
					.once('value')
					.then(snapshot => {
						for (let id in snapshot.val()) {
							let publicPromise = snapshot.val()[id];
							if (!myFollowings.includes(publicPromise.senderId)) continue;
							let publicPromiseView = {
								billboardId: id,
								promiseId: publicPromise.promiseId,
								createdOn: publicPromise.createdOn,
								content: publicPromise.content,
								senderName: publicPromise.senderName,
								senderImage: publicPromise.senderImage,
								senderId: publicPromise.senderId,
								senderPromiseId: publicPromise.senderPromiseId,
								status: publicPromise.status,
								privacy: publicPromise.privacy,
								lastChange: publicPromise.lastChange,
								life: publicPromise.life,
								action: publicPromise.action,
							};
							followingsViewable.push(publicPromiseView);
							initialViewable.push(publicPromiseView);
						}
						this.setState({
							followingsViewableBillboards: followingsViewable,
							currentlyDisplayed: initialViewable,
							loading: false,
						});
					});
			});

		db.ref('publicPromises/')
			.once('value')
			.then(snapshot => {
				let publicPromises = [];
				for (let id in snapshot.val()) {
					let publicPromise = snapshot.val()[id];
					let publicPromiseView = {
						billboardId: id,
						promiseId: publicPromise.promiseId,
						createdOn: publicPromise.createdOn,
						content: publicPromise.content,
						senderName: publicPromise.senderName,
						senderImage: publicPromise.senderImage,
						senderId: publicPromise.senderId,
						senderPromiseId: publicPromise.senderPromiseId,
						status: publicPromise.status,
						privacy: publicPromise.privacy,
						lastChange: publicPromise.lastChange,
						life: publicPromise.life,
						action: publicPromise.action,
					};
					publicPromises.push(publicPromiseView);
					this.setState({
						publicBillboards: publicPromises,
					});
				}
			});
	}

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			viewableBillboards: [],
			friendsViewableBillboards: [],
			followingsViewableBillboards: [],
			publicBillboards: [],
			currentlyDisplayed: [],
			filters: ['Friend', 'Following'],
		};
	}

	renderBillboard() {
		let display = this.state.currentlyDisplayed;
		display = this.removeDuplicates(display, 'billboardId');
		display.sort((a, b) => {
			return b.createdOn - a.createdOn;
		});
		return display.map(obj => {
			return <BillboardCard key={obj.billboardId} billboard={obj} />;
		});
	}

	onCheckboxChange(e) {
		let filters = this.state.filters;
		if (e.target.checked) {
			filters.push(e.target.value);
			this.setState({
				filters: filters,
			});
		} else {
			let index = filters.indexOf(e.target.value);
			if (index !== -1) filters.splice(index, 1);
			this.setState({
				filters: filters,
			});
		}
	}

	onCheckboxSubmit() {
		let display = [];

		if (this.state.filters.includes('Public')) {
			for (let id in this.state.publicBillboards) {
				display.push(this.state.publicBillboards[id]);
			}
		}
		if (this.state.filters.includes('Friend')) {
			for (let id in this.state.friendsViewableBillboards) {
				display.push(this.state.friendsViewableBillboards[id]);
			}
		}
		if (this.state.filters.includes('Following')) {
			for (let id in this.state.followingsViewableBillboards) {
				display.push(this.state.followingsViewableBillboards[id]);
			}
		}

		let currentlyDisplayed = this.removeDuplicates(display, 'createdOn');

		this.setState({
			currentlyDisplayed: currentlyDisplayed,
		});
	}

	removeDuplicates(myArr, prop) {
		return myArr.filter((obj, pos, arr) => {
			return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
		});
	}

	render() {
		let billboard = {};
		if (!this.state.loading && this.state.currentlyDisplayed.length == 0) {
			billboard = <div className="billboard-content">You have no billboard promise.</div>;
		} else {
			billboard = <div className="billboard-content">{this.renderBillboard()}</div>;
		}
		return (
			<div className="billboard-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown login={auth.currentUser} dropdownList={headerList} />
					</div>
				</div>

				<div className="billboard-container">
					<div className="billboard-body">
						<div className="billboard-filter-container">
							<div className="billboard-filter">
								<input
									className="billboard-checkbox"
									id="general-checkbox"
									type="checkbox"
									value="Friend"
									defaultChecked={true}
									onChange={this.onCheckboxChange.bind(this)}
								/>{' '}
								<span className="checkbox-title">Friend</span>
								<input
									className="billboard-checkbox"
									id="general-checkbox"
									type="checkbox"
									value="Following"
									defaultChecked={true}
									onChange={this.onCheckboxChange.bind(this)}
								/>{' '}
								<span className="checkbox-title">Following</span>
								<input
									className="billboard-checkbox"
									id="all-checkbox"
									type="checkbox"
									value="Public"
									defaultChecked={false}
									onChange={this.onCheckboxChange.bind(this)}
								/>{' '}
								<span className="checkbox-title">Public</span>
							</div>
							<button className="checkbox-submit" onClick={this.onCheckboxSubmit.bind(this)}>
								Submit
							</button>
						</div>
						<div className="loading-spinner-wrapper">
							<PulseSpinner size={60} color="#fff" loading={this.state.loading} />
						</div>

						<div className="billboard-list-container>">{billboard}</div>
					</div>
				</div>
			</div>
		);
	}
}
