import { db, auth } from './firebase';
export const firebaseFriendRequestRef = db.ref('requests/');
export const firebaseUserRef = db.ref('users/');

export const sendRequest = request => {
	let requestPromise = new Promise((resolve, reject) => {
		firebaseFriendRequestRef
			.child(request.receiverId)
			.push({
				receiverId: request.receiverId,
				receiverName: request.receiverName,
				senderId: request.senderId,
				senderName: request.senderName,
				timeStamp: request.timeStamp,
			})
			.then(() => {
				firebaseUserRef
					.child(auth.currentUser.uid + '/myFollowing')
					.orderByChild('followingId')
					.equalTo(request.receiverId)
					.once('value')
					.then(snapshot => {
						if (snapshot.exists()) return;
						else {
							firebaseUserRef.child(auth.currentUser.uid + '/myFollowing').push({
								followingId: request.receiverId,
								followingName: request.receiverName,
							});
							firebaseUserRef.child(request.receiverId + '/myFollowers').push({
								followerId: request.senderId,
								followerName: request.senderName,
							});
						}
					});
				resolve({ success: true });
			})
			.catch(error => {
				resolve(error);
			});
	});

	return requestPromise;
};

export const acceptFriendRequest = friend => {
	let acceptRequestPromise = new Promise((resolve, reject) => {
		firebaseUserRef
			.child(auth.currentUser.uid + '/myFriends')
			.push({
				friendId: friend.friendId,
			})
			.then(() => {
				firebaseUserRef
					.child(friend.friendId + '/myFriends')
					.push({
						friendId: auth.currentUser.uid,
					})
					.then(() => {
						deleteFriendRequest(friend).then(() => {
							resolve({ success: true });
						});
						firebaseUserRef
							.child(auth.currentUser.uid + '/myFollowers')
							.orderByChild('followerId')
							.equalTo(friend.friendId)
							.once('value')
							.then(snapshot => {
								for (let id in snapshot.val()) {
									firebaseUserRef
										.child(auth.currentUser.uid + '/myFollowers')
										.child(id)
										.remove();
								}
							});
						firebaseUserRef
							.child(auth.currentUser.uid + '/myFollowing')
							.orderByChild('followingId')
							.equalTo(friend.friendId)
							.once('value')
							.then(snapshot => {
								if (!snapshot.val()) return;
								else {
									for (let id in snapshot.val()) {
										firebaseUserRef
											.child(auth.currentUser.uid + '/myFollowing')
											.child(id)
											.remove();
									}
								}
							});

						firebaseUserRef
							.child(friend.friendId + '/myFollowing')
							.orderByChild('followingId')
							.equalTo(auth.currentUser.uid)
							.once('value')
							.then(snapshot => {
								for (let id in snapshot.val()) {
									firebaseUserRef
										.child(friend.friendId + '/myFollowing')
										.child(id)
										.remove();
								}
							});

						firebaseUserRef
							.child(friend.friendId + '/myFollowers')
							.orderByChild('followerId')
							.equalTo(auth.currentUser.uid)
							.once('value')
							.then(snapshot => {
								if (!snapshot.val()) return;
								else {
									for (let id in snapshot.val()) {
										firebaseUserRef
											.child(friend.friendId + '/myFollowers')
											.child(id)
											.remove();
									}
								}
							});
					})
					.catch(error => {
						reject(error);
					});
			})
			.catch(error => {
				reject(error);
			});
	});

	return acceptRequestPromise;
};

export const deleteFriendRequest = friend => {
	let deleteRequestPromise = new Promise((resolve, reject) => {
		firebaseFriendRequestRef
			.child(auth.currentUser.uid)
			.orderByChild('senderId')
			.equalTo(friend.friendId)
			.once('value', snapshot => {
				for (let request in snapshot.val()) {
					firebaseFriendRequestRef
						.child(auth.currentUser.uid)
						.child(request)
						.remove()
						.then(() => {
							resolve({ success: true });
						});
				}
			})
			.catch(error => {
				reject(error);
			});
	});

	return deleteRequestPromise;
};
