import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth, db } from '../utils/firebase';
import HeaderDropdown from '../components/headerDropdown';
import { headerList } from '../utils/consts';

import '../styles/home.css';
import Background from '../components/background';

export default class Home extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="home-root">
                <div className="all-background">
                    <Background />
                </div>
                <div className="home-page-title">
                    {
                        <Link to="/" className="home-link">
                            Promise
                        </Link>
                    }
                    <div className="drop-down-menu">
                        <HeaderDropdown
                            login={auth.currentUser}
                            dropdownList={headerList}
                        />
                    </div>
                </div>

                <div className="home-body">
                    <div className="home-title"> Now and Forever </div>
                    <button className="home-make-promise-button">
                        {
                            <Link to="/create" className="make-promise-link">
                                Make a Promise
                            </Link>
                        }
                    </button>
                </div>
            </div>
        );
    }
}
