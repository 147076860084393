import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Sent from '../pages/sentPromise';
import MyPromises from '../pages/myPromises';
import ChangePromise from '../pages/changePromise';
import BreakPromise from '../pages/breakPromise';
import BrokenPromise from '../pages/brokenPromise';
import LoggedInPageNotFound from '../pages/loggedInPageNotFound';
import ExtendPromise from '../pages/extendPromise';
import ResolvePromise from '../pages/resolvePromise';
import ResolvedPromise from '../pages/resolvedPromise';

export default class PromiseRouter extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Switch>
					<Route exact path="/myPromises" component={MyPromises} />
					<Route
						path="/myPromises/sent/:id"
						render={props => <Sent {...props} promiseInfo={this.props.location.state} />}
					/>
					<Route
						path="/myPromises/made"
						render={props => <Sent {...props} promiseInfo={this.props.location.state} />}
					/>
					<Route path="/myPromises/change/:id" component={ChangePromise} />
					<Route path="/myPromises/break/:id" component={BreakPromise} />
					<Route path="/myPromises/extend/:id" component={ExtendPromise} />
					<Route path="/myPromises/finish/:id" component={ResolvePromise} />
					<Route path="/myPromises/finished/:id" component={ResolvedPromise} />

					<Route exact path="/myPromises/broken/:id" component={BrokenPromise} />
				</Switch>
			</div>
		);
	}
}
