import React, { Component } from 'react';

import '../styles/historyCard.css';

import { standardTime, convertUTCToLocal } from '../utils/time';

export default class HistoryCard extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		console.log(this.props);
		const LARGE_DATE = new Date('01/01/2500').getTime();
		const neverExpire = this.props.data.life == LARGE_DATE;
		return (
			<div className="promise-history-card">
				<div className="promise-history-card-top">
					<div className="promise-history-action">Promise {this.props.data.action}</div>
					<div className="promise-history-editTime">
						{standardTime(convertUTCToLocal(this.props.data.createdOn))}
					</div>
				</div>
				<div className="promise-history-card-bottom">
					<div className="promise-history-content">
						<div className="history-card-titles">Promise</div>
						<div className="history-card-contents">{this.props.data.content}</div>
					</div>

					<div className="promise-history-content">
						<div className="history-card-titles">Expire on</div>
						<div className="history-card-contents">
							{neverExpire ? 'Never' : standardTime(convertUTCToLocal(this.props.data.life))}
						</div>
					</div>
					<div className="promise-history-content">
						<div className="history-card-titles">Privacy</div>
						<div className="history-card-contents">{this.props.data.privacy}</div>
					</div>
					<div className="promise-history-content">
						<div className="history-card-titles">Change Reason</div>
						<div className="history-card-contents">{this.props.data.reason}</div>
					</div>
				</div>
			</div>
		);
	}
}
