import React, { Component } from 'react';
import Background from '../components/background';
import { Link } from 'react-router-dom';
import '../styles/signupCheckEmail.css';

export default class SignupCheckEmail extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="signup-check-email-root">
                <Background />
                <div className="home-page-title">
                    {
                        <Link to="/" className="home-link">
                            Promise
                        </Link>
                    }
                </div>
                <div className="signup-check-email-container">
                    <div className="signup-check-email-title">
                        Verify it's you
                    </div>
                    <div className="signup-check-email-intro">
                        For your security, we need to verify your identity.
                    </div>
                    <div className="signup-check-email-intro">
                        We've sent a link to the email address: &ensp;
                        <div className="signup-check-email-email">
                            {localStorage.getItem('emailForSignIn')}.
                        </div>
                    </div>
                    <div className="signup-check-email-intro">
                        Please follow the link in the email to sign in to Promise.
                    </div>
                </div>
            </div>
        );
    }
}
