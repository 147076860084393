import React, { Component } from 'react';
import '../styles/background.css';

const backgroundStyle = {
	position: 'absolute',
	top: 0,
	left: 0,
	zIndex: -1,
};

export default class Background extends Component {
	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();
		this.state = {
			width: window.innerWidth,
			height: window.innerHeight,
		};

		this.resizeCanvas = this.resizeCanvas.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.resizeCanvas);
		this.initializeCanvas();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeCanvas);
	}

	componentDidUpdate() {
		this.initializeCanvas();
	}

	resizeCanvas() {
		this.setState({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	}

	initializeCanvas() {
		const ctx = this.canvasRef.current.getContext('2d');

		let increment;
		if (this.state.width > 800) {
			increment = 50;
		} else {
			increment = this.state.width / 10;
		}

		let firstMountainX = 0 - increment;
		let firstMountainY = this.state.height;
		let firstCloudX = 0 - increment;
		let firstCloudY = increment * 3.4;

		let secondMountainX = firstMountainX + 1000;
		let secondMountainY = firstMountainY;
		let secondCloudX = firstMountainX + 1000;
		let secondCloudY = firstCloudY;

		ctx.fillStyle = '#ffffff';
		ctx.fillRect(firstCloudX, firstCloudY, increment * 5, increment);
		ctx.fillRect(firstCloudX + increment, firstCloudY - increment, increment * 4, increment);
		ctx.fillRect(firstCloudX + increment * 2, firstCloudY - increment * 2, increment * 2, increment);

		ctx.fillRect(secondCloudX, secondCloudY, increment * 5, increment);
		ctx.fillRect(secondCloudX + increment, secondCloudY - increment, increment * 4, increment);
		ctx.fillRect(secondCloudX + increment * 2, secondCloudY - increment * 2, increment * 2, increment);

		ctx.fillStyle = '#5bad41';

		ctx.fillRect(firstMountainX, firstMountainY, increment * 12, increment);
		ctx.fillRect(firstMountainX + increment, firstMountainY - increment, increment * 10, increment);
		ctx.fillRect(firstMountainX + increment * 2, firstMountainY - increment * 2, increment * 8, increment);
		ctx.fillRect(firstMountainX + increment * 3, firstMountainY - increment * 3, increment * 6, increment);
		ctx.fillRect(firstMountainX + increment * 4, firstMountainY - increment * 4, increment * 4, increment);

		ctx.fillRect(secondMountainX, secondMountainY, increment * 12, increment);
		ctx.fillRect(secondMountainX + increment, secondMountainY - increment, increment * 10, increment);
		ctx.fillRect(secondMountainX + increment * 2, secondMountainY - increment * 2, increment * 8, increment);
		ctx.fillRect(secondMountainX + increment * 3, secondMountainY - increment * 3, increment * 6, increment);
		ctx.fillRect(secondMountainX + increment * 4, secondMountainY - increment * 4, increment * 4, increment);
	}

	render() {
		return (
			<canvas style={backgroundStyle} ref={this.canvasRef} width={this.state.width} height={this.state.height} />
		);
	}
}
