import React, { Component } from 'react';
import { auth, db } from '../utils/firebase';
import { Link } from 'react-router-dom';
import HeaderDropdown from '../components/headerDropdown';
import { headerList } from '../utils/consts';

import FriendSearchBar from '../components/friendSearchBar';
import UserCard from '../components/userCard';
import Background from '../components/background';

import '../styles/searchFriend.css';

export default class SearchFriend extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentlyDisplayed: [],
		};

		this.setCurrentlyDisplayed = this.setCurrentlyDisplayed.bind(this);
	}

	setCurrentlyDisplayed(userCards) {
		this.setState({
			currentlyDisplayed: userCards,
		});
	}

	renderUserCards() {
		console.log(this.state.currentlyDisplayed);
		return this.state.currentlyDisplayed.map((obj, index) => {
			let user = {
				systemId: obj.id,
				promiseId: obj.promiseId,
				userName: obj.name,
				imageURL: obj.avatarURL,
			};
			return (
				<UserCard
					cardId={index + 1}
					type={obj.relationWithUser}
					key={obj.id}
					user={user}
					addFriendsHandler={this.addFriends}
					deleteFriendHandler={this.deleteFriend}
					followHandler={this.follow}
					unfollowHandler={this.unfollow}
				/>
			);
		});
	}

	render() {
		return (
			<div className="friend-search-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown
							login={auth.currentUser}
							dropdownList={headerList}
						/>
					</div>
				</div>
				<div className="friend-search-bar">
					<FriendSearchBar searchSubmitHandler={this.setCurrentlyDisplayed} />
				</div>
				<div className="friends-search-list">{this.renderUserCards()}</div>
			</div>
		);
	}
}
