import React, { Component } from 'react';
const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'];
export default class DateSelection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedYear: new Date().getFullYear(),
			selectedMonth: 1,
			selectedDay: 1,
		};

		this.handleMonthSelected = this.handleMonthSelected.bind(this);
		this.handleDaySelected = this.handleDaySelected.bind(this);
		this.handleYearSelected = this.handleYearSelected.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			selectedYear: nextProps.selectedYear,
			selectedMonth: nextProps.selectedMonth, // [0, 11]
			selectedDay: nextProps.selectedDay - 1, // [0, 30]
		});
	}

	handleMonthSelected(e) {
		let map = new Map();
		for (let month in months) {
			map.set(months[month], parseInt(month));
		}

		this.setState({
			selectedMonth: map.get(e.target.value),
		});

		this.props.onSelectedMonth(map.get(e.target.value));
	}

	handleDaySelected(e) {
		this.setState({
			selectedDay: parseInt(e.target.value),
		});

		this.props.onSelectedDay(parseInt(e.target.value));
	}

	handleYearSelected(e) {
		this.setState({
			selectedYear: parseInt(e.target.value),
		});

		this.props.onSelectedYear(parseInt(e.target.value));
	}

	render() {
		console.log(this.props);
		let currentYear = new Date().getFullYear();
		let years = [];
		let monthWithThirtyDays = ['Apr.', 'Jul.', 'Jun.', 'Sept.', 'Nov.'];
		let monthWithThirtyOneDays = ['Jan.', 'Mar.', 'May', 'Aug.', 'Oct.', 'Dec.'];
		let monthWithTwentyEightDays = ['Feb.'];
		let daysThirty = [];
		let daysThirtyOne = [];
		let daysTwentyEight = [];

		for (let i = 0; i < 100; i++) {
			let year;
			if (this.props.type === 'before') {
				year = currentYear - i;
				years.push(year + ' ');
			} else if (this.props.type === 'after') {
				year = currentYear + i;
				years.push(year + ' ');
			}
		}

		for (let i = 1; i <= 31; i++) {
			if (i < 10) {
				daysThirtyOne.push('0' + i);
			} else {
				daysThirtyOne.push(i + ' ');
			}
		}

		for (let i = 1; i <= 30; i++) {
			if (i < 10) {
				daysThirty.push('0' + i);
			} else {
				daysThirty.push(i + ' ');
			}
		}

		for (let i = 1; i <= 28; i++) {
			if (i < 10) {
				daysTwentyEight.push('0' + i);
			} else {
				daysTwentyEight.push(i + ' ');
			}
		}

		let yearOptions = years.map(year => (
			<option key={year - currentYear} value={year}>
				{year}
			</option>
		));

		let monthOptions = months.map((month, index) =>
			index == this.state.selectedMonth ? (
				<option selected key={month} value={month}>
					{month}
				</option>
			) : (
				<option key={month} value={month}>
					{month}
				</option>
			)
		);

		let dayOptions;
		let selectedMonthName = months[this.state.selectedMonth];

		if (monthWithThirtyDays.includes(selectedMonthName)) {
			dayOptions = daysThirty.map((day, index) =>
				index == this.state.selectedDay ? (
					<option selected key={day} value={day}>
						{day}
					</option>
				) : (
					<option key={day} value={day}>
						{day}
					</option>
				)
			);
		} else if (monthWithThirtyOneDays.includes(selectedMonthName)) {
			dayOptions = daysThirtyOne.map((day, index) =>
				index == this.state.selectedDay ? (
					<option selected key={day} value={day}>
						{day}
					</option>
				) : (
					<option key={day} value={day}>
						{day}
					</option>
				)
			);
		} else {
			dayOptions = daysTwentyEight.map((day, index) =>
				index == this.state.selectedDay ? (
					<option selected key={day} value={day}>
						{day}
					</option>
				) : (
					<option key={day} value={day}>
						{day}
					</option>
				)
			);
		}

		return (
			<div>
				<select
					disabled={this.props.isDisabled}
					id="expiration-month-select"
					onChange={this.handleMonthSelected}
				>
					<option value="Month" disabled>
						Month
					</option>
					{monthOptions}
				</select>
				<select disabled={this.props.isDisabled} id="expiration-day-select" onChange={this.handleDaySelected}>
					<option value="Day" disabled>
						Day
					</option>
					{dayOptions}
				</select>
				<select disabled={this.props.isDisabled} id="expiration-year-select" onChange={this.handleYearSelected}>
					<option value="Year" disabled>
						Year
					</option>
					{yearOptions}
				</select>
			</div>
		);
	}
}
