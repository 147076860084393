import firebase from 'firebase';

var config = {
	apiKey: 'AIzaSyAiJncMWcDKhGGV0pRQ4Gh3Mgp2NHd3804',
	authDomain: 'promise-64bb5.firebaseapp.com',
	databaseURL: 'https://promise-64bb5.firebaseio.com',
	projectId: 'promise-64bb5',
	storageBucket: 'promise-64bb5.appspot.com',
	messagingSenderId: '1045516416552',
};

export const firebaseApp = firebase.initializeApp(config);
export const auth = firebaseApp.auth();
export const db = firebaseApp.database();
export const storage = firebaseApp.storage();
export const storageKey = 'PROMISE_LOCAL_$UID';

export const firebaseRef = {
	user: db.ref('users/'),
};

export const isAuthenticated = () => {
	return !!auth.currentUser;
};

export const signOut = () => {
	auth.signOut();
};

export const createUserIfNotExists = () => {
	if (isAuthenticated()) {
		let user = auth.currentUser;
		let usersRef = firebaseRef.user;
		let userData = null;

		userData = {
			privateFields: {
				email: user.email || 'anonymous.user@gmail.com',
				createdOn: firebase.database.ServerValue.TIMESTAMP,
				// facebookId: "",
				// githubId: "",
				// googleId: "",
				// phoneNumber: "",
				// pushNotificationsToken: "",
				// twitterId: "",
			},
			publicFields: {
				avatarURL: user.photoURL,
				displayName: user.displayName,
				isConnected: true,
				lastSeen: firebase.database.ServerValue.TIMESTAMP,
			},
		};

		user.providerData.forEach(provider => {
			let providerId = provider.providerId;
			let providerUid = provider.uid;
			let key = providerId.split('.')[0] + 'Id';
			if (userData.privateFields[key] === '') {
				userData.privateFields[key] = providerUid;
			}
		});

		usersRef
			.child(user.uid)
			.once('value')
			.then(snapshot => {
				if (!snapshot.exists()) {
					usersRef.child(user.uid).set(userData);
				}
			});
	}
};
