import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { auth, db } from '../utils/firebase';

import '../styles/imageUploader.css';

export default class ImageUploader extends Component {
	componentDidMount() {
		let userRef = db.ref('users/' + auth.currentUser.uid);
		userRef.child('publicFields').on('value', snapshot => {
			let publicInfo = snapshot.val();
			this.setState({
                file: publicInfo.avatarURL,
                userPromiseId: publicInfo.promiseId
			});
		});
	}

	constructor(props) {
		super(props);
		this.state = {
            file: {},
            userPromiseId: {}
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		this.setState({
			file: URL.createObjectURL(event.target.files[0]),
		});
	}

	render() {
		return (
			<div className="image-uploader-container">
				<Link to='/myPromises/'>
					<img
						className="settings-profile-image"
						id="avatar"
						src={this.state.file}
						width="200"
						heigth="200"
					/>
				</Link>
				<input id="file" type="file" onChange={this.handleChange} />
			</div>
		);
	}
}
