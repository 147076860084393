import React, { Component } from 'react';
import Tab from './tab';

export default class Tabs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: this.props.children[0].props.label,
		};
	}

	onClickTabItem = tab => {
		this.setState({ activeTab: tab });
	};

	render() {
		const {
			onClickTabItem,
			props: { children },
			state: { activeTab },
		} = this;

		return (
			<div className={this.props.tabs}>
				<ul className={this.props.tabList}>
					{children.map(child => {
						const { label } = child.props;

						return (
							<Tab
								svg={child.props.useSVG}
								svgType={child.props.svgType}
								activeTab={activeTab}
								key={label}
								label={label}
								className={child.props.className}
								onClick={onClickTabItem}
							/>
						);
					})}
				</ul>
				<div className={this.props.tabContent}>
					{children.map(child => {
						if (child.props.label !== activeTab) return '';
						return child.props.children;
					})}
				</div>
			</div>
		);
	}
}
