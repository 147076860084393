import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeaderDropdown from '../components/headerDropdown';
import ButtonAction from '../components/buttonAction';
import { auth, db } from '../utils/firebase';
import { headerList } from '../utils/consts';

import PromiseCard from '../components/promiseCard';
import { viewable } from '../utils/userPermissions';
import '../styles/userPage.css';
import Background from '../components/background';

export default class UserPage extends Component {
	componentDidUpdate(prevProps) {
		let userRef = {};
		if (prevProps.match.params.id != this.props.match.params.id) {
			let allMySentPromises = [];
			let myFriends = [];
			db.ref('users/' + auth.currentUser.uid)
				.child('myFriends/')
				.once('value')
				.then(snapshot => {
					for (let i in snapshot.val()) {
						myFriends.push(snapshot.val()[i].friendId);
					}
				});

			db.ref('users/')
				.orderByChild('publicFields/promiseId')
				.equalTo(this.props.match.params.id)
				.once('value')
				.then(snapshot => {
					for (let key in snapshot.val()) {
						userRef = db.ref('users/' + key);
						userRef
							.child('publicFields/')
							.once('value')
							.then(snapshot => {
								let publicInfo = snapshot.val();
								db.ref('users/' + auth.currentUser.uid)
									.child('myFriends')
									.orderByChild('friendId')
									.equalTo(key)
									.once('value', snapshot => {
										if (snapshot.exists()) {
											this.setState({
												buttonType: 'friended',
												userId: key,
												profileImage: publicInfo.avatarURL,
												username: publicInfo.displayName,
												userPromiseId: publicInfo.promiseId,
											});
										} else {
											db.ref('users/' + auth.currentUser.uid)
												.child('myFollowing')
												.orderByChild('followingId')
												.equalTo(key)
												.once('value', snapshot => {
													if (snapshot.exists()) {
														this.setState({
															buttonType: 'add-followed',
															userId: key,
															profileImage: publicInfo.avatarURL,
															username: publicInfo.displayName,
															userPromiseId: publicInfo.promiseId,
														});
													} else {
														this.setState({
															buttonType: 'add-follow',
															userId: key,
															profileImage: publicInfo.avatarURL,
															username: publicInfo.displayName,
															userPromiseId: publicInfo.promiseId,
														});
													}
												});
										}
									});
							});

						userRef.child('myPromises/sent').on('value', snapshot => {
							let viewablePromises = [];
							if (snapshot.exists()) {
								allMySentPromises = snapshot.val();
								for (let i in allMySentPromises) {
									db.ref('promises/' + allMySentPromises[i])
										.once('value')
										.then(snapshot => {
											let instance = snapshot.val();
											let permission = viewable(
												instance,
												auth.currentUser.uid,
												instance.sender,
												myFriends
											);
											if (permission) {
												let historySize = instance.history.length;
												let latestHistory = instance.history[historySize - 1];
												db.ref('users/' + instance.receiver)
													.child('publicFields')
													.on('value', snapshot => {
														let receiverName = snapshot.val().displayName;
														let receiverPromiseId = snapshot.val().promiseId;

														let promiseView = {
															id: allMySentPromises[i],
															createdOn: instance.createdOn,
															content: instance.content,
															userName: receiverName,
															userId: instance.receiver,
															userPromiseId: receiverPromiseId,
															status: instance.status,
															privacy: instance.privacy,
															lastChange: latestHistory.createdOn,
															life: instance.life,
														};
														viewablePromises.push(promiseView);
														this.setState({
															currentlyDisplayed: {
																type: 'others',
																members: viewablePromises,
															},
														});
													});
											}
										});
								}
							} else {
								this.setState({
									currentlyDisplayed: {
										type: 'others',
										members: [],
									},
								});
							}
						});
					}
				});
		}
	}
	componentDidMount() {
		let allMySentPromises = [];
		let userRef = {};
		let myFriends = [];

		db.ref('users/' + auth.currentUser.uid)
			.child('myFriends/')
			.once('value')
			.then(snapshot => {
				for (let i in snapshot.val()) {
					myFriends.push(snapshot.val()[i].friendId);
				}
			});

		db.ref('users/')
			.orderByChild('publicFields/promiseId')
			.equalTo(this.props.match.params.id)
			.once('value')
			.then(snapshot => {
				for (let key in snapshot.val()) {
					console.log(key);
					userRef = db.ref('users/' + key);
					userRef
						.child('publicFields/')
						.once('value')
						.then(snapshot => {
							let publicInfo = snapshot.val();
							db.ref('users/' + auth.currentUser.uid)
								.child('myFriends')
								.orderByChild('friendId')
								.equalTo(key)
								.once('value', snapshot => {
									if (snapshot.exists()) {
										console.log('friended');
										this.setState({
											userId: key,
											profileImage: publicInfo.avatarURL,
											username: publicInfo.displayName,
											userPromiseId: publicInfo.promiseId,
											buttonType: 'friended',
										});
									} else {
										db.ref('users/' + auth.currentUser.uid)
											.child('myFollowing')
											.orderByChild('followingId')
											.equalTo(key)
											.once('value', snapshot => {
												if (snapshot.exists()) {
													console.log('followed');
													this.setState({
														userId: key,
														profileImage: publicInfo.avatarURL,
														username: publicInfo.displayName,
														userPromiseId: publicInfo.promiseId,
														buttonType: 'add-followed',
													});
												} else {
													console.log('not followed');
													this.setState({
														userId: key,
														profileImage: publicInfo.avatarURL,
														username: publicInfo.displayName,
														userPromiseId: publicInfo.promiseId,
														buttonType: 'add-follow',
													});
												}
											});
									}
								});
						});

					userRef.child('myPromises/sent').on('value', snapshot => {
						let viewablePromises = [];
						if (snapshot.exists()) {
							allMySentPromises = snapshot.val();
							for (let i in allMySentPromises) {
								db.ref('promises/' + allMySentPromises[i])
									.once('value')
									.then(snapshot => {
										let instance = snapshot.val();
										let permission = viewable(
											instance,
											auth.currentUser.uid,
											instance.sender,
											myFriends
										);
										if (permission) {
											let historySize = instance.history.length;
											let latestHistory = instance.history[historySize - 1];
											if (instance.receiver === 'public') {
												let promiseView = {
													id: allMySentPromises[i],
													createdOn: instance.createdOn,
													content: instance.content,
													userName: instance.receiver,
													userPromiseId: instance.receiver,
													userId: instance.receiver,
													status: instance.status,
													privacy: instance.privacy,
													lastChange: latestHistory.createdOn,
													life: instance.life,
												};
												viewablePromises.push(promiseView);
												this.setState({
													currentlyDisplayed: {
														type: 'others',
														members: viewablePromises,
													},
												});
											} else {
												db.ref('users/' + instance.receiver)
													.child('publicFields')
													.on('value', snapshot => {
														console.log(snapshot.val());
														let receiverName = snapshot.val().displayName;
														let receiverPromiseId = snapshot.val().promiseId;

														let promiseView = {
															id: allMySentPromises[i],
															createdOn: instance.createdOn,
															content: instance.content,
															userName: receiverName,
															userId: instance.receiver,
															userPromiseId: receiverPromiseId,
															status: instance.status,
															privacy: instance.privacy,
															lastChange: latestHistory.createdOn,
															life: instance.life,
														};
														viewablePromises.push(promiseView);
														this.setState({
															currentlyDisplayed: {
																type: 'others',
																members: viewablePromises,
															},
														});
													});
											}
										}
									});
							}
						}
					});
				}
			});
	}

	constructor(props) {
		super(props);
		this.state = {
			userId: '',
			profileImage: '',
			username: '',
			userPromiseId: '',
			promises: [],
			currentlyDisplayed: {
				type: 'others',
				members: [],
			},
			buttonType: undefined,
		};

		this.setCurrentlyDisplayed = this.setCurrentlyDisplayed.bind(this);
	}

	setCurrentlyDisplayed(promiseCards, type) {
		this.setState({
			currentlyDisplayed: {
				members: promiseCards,
				type: type,
			},
		});
	}

	renderPromiseCards() {
		return this.state.currentlyDisplayed.members.map(obj => {
			return (
				<PromiseCard type={this.state.currentlyDisplayed.type} status={obj.status} key={obj.id} promise={obj} />
			);
		});
	}

	render() {
		console.log(this.state);
		let friendButton;
		if (!this.state.buttonType) {
			friendButton = (
				<ButtonAction id={1} uid={this.state.userId} userName={this.state.username} type="default" />
			);
		} else {
			friendButton = (
				<ButtonAction
					id={1}
					uid={this.state.userId}
					userName={this.state.username}
					type={this.state.buttonType}
				/>
			);
		}
		return (
			<div className="promises-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown
							login={auth.currentUser}
							userInfo={this.state.profile}
							dropdownList={headerList}
						/>
					</div>
				</div>
				<div className="user-profile">
					<div className="user-profile-left">
						<Link to={`/user/${this.state.userPromiseId}`}>
							<img
								className="user-profile-image"
								src={this.state.profileImage}
								width="200"
								heigth="200"
							/>
						</Link>
					</div>
					<div className="user-profile-middle">
						<div className="user-profile-name">{this.state.username}</div>
						<div className="user-profile-promiseId">
							<p className="user-profile-promiseId-title">Promise ID:</p>
							{this.state.userPromiseId}
						</div>
						<div className="user-profile-button-mobile">{friendButton}</div>
					</div>
					<div className="user-profile-button">{friendButton}</div>
				</div>
				<div className="user-promises-tab">{this.renderPromiseCards()}</div>
			</div>
		);
	}
}
