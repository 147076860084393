import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../pages/login';
import Signup from '../pages/signup';
import Start from '../pages/start';
import HandleEmailLink from '../pages/emailLinkHandler';
import ResetPassword from '../pages/resetPassword';
import SignupCheckEmail from '../pages/signupCheckEmail';
import SignupAlready from '../pages/signupAlready';
import ResetPasswordCheckEmail from '../pages/resetPasswordCheckEmail';

export default class LoggedOutRouter extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Switch>
					<Route exact path="/" component={Start} />
					<Route exact path="/login" component={Login} />
					<Route exact path="/signup" component={Signup} />
					<Route path="/__/auth/handleEmailLink" component={HandleEmailLink} />
					<Route path="/signupCheckEmail" component={SignupCheckEmail} />
					<Route path="/signupEmailExisted" component={SignupAlready} />
					<Route exact path="/resetPassword" component={ResetPassword} />
					<Route path="/resetPasswordCheckEmail" component={ResetPasswordCheckEmail} />

					<Route path="/promises/:id" component={Login} />
					<Route path="/user/:id" component={Login} />
				</Switch>
			</div>
		);
	}
}
