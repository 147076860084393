import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../utils/firebase';

import Background from '../components/background';
import HeaderDropdown from '../components/headerDropdown';
import { headerList } from '../utils/consts';

import '../styles/sentPromise.css';

export default class BrokenPromise extends Component {
	constructor(props) {
		super(props);
		let storedUserName = {};
		let storedUserPromiseId = {};

		if (localStorage.getItem('username') && props.location.state === undefined) {
			storedUserName = localStorage.getItem('username');
		} else {
			storedUserName = props.location.state.receiverName;
			localStorage.setItem('username', storedUserName);
		}

		if (localStorage.getItem('userPromiseId') && props.location.state === undefined) {
			storedUserPromiseId = localStorage.getItem('userPromiseId');
		} else {
			storedUserPromiseId = props.location.state.receiverPromiseId;
			localStorage.setItem('userPromiseId', storedUserPromiseId);
		}

		this.state = {
			receiverName: storedUserName,
			receiverPromiseId: storedUserPromiseId,
			promiseId: this.props.match.params.id,
		};
	}

	render() {
		console.log(this.state);
		let userLink = {};
		if (this.state.receiverName === 'public') {
			userLink = this.state.receiverName;
		} else {
			userLink = (
				<Link
					to={{
						pathname: `/user/${this.state.receiverPromiseId}`,
						state: {
							userPromiseId: this.state.receiverPromiseId,
							userName: this.state.receiverName,
						},
					}}
					className="user-page-link"
				>
					{this.state.receiverName}
				</Link>
			);
		}
		return (
			<div className="broken-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown login={auth.currentUser} dropdownList={headerList} />
					</div>
				</div>

				<div className="sent-promise-container">
					<div className="sent-promise-container-left">
						<div className="sent-promise-content">Your promise was broken.</div>

						<div className="sent-promise-notification">
							A notification was sent to
							<div className="sent-promise-receiver">{userLink}</div>
						</div>
					</div>
					<div className="sent-promise-container-right">
						<button className="sent-promise-track-button">
							{
								<Link
									to={{
										pathname: '/promises/' + this.state.promiseId,
										state: {
											promiseId: this.state.promiseId,
											userPromiseId: this.state.userPromiseId,
											userName: this.state.userName,
										},
									}}
									className="promise-change-link"
								>
									Track
								</Link>
							}
						</button>
						<div className="sent-promise-see-my-promises">
							{' '}
							{
								<Link to="/myPromises" className="sent-promise-see-my-promises-link">
									See my promises
								</Link>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
