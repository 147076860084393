import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { checkExpiration, getLeftTime, standardTime, convertUTCToLocal } from '../utils/time';
import '../styles/promiseCard.css';
import { auth, db } from '../utils/firebase';
import { generateUserNameLink } from '../utils/links';

export default class PromiseCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userId: this.props.promise.userId,
			userPromiseId: this.props.promise.userPromiseId,
			userName: this.props.promise.userName,
		};
	}

	userRedirect() {
		let path = {};
		if (this.state.userId === auth.currentUser.uid) {
			path = '/myPromises/';
		} else {
			path = '/user/' + this.state.userPromiseId;
		}
		return path;
	}

	resolvePromise() {
		db.ref('promises/' + this.props.promise.id)
			.update({
				status: 'finished',
			})
			.catch(err => {
				alert(err);
			});
	}

	render() {
		let type = this.props.type;
		let status = this.props.status;
		let displayType;
		let currentTime = new Date();
		let expirationTime = this.props.promise.life;

		if (type == 'received') {
			displayType = type;
		} else if (type == 'others') {
			displayType = 'others_made';
		} else {
			displayType = type + '_' + status;
		}

		const LARGE_DATE = new Date('01/01/2500').getTime();
		const neverExpire = expirationTime == LARGE_DATE;

		switch (displayType) {
			case 'received': {
				return (
					<div className="promise-card-container">
						<div className="promise-card-top">
							<div className="create-date-info">
								<div className="promise-card-top-titles">Created on</div>
								<div className="promise-card-top-contents" id="promise-card-top-contents-createdon">
									{standardTime(convertUTCToLocal(this.props.promise.createdOn))}
								</div>
							</div>
							<div className="last-change-info">
								<div className="promise-card-top-titles">Last change</div>
								<div className="promise-card-top-contents">
									{standardTime(convertUTCToLocal(this.props.promise.lastChange))}
								</div>
							</div>

							<div className="status-info">
								<div className="promise-card-top-titles">Status</div>
								<div className="promise-card-top-contents">{this.props.promise.status}</div>
							</div>

							<div className="expiration-info">
								<div className="promise-card-top-titles">Expiration</div>
								<div className="promise-card-top-contents">
									{neverExpire
										? 'Never'
										: checkExpiration(expirationTime, currentTime)
										? 'Expired'
										: getLeftTime(expirationTime, currentTime)}
								</div>
							</div>
						</div>
						<div className="promise-card-bottom">
							<div className="promise-card-bottom-left">
								<div className="sender-info">
									<div className="promise-card-bottom-titles">From</div>
									<div className="promise-card-bottom-contents">
										{generateUserNameLink(
											this.state.userId,
											this.state.userPromiseId,
											this.state.userName,
											'user-page-link'
										)}
									</div>
								</div>
								<div className="promise-info">
									<div className="promise-card-promise-contents">
										{
											<Link
												to={`/promises/${this.props.promise.id}`}
												className="promise-history-link"
											>
												{this.props.promise.content}
											</Link>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
			case 'others_made': {
				return (
					<div className="promise-card-container">
						<div className="promise-card-top">
							<div className="create-date-info">
								<div className="promise-card-top-titles">Created on</div>
								<div className="promise-card-top-contents" id="promise-card-top-contents-createdon">
									{standardTime(convertUTCToLocal(this.props.promise.createdOn))}
								</div>
							</div>
							<div className="last-change-info">
								<div className="promise-card-top-titles">Last change</div>
								<div className="promise-card-top-contents">
									{standardTime(convertUTCToLocal(this.props.promise.lastChange))}
								</div>
							</div>

							<div className="status-info">
								<div className="promise-card-top-titles">Status</div>
								<div className="promise-card-top-contents">{this.props.promise.status}</div>
							</div>

							<div className="expiration-info">
								<div className="promise-card-top-titles">Expiration</div>
								<div className="promise-card-top-contents">
									{neverExpire
										? 'Never'
										: checkExpiration(expirationTime, currentTime)
										? 'Expired'
										: getLeftTime(expirationTime, currentTime)}
								</div>
							</div>
						</div>

						<div className="promise-card-bottom">
							<div className="promise-card-bottom-left">
								<div className="sender-info">
									<div className="promise-card-bottom-titles">To</div>
									<div className="promise-card-bottom-contents">
										{generateUserNameLink(
											this.state.userId,
											this.state.userPromiseId,
											this.state.userName,
											'user-page-link'
										)}
									</div>
								</div>
								<div className="promise-info">
									<div className="promise-card-promise-contents">
										{
											<Link
												to={`/promises/${this.props.promise.id}`}
												className="promise-history-link"
											>
												{this.props.promise.content}
											</Link>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
			case 'made_keeping': {
				return (
					<div className="promise-card-container">
						<div className="promise-card-top">
							<div className="create-date-info">
								<div className="promise-card-top-titles">Created on</div>
								<div className="promise-card-top-contents" id="promise-card-top-contents-createdon">
									{standardTime(convertUTCToLocal(this.props.promise.createdOn))}
								</div>
							</div>
							<div className="last-change-info">
								<div className="promise-card-top-titles">Last change</div>
								<div className="promise-card-top-contents">
									{standardTime(convertUTCToLocal(this.props.promise.lastChange))}
								</div>
							</div>

							<div className="status-info">
								<div className="promise-card-top-titles">Status</div>
								<div className="promise-card-top-contents">{this.props.promise.status}</div>
							</div>

							<div className="expiration-info">
								<div className="promise-card-top-titles">Expiration</div>
								<div className="promise-card-top-contents">
									{neverExpire
										? 'Never'
										: checkExpiration(expirationTime, currentTime)
										? 'Expired'
										: getLeftTime(expirationTime, currentTime)}
								</div>
							</div>
						</div>
						<div className="promise-card-bottom">
							<div className="promise-card-bottom-left">
								<div className="receiver-info">
									<div className="promise-card-bottom-titles">To</div>
									<div className="promise-card-bottom-contents">
										{generateUserNameLink(
											this.state.userId,
											this.state.userPromiseId,
											this.state.userName,
											'user-page-link'
										)}
									</div>
								</div>
								<div className="promise-info">
									<div className="promise-card-promise-contents">
										{
											<Link
												to={`/promises/${this.props.promise.id}`}
												className="promise-history-link"
											>
												{this.props.promise.content}
											</Link>
										}
									</div>
								</div>
							</div>
							<div className="promise-card-bottom-right">
								<button className="promise-card-button">
									{
										<Link
											to={{
												pathname: '/promises/' + this.props.promise.id,
												state: {
													promiseId: this.props.promise.id,
													userPromiseId: this.props.promise.userPromiseId,
													userName: this.props.promise.userName,
												},
											}}
											className="promise-change-link"
										>
											Track
										</Link>
									}
								</button>
								<button className="promise-card-button" onClick={this.props.changePromiseHandler}>
									{
										<Link
											to={{
												pathname: '/myPromises/change/' + this.props.promise.id,
												state: {
													promiseId: this.props.promise.id,
													userPromiseId: this.props.promise.userPromiseId,
													userName: this.props.promise.userName,
												},
											}}
											className="promise-change-link"
										>
											Change
										</Link>
									}
								</button>
								<button className="promise-card-button">
									{
										<Link
											to={{
												pathname: '/myPromises/break/' + this.props.promise.id,
												state: {
													promiseId: this.props.promise.id,
													userPromiseId: this.props.promise.userPromiseId,
													userName: this.props.promise.userName,
												},
											}}
											className="promise-break-link"
										>
											Break
										</Link>
									}
								</button>
								<button className="promise-card-button">
									{
										<Link
											to={{
												pathname: '/myPromises/finish/' + this.props.promise.id,
												state: {
													promiseId: this.props.promise.id,
													userPromiseId: this.props.promise.userPromiseId,
													userName: this.props.promise.userName,
												},
											}}
											className="promise-break-link"
										>
											Finish
										</Link>
									}
								</button>
							</div>
						</div>
					</div>
				);
			}
			case 'made_broken': {
				return (
					<div className="promise-card-container">
						<div className="promise-card-top">
							<div className="create-date-info">
								<div className="promise-card-top-titles">Created on</div>
								<div className="promise-card-top-contents" id="promise-card-top-contents-createdon">
									{standardTime(convertUTCToLocal(this.props.promise.createdOn))}
								</div>
							</div>
							<div className="last-change-info">
								<div className="promise-card-top-titles">Last change</div>
								<div className="promise-card-top-contents">
									{standardTime(convertUTCToLocal(this.props.promise.lastChange))}
								</div>
							</div>

							<div className="status-info">
								<div className="promise-card-top-titles">Status</div>
								<div className="promise-card-top-contents">{this.props.promise.status}</div>
							</div>

							<div className="expiration-info">
								<div className="promise-card-top-titles">Expiration</div>
								<div className="promise-card-top-contents">
									{neverExpire
										? 'Never'
										: checkExpiration(expirationTime, currentTime)
										? 'Expired'
										: getLeftTime(expirationTime, currentTime)}
								</div>
							</div>
						</div>
						<div className="promise-card-bottom">
							<div className="promise-card-bottom-left">
								<div className="receiver-info">
									<div className="promise-card-bottom-titles">To</div>
									<div className="promise-card-bottom-contents">
										{generateUserNameLink(
											this.state.userId,
											this.state.userPromiseId,
											this.state.userName,
											'user-page-link'
										)}
									</div>
								</div>
								<div className="promise-info">
									<div className="promise-card-promise-contents" id="promise-content">
										{
											<Link
												to={`/promises/${this.props.promise.id}`}
												className="promise-history-link"
											>
												{this.props.promise.content}
											</Link>
										}
									</div>
								</div>
							</div>
							<div className="promise-card-bottom-right">
								<button className="promise-card-button">
									{
										<Link
											to={{
												pathname: '/promises/' + this.props.promise.id,
												state: {
													promiseId: this.props.promise.id,
													userPromiseId: this.props.promise.userPromiseId,
													userName: this.props.promise.userName,
												},
											}}
											className="promise-change-link"
										>
											Track
										</Link>
									}
								</button>
								<button disabled className="promise-card-button">
									Change
								</button>
								<button disabled className="promise-card-button">
									Break
								</button>
							</div>
						</div>
					</div>
				);
			}

			case 'made_expired': {
				return (
					<div className="promise-card-container">
						<div className="promise-card-top">
							<div className="create-date-info">
								<div className="promise-card-top-titles">Created on</div>
								<div className="promise-card-top-contents" id="promise-card-top-contents-createdon">
									{standardTime(convertUTCToLocal(this.props.promise.createdOn))}
								</div>
							</div>
							<div className="last-change-info">
								<div className="promise-card-top-titles">Last change</div>
								<div className="promise-card-top-contents">
									{standardTime(convertUTCToLocal(this.props.promise.lastChange))}
								</div>
							</div>

							<div className="status-info">
								<div className="promise-card-top-titles">Status</div>
								<div className="promise-card-top-contents">{this.props.promise.status}</div>
							</div>

							<div className="expiration-info">
								<div className="promise-card-top-titles">Expiration</div>
								<div className="promise-card-top-contents">
									{neverExpire
										? 'Never'
										: checkExpiration(expirationTime, currentTime)
										? 'Expired'
										: getLeftTime(expirationTime, currentTime)}
								</div>
							</div>
						</div>
						<div className="promise-card-bottom">
							<div className="promise-card-bottom-left">
								<div className="receiver-info">
									<div className="promise-card-bottom-titles">To</div>
									<div className="promise-card-bottom-contents">
										{generateUserNameLink(
											this.state.userId,
											this.state.userPromiseId,
											this.state.userName,
											'user-page-link'
										)}
									</div>
								</div>
								<div className="promise-info">
									<div className="promise-card-promise-contents" id="promise-content">
										{
											<Link
												to={`/promises/${this.props.promise.id}`}
												className="promise-history-link"
											>
												{this.props.promise.content}
											</Link>
										}
									</div>
								</div>
							</div>
							<div className="promise-card-bottom-right">
								<button className="promise-card-button">
									{
										<Link
											to={{
												pathname: '/promises/' + this.props.promise.id,
												state: {
													promiseId: this.props.promise.id,
													userPromiseId: this.props.promise.userPromiseId,
													userName: this.props.promise.userName,
												},
											}}
											className="promise-change-link"
										>
											Track
										</Link>
									}
								</button>
								<button className="promise-card-button">
									{
										<Link
											to={{
												pathname: '/myPromises/extend/' + this.props.promise.id,
												state: {
													promiseId: this.props.promise.id,
													userPromiseId: this.props.promise.userPromiseId,
													userName: this.props.promise.userName,
												},
											}}
											className="promise-change-link"
										>
											Extend
										</Link>
									}
								</button>
								<button className="promise-card-button">
									{
										<Link
											to={{
												pathname: '/myPromises/break/' + this.props.promise.id,
												state: {
													promiseId: this.props.promise.id,
													userPromiseId: this.props.promise.userPromiseId,
													userName: this.props.promise.userName,
												},
											}}
											className="promise-break-link"
										>
											Break
										</Link>
									}
								</button>
								<button className="promise-card-button">
									{
										<Link
											to={{
												pathname: '/myPromises/finish/' + this.props.promise.id,
												state: {
													promiseId: this.props.promise.id,
													userPromiseId: this.props.promise.userPromiseId,
													userName: this.props.promise.userName,
												},
											}}
											className="promise-break-link"
											onClick={this.resolvePromise.bind(this)}
										>
											Finish
										</Link>
									}
								</button>
							</div>
						</div>
					</div>
				);
			}
			case 'made_finished': {
				return (
					<div className="promise-card-container">
						<div className="promise-card-top">
							<div className="create-date-info">
								<div className="promise-card-top-titles">Created on</div>
								<div className="promise-card-top-contents" id="promise-card-top-contents-createdon">
									{standardTime(convertUTCToLocal(this.props.promise.createdOn))}
								</div>
							</div>
							<div className="last-change-info">
								<div className="promise-card-top-titles">Last change</div>
								<div className="promise-card-top-contents">
									{standardTime(convertUTCToLocal(this.props.promise.lastChange))}
								</div>
							</div>

							<div className="status-info">
								<div className="promise-card-top-titles">Status</div>
								<div className="promise-card-top-contents">{this.props.promise.status}</div>
							</div>
						</div>
						<div className="promise-card-bottom">
							<div className="promise-card-bottom-left">
								<div className="receiver-info">
									<div className="promise-card-bottom-titles">To</div>
									<div className="promise-card-bottom-contents">
										{generateUserNameLink(
											this.state.userId,
											this.state.userPromiseId,
											this.state.userName,
											'user-page-link'
										)}
									</div>
								</div>
								<div className="promise-info">
									<div className="promise-card-promise-contents" id="promise-content">
										{
											<Link
												to={`/promises/${this.props.promise.id}`}
												className="promise-history-link"
											>
												{this.props.promise.content}
											</Link>
										}
									</div>
								</div>
							</div>
							<div className="promise-card-bottom-right">
								<button className="promise-card-button">
									{
										<Link
											to={{
												pathname: '/promises/' + this.props.promise.id,
												state: {
													promiseId: this.props.promise.id,
													userPromiseId: this.props.promise.userPromiseId,
													userName: this.props.promise.userName,
												},
											}}
											className="promise-change-link"
										>
											Track
										</Link>
									}
								</button>
								<button disabled className="promise-card-button">
									Change
								</button>
								<button disabled className="promise-card-button">
									Break
								</button>
							</div>
						</div>
					</div>
				);
			}
		}
	}
}
