export function viewable(promise, viewerId, authorId, friendsOfViewer) {
	let privacy = promise.privacy;
	if (viewerId === authorId) {
		console.log('This is my own promise.');
		return true;
	}
	if (promise.receiver.includes(viewerId)) {
		console.log('This viewer is a participant of this promise.');
		return true;
	}

	switch (privacy) {
		case 'Public': {
			return true;
		}
		case 'Participants': {
			if (promise.receiver.includes(viewerId)) {
				console.log('This viewer is a participant of this promise.');
				return true;
			} else return false;
		}
		case 'Friends': {
			if (friendsOfViewer.includes(authorId)) return true;
			else return false;
		}
		default:
			return false;
	}
}
