import React, { Component } from 'react';
import { auth, storageKey, createUserIfNotExists } from './utils/firebase';
import './App.css';
import LoggedInRouter from './routers/loggedInRouter';
import LoggedOutRouter from './routers/loggedOutRouter';

export default class App extends Component {
	constructor() {
		super();
		this.state = {
			uid: -1,
		};
	}

	componentDidMount() {
		// Add presence listener
		auth.onIdTokenChanged(user => {
			if (user) {
				window.localStorage.setItem(storageKey, user.uid);
				this.setState({ uid: user.uid });
			} else {
				window.localStorage.removeItem(storageKey);
				this.setState({ uid: null });
			}
		});
	}

	render() {
		if (this.state.uid == -1) return <div />;
		else {
			return (
				<div className="root">
					<div className="app-parent-container">
						{this.state.uid ? <LoggedInRouter /> : <LoggedOutRouter />}
					</div>
				</div>
			);
		}
	}
}
