import React from 'react';
import { Link } from 'react-router-dom';
import { auth } from './firebase';

export const generateUserNameLink = (userId, userPromiseId, userName, className) => {
	let link = {};
	if (userId === 'public') return userId;
	if (auth.currentUser.uid !== userId) {
		link = (
			<Link
				to={{
					pathname: '/user/' + userPromiseId,
					state: {
						userPromiseId: userPromiseId,
						userName: userName,
					},
				}}
				className={className}
			>
				{userName}
			</Link>
		);
	} else {
		link = (
			<Link
				to={{
					pathname: '/myPromises/',
					state: {
						userPromiseId: userPromiseId,
						userName: userName,
					},
				}}
				className={className}
			>
				{userName}
			</Link>
		);
	}
	return link;
};

export const generateUserImageLink = (userId, userPromiseId, userName, imageURL, className) => {
	let link = {};
	if (auth.currentUser.uid !== userId) {
		link = (
			<Link
				to={{
					pathname: '/user/' + userPromiseId,
					state: {
						userPromiseId: userPromiseId,
						userName: userName,
					},
				}}
			>
				<img
					className={className}
					id="avatar"
					src={imageURL}
					width="100"
					heigth="100"
				/>
			</Link>
		);
	} else {
		link = (
			<Link
				to={{
					pathname: '/myPromises/',
					state: {
						userPromiseId: userPromiseId,
						userName: userName,
					},
				}}
			>
				<img
					className={className}
					id="avatar"
					src={imageURL}
					width="100"
					heigth="100"
				/>
			</Link>
		);
	}
	return link;
};
