import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth, db } from '../utils/firebase';

import Background from '../components/background';
import HeaderDropdown from '../components/headerDropdown';
import { headerList } from '../utils/consts';

import '../styles/resolvePromise.css';

export default class ResolvePromise extends Component {
	componentDidMount() {
		db.ref('promises/' + this.state.promiseId)
			.once('value')
			.then(snapshot => {
				if (snapshot.exists()) {
					console.log(snapshot.val());
					let receiver = snapshot.val().receiver;
					let promiseData = snapshot.val();
					db.ref('users/' + receiver)
						.child('publicFields')
						.once('value')
						.then(snapshot => {
							if (snapshot.exists()) {
								this.setState({
									promise: promiseData,
									receiverName: snapshot.val().displayName,
								});
							} else {
								this.setState({
									promise: promiseData,
									receiverName: 'public',
								});
							}
						});
				}
			});
	}

	constructor(props) {
		super(props);
		let storedUserName = {};
		let storedUserPromiseId = {};

		if (localStorage.getItem('username') && props.location.state === undefined) {
			storedUserName = localStorage.getItem('username');
		} else {
			storedUserName = props.location.state.userName;
			localStorage.setItem('username', storedUserName);
		}

		if (localStorage.getItem('userPromiseId') && props.location.state === undefined) {
			storedUserPromiseId = localStorage.getItem('userPromiseId');
		} else {
			storedUserPromiseId = props.location.state.userPromiseId;
			localStorage.setItem('userPromiseId', storedUserPromiseId);
		}

		this.state = {
			receiverName: storedUserName,
			receiverPromiseId: storedUserPromiseId,
			promiseId: this.props.match.params.id,
			promise: {},
		};
	}

	resolvePromise() {
		let currentTime = new Date().getTime();
		let historyArray = this.state.promise.history;

		let notification = {};

		notification = {
			promiseId: this.state.promiseId,
			sender: auth.currentUser.uid,
			createdOn: currentTime,
			content: this.state.promise.content,
			privacy: this.state.promise.privacy,
			life: this.state.promise.life,
			contentType: 'finished',
			isRead: false,
		};

		let newHistory = {
			createdOn: currentTime,
			action: 'finish',
			content: this.state.promise.content,
			reason: '',
			life: this.state.promise.life,
			privacy: this.state.promise.privacy,
		};
		historyArray.push(newHistory);

		db.ref('promises/' + this.state.promiseId)
			.update({
				status: 'finished',
				lastChange: currentTime,
				history: historyArray,
				notification: notification,
			})
			.catch(err => {
				alert(err);
			});
	}

	goBack() {
		this.props.history.goBack();
	}

	render() {
		console.log(this.state);
		console.log(this.props);
		return (
			<div className="finish-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
					<div className="drop-down-menu">
						<HeaderDropdown login={auth.currentUser} dropdownList={headerList} />
					</div>
				</div>
				<div className="finish-promise-container">
					<div className="finish-promise-content-one">Finish promise:</div>
					<div className="finish-promise-content-two">
						<Link to={`/promises/${this.state.promiseId}`} className="promise-history-link">
							{this.state.promise.content}
						</Link>
					</div>

					<div className="promise-finish-buttons">
						<button className="promise-finish-cancel-button" onClick={this.goBack.bind(this)}>
							<div className="finish-promise-cancel-link">Cancel</div>
						</button>
						<button className="promise-finish-confirm-button">
							<Link
								className="finish-promise-confirm-link"
								to={{
									pathname: '/myPromises/finished/' + this.state.promiseId,
									state: {
										receiverName: this.state.receiverName,
										receiverPromiseId: this.state.receiverPromiseId,
										action: 'finish',
									},
								}}
								onClick={this.resolvePromise.bind(this)}
							>
								Confirm
							</Link>
						</button>
					</div>
				</div>
			</div>
		);
	}
}
