import React, { Component } from 'react';
import { auth, db } from '../utils/firebase';

export default class FriendSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 'public',
			friends: [],
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		this.setState({ value: e.target.value });

		let options = document.getElementsByClassName('friends-selector')[0].options;
		let selectedId = options.selectedIndex;
		let callbackData = {};
		console.log(options[selectedId]);
		if (options[selectedId].id === 'public') {
			callbackData = {
				id: options[selectedId].id,
				name: 'public',
			};
		} else {
			callbackData = {
				id: options[selectedId].id,
				name: e.target.value,
				userPromiseId: options[selectedId].getAttribute('user'),
			};
		}

		console.log(callbackData);
		this.props.setReceiver(callbackData);
	}

	render() {
		let style = {
			display: 'none',
		};

		return (
			<select className="friends-selector" value={this.state.value} onChange={this.handleChange}>
				<optgroup label="Groups">
					<option id="public">Public</option>
				</optgroup>
				<optgroup label="Friends">
					{this.props.data.map(option => (
						<option id={option.id} key={option.id} user={option.userPromiseId}>
							{option.name}
						</option>
					))}
				</optgroup>
			</select>
		);
	}
}
