import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db, auth } from '../utils/firebase';
import Background from '../components/background';

import { validateEmail } from '../utils/formValidate';

import '../styles/signup.css';
import '../styles/formTransition.css';

export default class Signup extends Component {
	constructor() {
		super();
		this.state = {
			email: '',
			password: '',
		};
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value,
		});
	}

	handleSubmitSignup() {
		let email = document.getElementsByClassName('signup-email-input')[0].value;
		let password = document.getElementsByClassName('signup-password-input')[0].value;
		let confirmPassword = document.getElementsByClassName('confirm-password-input')[0].value;

		let emailErrorElement = document.getElementById('error-email');
		let emaillInputBorder = document.getElementsByClassName('signup-email-input')[0];
		let passwordErrorElement = document.getElementById('error-password');
		let passwordInputBorder = document.getElementsByClassName('signup-password-input')[0];
		let confirmPasswordErrorElement = document.getElementById('error-password-confirm');
		let confirmPasswordInputBorder = document.getElementsByClassName('confirm-password-input')[0];

		if (!validateEmail(email)) {
			emailErrorElement.className = 'signup-email-error-message show';
			emaillInputBorder.className = 'signup-email-input border-red';
			return;
		} else {
			emailErrorElement.className = 'signup-email-error-message';
			emaillInputBorder.className = 'signup-email-input';
		}

		if (password.length < 6) {
			passwordErrorElement.className = 'signup-password-error-message show';
			passwordInputBorder.className = 'signup-password-input border-red';
			return;
		} else {
			passwordErrorElement.className = 'signup-password-error-message';
			passwordInputBorder.className = 'signup-password-input';
		}

		if (confirmPassword === '') {
			this.setState({
				confirmPasswordErrorCode: 'empty-confirm-password',
			});

			confirmPasswordErrorElement.className = 'signup-confirm-password-message show';
			confirmPasswordInputBorder.className = 'confirm-password-input border-red';
			return;
		} else if (confirmPassword !== password) {
			this.setState({
				confirmPasswordErrorCode: 'not-match-password',
			});

			confirmPasswordErrorElement.className = 'signup-confirm-password-message show';
			confirmPasswordInputBorder.className = 'confirm-password-input border-red';
			return;
		} else {
			confirmPasswordErrorElement.className = 'signup-confirm-password-message';
			confirmPasswordInputBorder.className = 'confirm-password-input';
		}

		let actionCodeSettings = {
			// URL you want to redirect back to. The domain (www.example.com) for this
			// URL must be whitelisted in the Firebase Console.
			url: 'https://promise-64bb5.firebaseapp.com/__/auth/handleEmailLink',
			// This must be true.
			handleCodeInApp: true,
		};

		auth.fetchSignInMethodsForEmail(email)
			.then(result => {
				if (result.length == 0) {
					auth.sendSignInLinkToEmail(email, actionCodeSettings)
						.then(result => {
							// The link was successfully sent. Inform the user.
							// Save the email locally so you don't need to ask the user for it again
							// if they open the link on the same device.
							window.localStorage.setItem('emailForSignIn', email);
							window.localStorage.setItem('passwordForSignIn', password);

							this.props.history.push('/signupCheckEmail');
						})
						.catch(error => {
							console.log(error);
						});
				} else {
					this.props.history.push('/signupEmailExisted');
				}
			})
			.catch(error => {
				console.log(error);
			});
	}

	render() {
		console.log(this.state);
		let confirmPasswordErrorMessage;
		switch (this.state.confirmPasswordErrorCode) {
			case 'empty-confirm-password': {
				confirmPasswordErrorMessage = 'Please input your password again.';
				break;
			}
			case 'not-match-password': {
				confirmPasswordErrorMessage = 'Confirm password does not match. Please input again.';
				break;
			}
			default:
				break;
		}
		return (
			<div className="signup-root">
				<div className="all-background">
					<Background />
				</div>
				<div className="home-page-title">
					{
						<Link to="/" className="home-link">
							Promise
						</Link>
					}
				</div>
				<div className="signup-container">
					<div className="signup-body">
						<div className="signup-text"> Sign up </div>
						<div className="signup-email">
							<div className="signup-email-text">Email</div>
							<input
								className="signup-email-input"
								name="email"
								type="text"
								placeholder=""
								value={this.state.email}
								required
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
						<div className="signup-email-error-message" id="error-email">
							Invalid email.
						</div>
						<div className="signup-password">
							<div className="signup-password-text">Password</div>
							<input
								className="signup-password-input"
								name="password"
								type="password"
								placeholder=""
								required
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
						<div className="signup-password-error-message" id="error-password">
							Password must be at least 6 characters.
						</div>
						<div className="signup-password">
							<div className="signup-password-text">Confirm password</div>
							<input
								className="confirm-password-input"
								name="password"
								type="password"
								placeholder=""
								required
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
						<div className="signup-confirm-password-message" id="error-password-confirm">
							{confirmPasswordErrorMessage}
						</div>

						<div className="signupquestion-container">
							<button className="signup-button" onClick={this.handleSubmitSignup.bind(this)}>
								Continue
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
