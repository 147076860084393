export function timeSince(date) {
	var seconds = Math.floor((new Date() - date) / 1000);

	var interval = Math.floor(seconds / 31536000);

	if (interval > 1) {
		return interval + ' years ago';
	}
	interval = Math.floor(seconds / 2592000);
	if (interval > 1) {
		return interval + ' months ago';
	}
	interval = Math.floor(seconds / 86400);
	if (interval > 1) {
		return interval + ' days ago';
	}
	interval = Math.floor(seconds / 3600);
	if (interval > 1) {
		return interval + ' hours ago';
	}
	interval = Math.floor(seconds / 60);
	if (interval > 1) {
		return interval + ' minutes ago';
	}
	return Math.floor(seconds) + ' seconds ago';
}

export function timeFromNow(date, currentTime) {
	var seconds = Math.floor((date - currentTime) / 1000);

	var interval = Math.floor(seconds / 31536000);

	if (interval > 1) {
		return 'In ' + interval + ' years';
	}
	interval = Math.floor(seconds / 2592000);
	if (interval > 1) {
		return 'In ' + interval + ' months';
	}
	interval = Math.floor(seconds / 86400);
	if (interval > 1) {
		return 'In ' + interval + ' days';
	}
	interval = Math.floor(seconds / 3600);
	if (interval > 1) {
		return 'In ' + interval + ' hours';
	}
	interval = Math.floor(seconds / 60);
	if (interval > 1) {
		return 'In ' + interval + ' minutes';
	}
	return 'In ' + Math.floor(seconds) + ' seconds';
}

export function standardTime(date) {
	return new Date(date).toISOString().substring(0, 10);
}

export function getLeftTime(expirationTime, currentTime) {
	return timeFromNow(expirationTime, currentTime);
}

export function checkExpiration(expirationTime, currentTime) {
	let leftTime = expirationTime - currentTime;
	return leftTime < 0 ? true : false;
}

export function convertUTCToLocal(utcMilliseconds) {
	// Local time zone offset in minutes (UTC - Local)
	let localTimeOffset = new Date().getTimezoneOffset();
	let localTimeInMilliseconds = utcMilliseconds - localTimeOffset * 60 * 1000;
	return localTimeInMilliseconds;
}

export function convertLocalToUTC(localMilliseconds) {
	// Local time zone offset in minutes (UTC - Local)
	let localTimeOffset = new Date().getTimezoneOffset();
	let UTCInMilliseconds = localMilliseconds + localTimeOffset * 60 * 1000;
	return UTCInMilliseconds;
}
