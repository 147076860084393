import React, { Component } from 'react';

import { signOut, auth } from '../utils/firebase';

// import '../styles/buttonDropdown.css';
// import '../styles/buttonDropdownItem.css';

export default class ButtonDropdownItem extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		// const onClick = this;

		return (
			<div
				className="dd-list-item-botton"
				uid={this.props.uid}
				id={'button_dd_item_' + this.props.id}
				onClick={this.props.onClickHandler}
				next={this.props.next}
			>
				{this.props.item.title}
			</div>
		);
	}
}
